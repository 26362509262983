import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import axios from "axios";
import { API_BASE } from "../../env";
import { history } from "../../routers/history";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import moment from "moment";

// REDUX
import { connect } from "react-redux";
import { compose } from "redux";

import {
  FormControl,
  TextField,
  Button,
  Grid,
  Typography,
  TextareaAutosize,
  // Input
} from "@material-ui/core";
// import CameraButton from "./CameraButton";

class DispatchReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vin: "",
      service: "",
      type: "",
      km: "",
      litros: "",
      model: "",
      date: null,
      remark: null
    };
  }

  handleChange = prop => event => {
    this.setState({
      [prop]: event.target.value
    });
  };

  handleImageCapture = imageData => {
    this.setState({
      capturedImage: imageData // Almacena la imagen capturada en el estado
    });
  };

  handleChangeDateTime = (date, type) => {
    const newDate = moment(date).format("YYYY-MM-DDTHH:mm");
    this.setState({ date: newDate });
  };

  handleImageChange = (event) => {
    const file = event.target.files[0];

    const imageUrl = URL.createObjectURL(file);

    this.setState({
      selectedImage: file,
      imagePreview: imageUrl
    })
  };

  continue = e => {
    this.handleSendData();
  };

  handleSendData = async () => {

    this.setState({
      sendingData: true
    })


    var dateTime = Date.now();
    var dateTimeForNull = moment(dateTime).format("YYYY-MM-DDTHH:mm");
    // const { auth } = this.props;

    // const tt = this.state.litros.replace(/,/g, ".");
    let data = {
      vin_number: this.state.vin,
      km: this.state.km,
      date: this.state.date,
      model: this.state.model,
      remark: this.state.remark
    };

    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    const token = localStorage.getItem("token");

    config.headers["Authorization"] = `Token ${token}`;

    if (data.date === null) {
      data.date = dateTimeForNull;
    }

    // const body = JSON.stringify(data);

    const formData = new FormData()

    formData.append('image', this.state.selectedImage)
    formData.append('vin_number', data.vin_number)
    formData.append('km', data.km)
    formData.append('model', data.model)
    formData.append('date', data.date)
    formData.append('remark', data.remark)

    await axios.post(`${API_BASE}v1/dispatches-review/new/`, formData, config)
      .then(response => {
        //console.log ("ingresado");
        // history.push("/dispatchReviewData");
        this.setState({
          vin: "",
          service: "",
          type: "",
          km: "",
          litros: "",
          model: "",
          selectedImage: undefined,
          imagePreview: undefined,
          remark: undefined
        })
        alert('Los datos han sido cargados!')
      })
      .catch(error => {
        console.log(error.response.data);
      }).finally(() => {
        this.setState({
          sendingData: false
        })
      })
  };

  render() {
    const { classes } = this.props;

    const { remark, vin, km, model } = this.state;

    return (
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography
              className={classes.subtitle}
              variant="h5"
              align="center"
              paragraph
              color="primary"
            >
              Ingreso de revisión de despacho
            </Typography>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.select}
            >
              <TextField
                className={classes.select}
                variant="outlined"
                label="Nº de VIN"
                value={vin}
                margin="dense"
                type="text"
                pattern=".{2,}"
                onChange={this.handleChange("vin")}
              />
            </FormControl>
          </Grid>
          
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.select}
            >
              <TextField
                className={classes.select}
                variant="outlined"
                label="Modelo"
                value={model}
                margin="dense"
                type="text"
                pattern=".{2,}"
                onChange={this.handleChange("model")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.select}
            >
              <TextField
                className={classes.select}
                variant="outlined"
                label="KM"
                value={km}
                margin="dense"
                type="text"
                pattern=".{2,}"
                onChange={this.handleChange("km")}
              />
            </FormControl>
          </Grid>

          
          <Grid item xs={12}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            maxHeight: '500px'
          }}>
            {this.state.imagePreview && (
              <div style={{
                maxWidth: 300,
                maxHeight: 500,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
                <img
                  src={this.state.imagePreview}
                  alt="Vista Previa"
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                  }}
                />
              </div>
            )}
            <Button
              className={classes.searchButton}
              variant="contained"
              component="label"
              color="secondary"
              style={{ backgroundColor: "#600080" }}
            >
              Capturar imagen
              <input
                type="file"
                accept="image/*"
                capture="environment"
                onChange={this.handleImageChange}
                hidden
              />
            </Button>
            </div>
          </Grid>


          
          <Grid item xs={12}>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              style={{ cursor: "pointer" }}
            >
              <DateTimePicker
                disabled
                value={this.state.date || Date.now()}
                onChange={this.handleChangeDateTime}
                format="dd/MM/yyyy HH:mm "
                ampm={false}
                style={{ width: "300px", cursor: "pointer" }}
              />
            </MuiPickersUtilsProvider>
          </Grid>


          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.select}
            >
              <TextareaAutosize
                rowsMin={6}
                rowsMax={6}
                aria-label="maximum height"
                placeholder="Observaciones..."
                value={remark}
                style={{ fontSize: 14 }}
                onChange={this.handleChange("remark")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.searchButton}
              variant="contained"
              onClick={this.continue}
              color="secondary"
              style={{ backgroundColor: "#600080" }}
              disabled={this.state.sendingData}
            >
              {(!this.state.sendingData) ? "FINALIZAR CARGA" : "CARGANDO.."}
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const styles = createStyles(theme => ({
  select: {
    width: 300,
    marginTop: theme.spacing(2)
  },
  searchButton: {
    width: 200,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 30,
    color: "white"
  },
  menuItem: {
    width: "100%"
  },
  error: {
    color: "red"
  }
}));

const mapStateToProps = state => ({
  auth: state.auth
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps)
)(DispatchReview);
