import React from "react";
import PropTypes from "prop-types";

import { createStyles, withStyles } from "@material-ui/core/styles";
import {
  Avatar,
  Typography,
  InputLabel,
  InputAdornment,
  IconButton,
  OutlinedInput,
  FormControl,
  Button,
  Paper,
  Grid
} from "@material-ui/core";
//import CheckCircleIcon from "@material-ui/icons/CheckCircle";
//import SnackbarContent from "@material-ui/core/SnackbarContent";
//import MySnackbarContentWrapper from "../components/MySnackbarContentWrapper";

import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";

import Logo from "../assets/isotipo.png";
//import SuccessSnack from "./SuccessSnack";

import { Redirect } from "react-router-dom";

import { login } from "../redux/actions/auth/actions";
import { clearSnackbar } from "../redux/actions/auth/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import Notifications from "../components/Notifications";

//import { Icon } from "@material-ui/core";
//import Snackbar from "@material-ui/core/Snackbar";
class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      showPassword: false,
      openSuccess: "done"
    };
  }

  static propTypes = {
    clearSnackbar: PropTypes.func.isRequired,
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
  };

  componentDidMount() {
    document.title = "Somec - Login";
  }

  handleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword
    });
  };

  handleChange = prop => event => {
    this.setState({
      [prop]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.props.login(this.state.email, this.state.password);
  };

  handleClose() {
    this.props.clearSnackbar();
  }

  render() {
    if (this.props.isAuthenticated) {
      return <Redirect to="/dashboard" />;
    }

    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item xs={12}>
            <Paper elevation={3} className={classes.paper}>
              <Avatar className={classes.avatar} src={Logo} />
              <Typography component="h1" variant="h5">
                Ingresar
              </Typography>
              <Grid
                container
                direction="column"
                justify="flex-start"
                alignItems="center"
              >
                <form onSubmit={this.handleSubmit}>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.input}
                  >
                    <InputLabel htmlFor="outlined-adornment-email">
                      Email *
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-email"
                      value={this.state.email}
                      onChange={this.handleChange("email")}
                      type="email"
                      required
                      placeholder="username@example.com"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton>
                            <AccountCircleOutlinedIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={55}
                    />
                  </FormControl>
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className={classes.input}
                  >
                    <InputLabel htmlFor="outlined-adornment-password">
                      Password *
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password"
                      value={this.state.password}
                      onChange={this.handleChange("password")}
                      type={this.state.showPassword ? "text" : "password"}
                      required
                      autoComplete="on"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton onClick={this.handleShowPassword}>
                            {this.state.showPassword ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={85}
                    />
                  </FormControl>
                  <Button
                    type="submit"
                    className={classes.submit}
                    fullWidth
                    variant="contained"
                    color="secondary"
                  >
                    Iniciar Sesión
                  </Button>
                </form>

                {/*      <Typography
                  component="h2"
                  variant="overline"
                  color="textSecondary"
                  className={classes.formFooter}
                >
                  <Link to="/sign-up" className={classes.link}>
                    {" "}
                    Regístrate
                  </Link>{" "}
                  <br />
                  <Link to="/AccountRecovery" className={classes.link}>
                    {" "}
                    Recupera tu contraseña
                  </Link>
                </Typography> */}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        {this.props.notificationOpen === true && (
          <Notifications
            onClose={this.props.clearSnackbar}
            open={this.props.notificationOpen}
            message="Credenciales incorrectas"
            notificationType="error"
            autoHideDuration={4000}
          />
        )}
      </div>
    );
  }
}

const styles = createStyles(theme => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(12),
      display: "flex",
      width: 400,
      flexDirection: "column",
      alignItems: "center",
      borderRadius: 0
    },
    [theme.breakpoints.up("xs")]: {
      marginTop: theme.spacing(10),
      display: "flex",
      minWidth: 300,
      flexDirection: "column",
      alignItems: "center",
      borderRadius: 0
    }
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: 200,
    color: "white"
  },
  input: {
    margin: theme.spacing(2, -1, 2),
    width: "80%"
  },
  avatar: {
    marginTop: theme.spacing(2),
    borderRadius: 0,
    margin: 5,
    width: 100,
    height: 50
  },
  container: {
    backgroundColor: "#022C42",
    backgroundRepeat: "repeat",
    position: "fixed",
    top: 0,
    left: 0,
    minWidth: "100%",
    minHeight: "100%"
  },
  grid: {
    minHeight: "100vh"
  },
  fontFooter: {
    fontSize: 8
  },
  link: {
    textDecoration: "none"
  }
}));

Login.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  notificationOpen: state.auth.notificationOpen,
  notificationMessage: state.auth.notificationMessage
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { login, clearSnackbar })
)(Login);
