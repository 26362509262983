import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
//import axios from "axios";
//import { API_BASE } from "../env";
//import Loading from "./Loading";
import {
  Button,
  Grid,
  Typography,
  Fab,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import ObservationModal from "./ObservationModal";
import ObservationModalTank from "./ObservationModalTank";
import ObservationModalFuelFilter from "./ObservationModalFuelFilter";

class AddIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      observationModal: false,
      value: "",
      observationModalTank: false,
      valueTank: "",
      observationModalFuelFilter: false,
      valueFuelFilter: ""
    };
  }
  continue = e => {
    this.props.nextStep();
  };

  back = e => {
    this.props.backStep();
  };

  addRow = e => {
    this.props.handleAddRow();
  };

  handleStatusObservationDialog = value => {
    if (value === "visual") {
      this.setState({
        observationModal: !this.state.observationModal,
        value: value
      });
    } else if (value === "tank") {
      this.setState({
        observationModalTank: !this.state.observationModalTank,
        valueTank: value
      });
    } else if (value === "filter") {
      this.setState({
        observationModalFuelFilter: !this.state.observationModalFuelFilter,
        valueFuelFilter: value
      });
    }
  };
  addCondition = e => {
    if (this.props.rowsCounter === 1) {
      this.props.addRowNumber();
    }
    this.props.handleChangeCondition();
  };

  end = e => {
    this.props.end();
  };

  render() {
    const { classes } = this.props;
    const {
      handleChange,
      tank_status,
      fuel_filter_status,
      visual_status
    } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h5" align="center" paragraph color="primary">
              Inspección Previa{" "}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              align="center"
              paragraph
              color="primary"
            >
              Inspección visual de la unidad en cuanto a daños y óxidos del
              chasis, identificar en observaciones
            </Typography>
          </Grid>

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <RadioGroup
                aria-label="Estado"
                name="status"
                value={visual_status}
                onChange={handleChange("visual_status")}
                row
              >
                <FormControlLabel
                  value="OK"
                  control={<Radio color="primary" />}
                  label="REALIZADO"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="NO_OK"
                  control={<Radio color="primary" />}
                  label="NO REALIZADO"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="NA"
                  control={<Radio color="primary" />}
                  label="NA"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} xl={12} md={12} style={{ color: "#95C225" }}>
              <Fab className={classes.iconAdd} size="small">
                <AddIcon
                  fontSize="small"
                  style={{ color: "white" }}
                  onClick={() => this.handleStatusObservationDialog("visual")}
                />
              </Fab>{" "}
              Agregar observación
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <br />
            <br />

            <Typography
              variant="body1"
              align="center"
              paragraph
              color="primary"
            >
              Despresurizar tanques de combustibles (abrir y cerrar tapas de
              estanque)
            </Typography>
          </Grid>

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <RadioGroup
                aria-label="Estado"
                name="status"
                value={tank_status}
                onChange={handleChange("tank_status")}
                row
              >
                <FormControlLabel
                  value="OK"
                  control={<Radio color="primary" />}
                  label="REALIZADO"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="NO_OK"
                  control={<Radio color="primary" />}
                  label="NO REALIZADO"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="NA"
                  control={<Radio color="primary" />}
                  label="NA"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={12} xl={12} md={12} style={{ color: "#95C225" }}>
              <Fab className={classes.iconAdd} size="small">
                <AddIcon
                  fontSize="small"
                  style={{ color: "white" }}
                  onClick={() => this.handleStatusObservationDialog("tank")}
                />
              </Fab>{" "}
              Agregar observación
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <br />
            <br />
            <Typography
              variant="body1"
              align="center"
              paragraph
              color="primary"
            >
              Drenar por completo el combustible del filtro decantador, luego
              llenar el filtro con bomba manual
            </Typography>
          </Grid>

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <RadioGroup
                aria-label="Estado"
                name="status"
                value={fuel_filter_status}
                onChange={handleChange("fuel_filter_status")}
                row
              >
                <FormControlLabel
                  value="OK"
                  control={<Radio color="primary" />}
                  label="REALIZADO"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="NO_OK"
                  control={<Radio color="primary" />}
                  label="NO REALIZADO"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="NA"
                  control={<Radio color="primary" />}
                  label="NA"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={12} md={12} style={{ color: "#95C225" }}>
            <Fab className={classes.iconAdd} size="small">
              <AddIcon
                fontSize="small"
                style={{ color: "white" }}
                onClick={() => this.handleStatusObservationDialog("filter")}
              />
            </Fab>{" "}
            Agregar observación
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.searchButton}
              variant="contained"
              onClick={this.continue}
              color="secondary"
            >
              CONTINUAR
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.searchButton}
              variant="contained"
              onClick={this.back}
              color="primary"
            >
              ATRAS
            </Button>
          </Grid>
        </Grid>
        {this.state.observationModal ? (
          <ObservationModal
            hadleChangeFlag={this.handleStatusObservationDialog}
            handleObservation={this.props.handleObservation}
          />
        ) : null}
        {this.state.observationModalTank ? (
          <ObservationModalTank
            hadleChangeFlag={this.handleStatusObservationDialog}
            handleObservation={this.props.handleObservation}
          />
        ) : null}
        {this.state.observationModalFuelFilter ? (
          <ObservationModalFuelFilter
            hadleChangeFlag={this.handleStatusObservationDialog}
            handleObservation={this.props.handleObservation}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const styles = createStyles(theme => ({
  select: {
    width: 205,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  searchButton: {
    width: 200,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 30,
    color: "white"
  },
  menuItem: {
    width: "100%"
  },
  valueField: {
    marginTop: theme.spacing(-0.1)
  },
  subtitle: {
    marginLeft: theme.spacing(1.5),
    border: "dashed 2px grey",
    borderRadius: "15px"
  },

  subtitle1: {
    marginLeft: theme.spacing(1.5)
  },
  iconDelete: {
    width: "20x",
    marginTop: "15px",
    marginLeft: "5px"
  },
  iconAdd: {
    marginTop: "5px",
    marginLeft: "5px",
    backgroundColor: "#95C225"
  }
}));

export default withStyles(styles)(AddIndicator);
