import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// Material-UI COMPONENTS
import {
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell
} from "@material-ui/core";

// MATERIAL-UI ICONS
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CloseIcon from "@material-ui/icons/Close";

export default function TableUers(props) {
  const classes = styles();

  return (
    <React.Fragment>
      <div className={classes.table}>
        <Table stickyHeader size="small">
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell size="small">Nombre</TableCell>
              <TableCell size="small">Apellido</TableCell>
              <TableCell size="small">Username</TableCell>
              <TableCell size="small">Status</TableCell>
              <TableCell size="small">E-mail</TableCell>
              <TableCell size="small">Administrador</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.users.map((obj, key) => {
              let user = obj["user"];
              return (
                <TableRow key={key}>
                  <TableCell size="small">{user.first_name}</TableCell>
                  <TableCell size="small">{user.last_name}</TableCell>
                  <TableCell size="small">{user.username}</TableCell>
                  <TableCell size="small">
                    {user.is_active ? <CheckCircleIcon /> : <CloseIcon />}
                  </TableCell>
                  <TableCell size="small">{user.email}</TableCell>
                  <TableCell size="small">
                    {obj.is_admin ? <CheckCircleIcon /> : <CloseIcon />}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </React.Fragment>
  );
}

const styles = makeStyles(theme => ({
  header: {
    textTransform: "uppercase"
  },
  table: {
    maxHeight: 440,
    minHeight: 440,
    overflowX: "auto"
  }
}));
