import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import axios from "axios";
import { API_BASE } from "../../env";
import { history } from "../../routers/history";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
// import BarcodeScannerComponent from "react-qr-barcode-scanner";s
import Quagga from "quagga";

// REDUX
import { connect } from "react-redux";
import { compose } from "redux";

import {
  FormControl,
  TextField,
  Button,
  Grid,
  Typography,
  TextareaAutosize,
  InputLabel,
  Select,
  MenuItem,
  // Input
} from "@material-ui/core";
// import CameraButton from "./CameraButton";

class FuelSupply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      vin: "",
      service: "",
      type: "",
      km: "",
      liters: 0.00,
      model: "",
      date: null,
      remark: "",
      scanning: true
    };

    this.Quagga = Quagga

    this.qrRef = React.createRef(null);
  }

  handleChange = prop => event => {
    this.setState({
      [prop]: event.target.value
    });
  };

  handleChangeDateTime = (date, type) => {
    const newDate = moment(date).format("YYYY-MM-DDTHH:mm");
    this.setState({ date: newDate });
  };

  handleImageChange = (event) => {
    const file = event.target.files[0];

    const imageUrl = URL.createObjectURL(file);

    this.setState({
      selectedImage: file,
      imagePreview: imageUrl
    })
  };

  continue = e => {
    this.handleSendData();
  };

  handleStartScanner = () => {
    console.log("Hola")
    const targetElement = document.querySelector('#barcode-scanner1');
  
    if (targetElement) {
      this.Quagga.init({
        inputStream: {
          name: "Live",
          type: "LiveStream",
          target: targetElement,
          constraints: {
            width: 340,
            height: 300,
            facingMode: "environment"
          }
        },
        decoder: {
          readers: ["code_128_reader", "ean_reader", "ean_8_reader", "code_39_reader", "code_39_vin_reader", "codabar_reader", "upc_reader", "upc_e_reader", "i2of5_reader"]
        }
      }, err => {
        if (err) {
          console.error(err);
        }
        this.Quagga.start();
      });
  
      this.Quagga.onDetected((data) => {
        this.setState({
          vin: data.codeResult.code
        });
      });
    } else {
      console.error("Elemento HTML objetivo no encontrado");
    }
  };
  

  handleStopScanner = () => {
    this.setState({
      scanning: true
    })
    this.Quagga.stop()
  }

  handleSendData = async () => {

    this.setState({
      sendingData: true
    })


    var dateTime = Date.now();
    var dateTimeForNull = moment(dateTime).format("YYYY-MM-DDTHH:mm");
    // const { auth } = this.props;

    // const tt = this.state.liters.replace(/,/g, ".");
    let data = {
      vin_number: this.state.vin,
      liters: this.state.liters,
      date: this.state.date,
      fuel_type: this.state.type,
      brand: this.state.brand,
      model: this.state.model,
      remark: this.state.remark
    };

    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const token = localStorage.getItem("token");

    config.headers["Authorization"] = `Token ${token}`;

    if (data.date === null) {
      data.date = dateTimeForNull;
    }

    const body = JSON.stringify(data);

    await axios.post(`${API_BASE}v1/fuel-supply/new/`, body, config)
      .then(response => {
        this.setState({
          vin: "",
          type: "",
          liters: 0.00,
          model: "",
          brand: "",
          remark: ""
        })
        alert('Los datos han sido cargados!')
      })
      .catch(error => {
        // console.log(error.response.data);
      }).finally(() => {
        this.setState({
          sendingData: false
        })
      })
  };

  handleScanning = () => {
    this.setState({
      scanning: !this.state.scanning
    })
  };

  handleBarCodeError = (err) => {
    console.error("Code not found!")
  }

  handleScan = (result) => {
    if (result) {
      this.setState({
        vin: result.text,
        scanning: false
      })
    }
  }

  handleStartProcess = () => {
    this.setState({
      scanning: false
    }, this.handleStartScanner())
  }

  render() {
    const { classes } = this.props;

    const { remark, vin, model, type, brand, liters } = this.state;

    return (
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography
              className={classes.subtitle}
              variant="h5"
              align="center"
              paragraph
              color="primary"
            >
              Ingreso de suministro 8000S0
            </Typography>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.select}
            >
              <TextField
                className={classes.select}
                variant="outlined"
                label="Nº de VIN"
                value={vin}
                margin="dense"
                type="text"
                pattern=".{2,}"
                disabled={!this.state.scanning}
                onChange={this.handleChange("vin")}
              />
              <br />
              <div id="barcode-scanner1" style={{maxWidth: 340, maxHeight: 300}}>
                 
              </div>
              {(this.state.scanning) && (
              // <BarcodeScannerComponent
              //   width={300}
              //   height={340}
              //   onUpdate={(err, result) => {
              //     if (result) this.handleScan(result);
              //     else this.handleBarCodeError(err);
              //   }}
              // />
              <>
              </>
              
              )}
            </FormControl>

            {/* <Grid item xs={12}>
              {(this.state.scanning) && (<Button
                className={classes.scanButton}
                variant="contained"
                onClick={
                  () => this.handleStartProcess()
                }
                color="secondary"
                style={{ backgroundColor: "rgba(96, 0, 128, 0.8)" }}
              >
                Escanear código
              </Button>)
              }
              {(!this.state.scanning) && (<Button
                className={classes.scanButton}
                variant="contained"
                onClick={
                  () => this.handleStopScanner()
                }
                color="secondary"
                style={{ backgroundColor: "rgba(96, 0, 128, 0.8)" }}
              >
                Finalizar
              </Button>)
              }
            </Grid> */}
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.select}>
              <InputLabel id="simple-select-brand-outlined-label">
                Marca
              </InputLabel>
              <Select
                labelId="simple-select-brand-outlined-label"
                id="simple-select-brand-outlined"
                value={brand}
                onChange={this.handleChange("brand")}
                style={{ width: "295px", height: "50px", marginTop: "14px" }}
              >
                <MenuItem value="MERCEDES BENZ">MERCEDES BENZ</MenuItem>
                <MenuItem value="FREIGHTLINER">FREIGHTLINER</MenuItem>
                <MenuItem value="MAXUS">MAXUS</MenuItem>
                <MenuItem value="IVECO">IVECO</MenuItem>
                <MenuItem value="KARRY">KARRY</MenuItem>
                <MenuItem value="SANY">SANY</MenuItem>
                <MenuItem value="JETOUR">JETOUR</MenuItem>
                <MenuItem value="KAIYI">KAIYI</MenuItem>
                <MenuItem value="FOTON">FOTON</MenuItem>
                <MenuItem value="FUSO">FUSO</MenuItem>
                <MenuItem value="AGRALE">AGRALE</MenuItem>
                <MenuItem value="OTRO">OTRO</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.select}
            >
              <TextField
                className={classes.select}
                variant="outlined"
                label="Modelo"
                value={model}
                margin="dense"
                type="text"
                pattern=".{2,}"
                onChange={this.handleChange("model")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
          <FormControl variant="outlined" margin="dense" className={classes.select}>
            <TextField
              className={classes.select}
              variant="outlined"
              label="Cantidad en litros"
              value={liters}
              margin="dense"
              type="number"
              inputProps={{
                step: "0.01",
                inputMode: "numeric",
              }}
              onChange={(e) => {
                const value = e.target.value;
                const regex = /^\d*\.?\d{0,2}$/;
                if (regex.test(value) || value === "") {
                  this.handleChange("liters")(e);
                }
              }}
            />
          </FormControl>
        </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" className={classes.select}>
              <InputLabel id="simple-select-fuel-type-outlined-label">
                Tipo
              </InputLabel>
              <Select
                labelId="simple-select-fuel-type-outlined-label"
                id="simple-select-fuel-type-outlined"
                value={type}
                onChange={this.handleChange("type")}
                style={{ width: "295px", height: "50px", marginTop: "14px" }}
              >
                <MenuItem value="BENCINA">BENCINA</MenuItem>
                <MenuItem value="DIESEL">DIESEL</MenuItem>
                <MenuItem value="ADBLUE">ADBLUE</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          
          <Grid item xs={12}>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              style={{ cursor: "pointer" }}
            >
              <DateTimePicker
                disabled
                value={this.state.date || Date.now()}
                onChange={this.handleChangeDateTime}
                format="dd/MM/yyyy HH:mm "
                ampm={false}
                style={{ width: "300px", cursor: "pointer" }}
              />
            </MuiPickersUtilsProvider>
          </Grid>


          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.select}
            >
              <TextareaAutosize
                rowsMin={6}
                rowsMax={6}
                aria-label="maximum height"
                placeholder="Observaciones..."
                value={remark}
                style={{ fontSize: 14 }}
                onChange={this.handleChange("remark")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.searchButton}
              variant="contained"
              onClick={this.continue}
              color="secondary"
              style={{ backgroundColor: "#600080" }}
              disabled={this.state.sendingData}
            >
              {(!this.state.sendingData) ? "FINALIZAR CARGA" : "CARGANDO.."}
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const styles = createStyles(theme => ({
  select: {
    width: 300,
    marginTop: theme.spacing(2)
  },
  searchButton: {
    width: 200,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 30,
    color: "white"
  },
  menuItem: {
    width: "100%"
  },
  error: {
    color: "red"
  },

  scanButton: {
    width: 200,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    borderRadius: 30,
    color: "white"
  },
}));

const mapStateToProps = state => ({
  auth: state.auth
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps)
)(FuelSupply);
