import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
//import axios from "axios";
//import { API_BASE } from "../env";
//import Loading from "./Loading";
import {
  FormControl,
  Button,
  Grid,
  Typography,
  TextareaAutosize
} from "@material-ui/core";

class Observation extends React.Component {
  continue = e => {
    this.props.nextStep();
  };

  back = e => {
    this.props.backStep();
  };

  addRow = e => {
    this.props.handleAddRow();
  };

  addCondition = e => {
    if (this.props.rowsCounter === 1) {
      this.props.addRowNumber();
    }
    this.props.handleChangeCondition();
  };

  end = e => {
    this.props.end();
  };

  render() {
    const { classes } = this.props;
    const { handleChange, remark } = this.props;

    return (
      <React.Fragment>
        <Grid container direction="row" justify="center" alignItems="center">
          {" "}
          <Grid item xs={12}>
            <Typography variant="h5" align="center" paragraph color="primary">
              Observaciones{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} xl={12} md={4}>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.select}
            >
              <TextareaAutosize
                rowsMax={6}
                aria-label="maximum height"
                placeholder="Observaciones..."
                value={remark}
                onChange={handleChange("remark")}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.searchButton}
              variant="contained"
              onClick={this.end}
              color="secondary"
              style={{ backgroundColor: "#C22561" }}
            >
              FINALIZAR
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.searchButton}
              variant="contained"
              onClick={this.back}
              color="primary"
            >
              ATRAS
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const styles = createStyles(theme => ({
  select: {
    width: 205,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  searchButton: {
    width: 200,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 30,
    color: "white"
  },
  menuItem: {
    width: "100%"
  },
  valueField: {
    marginTop: theme.spacing(-0.1)
  },
  subtitle: {
    marginLeft: theme.spacing(1.5),
    border: "dashed 2px grey",
    borderRadius: "15px"
  },

  subtitle1: {
    marginLeft: theme.spacing(1.5)
  },
  iconAdd: {
    marginTop: "5px",
    marginLeft: "5px",
    backgroundColor: "#00b9ef"
  },
  iconDelete: {
    width: "20x",
    marginTop: "15px",
    marginLeft: "5px"
  }
}));

export default withStyles(styles)(Observation);
