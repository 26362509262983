import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const LoadingApp = withStyles({
  colorPrimary: {
    backgroundColor: "#022C42"
  },
  barColorPrimary: {
    backgroundColor: "#00695c"
  }
})(LinearProgress);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 2
  }
}));

function Loading() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LoadingApp />
    </div>
  );
}

export default Loading;
