import React from "react";
import kaufmann from "../assets/img_kaufmann.png";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet
} from "@react-pdf/renderer";
import { Avatar } from "@material-ui/core";

import axios from "axios";
import { API_BASE } from "../env/index";
import Loading from "./Loading";
//import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import imgPdf from "../assets/pdf.svg";
class PdfDaily extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: "",
      oct97: "",
      oct95: "",
      oct93: "",
      diesel: "",
      loading: true
    };
  }

  componentDidMount() {
    this.handleGetData();
  }

  handleGetData = async () => {
    const config = {
      headers: {}
    };

    const token = localStorage.getItem("token");
    config.headers["Authorization"] = `Token ${token}`;

    await axios
      .get(`${API_BASE}v1/fuels/daily/${this.props.date}`, config)
      .then(response => {
        this.setState({
          oct97: response.data.adblue,
          oct95: response.data.bencina,
          oct93: response.data.bencina,
          diesel: response.data.diesel,
          date: response.data.date
        });
      })
      .catch(error => {
        console.log(error.response);
      })
      .finally(
        this.setState({
          loading: false
        })
      );
  };

  render() {
    const styles = StyleSheet.create({
      page: { padding: 60 },
      box: { width: "100%", marginBottom: 10 },
      pageNumbers: {
        position: "absolute",
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: "center"
      },
      image: {
        width: "100px",
        marginLeft: 10
      },
      subtitle: {
        fontSize: 18,
        margin: 12,
        color: "white",
        textAlign: "right",
        position: "fixed",
        top: -50
      },
      column1: {
        fontSize: 10,
        color: "black",
        textAlign: "left",
        marginVertical: 0,
        marginHorizontal: "20%"
      },
      column2: {
        fontSize: 10,
        color: "black",
        textAlign: "left",
        marginVertical: -10,
        marginHorizontal: "60%"
      },
      column3: {
        fontSize: 10,
        color: "black",
        textAlign: "left",
        marginVertical: -20,
        marginHorizontal: "60%"
      },
      column4: {
        fontSize: 10,
        color: "black",
        textAlign: "left",
        marginVertical: -30,
        marginHorizontal: "80%"
      },
      title: {
        fontSize: 18,
        margin: 12,
        color: "white",
        textAlign: "center",
        position: "fixed"
      }
    });
    const PdfDocument = ({ date, oct93, oct95, oct97, diesel }) => (
      <Document>
        <Page size="A4">
          <View
            style={[styles.box, { height: 60, backgroundColor: "#022c42" }]}
          >
            <Image style={styles.image} src={kaufmann} />
            <Text style={styles.subtitle}>Cierre contable diario</Text>
          </View>

          <View style={[styles.box, { height: 40, backgroundColor: "white" }]}>
            <Text style={styles.column1}>ADBLUE: {oct97}</Text>

            <Text style={styles.column2}>DIESEL: {diesel} </Text>
            <Text style={[styles.column1, { marginVertical: 10 }]}>
              BENCINA:{oct93}
            </Text>
            <Text style={[styles.column2, { marginVertical: -20 }]}></Text>
          </View>
        </Page>
      </Document>
    );

    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div
        style={{
          float: "right",
          marginBottom: "-56px",
          marginRight: "5px",
          paddingTop: "10px"
        }}
      >
        <PDFDownloadLink
          document={PdfDocument({
            date: this.state.date,
            oct93: this.state.oct93,
            oct95: this.state.oct95,
            oct97: this.state.oct97,
            diesel: this.state.diesel
          })}
          fileName="reporteDiario.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <Loading />
            ) : (
              <Avatar
                variant="circle"
                style={{
                  backgroundColor: "#C22561",
                  width: "50px",
                  height: "50px"
                }}
              >
                <img src={imgPdf} alt="pdf daily report" />
              </Avatar>
            )
          }
        </PDFDownloadLink>
      </div>
    );
  }
}
export default PdfDaily;
