import React from "react";
import Page from "./Page";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import logoKaufmann from "../assets/img_kaufmann.png";
import Pdf from "./Pdf";
const SinglePage = ({
  id,
  factory,
  model,
  motor_number,
  vin_number,
  km,
  date,
  batteries,
  board_failures_status,
  fuel_filter_status,
  start_motor_status,
  visual_status,
  air_system_status,
  tank_status,
  kaufman_supervisor_full_name,
  kaufmann_supervisor_date,
  kaufmann_supervisor_signature,
  responsible_full_name,
  responsible_date,
  responsible_signature,
  external_supervisor_full_name,
  external_supervisor_date,
  external_supervisor_signature,
  idStock,
  visual_remark,
  visual_image,
  tank_remark,
  tank_image,
  fuel_filter_remark,
  air_system_remark,
  board_failures_remark,
  ac_system_remark,
  start_motor_remark,
  remark,
  handleImageModal,
  fuel_filter_image,
  board_failures_image,
  ac_system_image,
  air_system_image,
  start_motor_image,
  ac_system_status
}) => {
  return (
    <>
      {id && (
        <Page singleMode={true} id={id}>
          {vin_number && remark && batteries ? <Pdf idStock={idStock} /> : null}
          <Grid
            container
            style={{ backgroundColor: "#022C42", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={6}
              style={{ color: "white", textAlign: "left", paddingLeft: "1px" }}
            >
              <img
                src={logoKaufmann}
                alt="kaufmann"
                style={{ width: "95px" }}
              />
            </Grid>
            <Grid
              item
              xs={6}
              style={{ color: "white", fontSize: "15px", paddingTop: "20px" }}
            >
              Pauta Inspección Unidades en Stock
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", height: "20px" }}
          >
            <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
              <strong>FÁBRICA</strong>
            </Grid>
            <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
              {factory}
            </Grid>
            <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
              <strong>MODELO</strong>
            </Grid>
            <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
              {model}
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", height: "20px" }}
          >
            <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
              <strong>Nº VIN</strong>
            </Grid>
            <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
              {vin_number}
            </Grid>
            <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
              <strong>KM</strong>
            </Grid>
            <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
              {km}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white" }}
          >
            <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
              <strong>Nº MOTOR</strong>
            </Grid>
            <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
              {motor_number}
            </Grid>
            <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
              <strong>FECHA</strong>
            </Grid>
            <Grid item xs={2} style={{ color: "black", fontSize: "11px" }}>
              {date && date.substring(0, 10)}
              {date && " " + date.substring(11, 19)}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "#022C42" }}
          >
            <Grid
              item
              xs={12}
              style={{ color: "white", fontSize: "15px", textAlign: "center" }}
            >
              INSPECCIÓN PREVIA
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={9}
              style={{ color: "black", fontSize: "12px", textAlign: "left" }}
            >
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                Inspección visual en cuanto a daños y óxidos el chasis,
                identificar en observaciones
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {visual_status === "OK"
                ? "REALIZADO"
                : visual_status === "NO_OK"
                ? "NO REALIZADO"
                : "NA"}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={9}
              style={{ color: "black", fontSize: "12px", textAlign: "left" }}
            >
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                <strong>OBSERVACIONES:</strong>{" "}
                {visual_remark !== "null" ? visual_remark : "Sin observaciones"}
              </Typography>
            </Grid>

            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {visual_image !== null && (
                <img
                  style={{ width: "200px" }}
                  src={visual_image}
                  alt="Visual"
                  onClick={() => handleImageModal(visual_image, visual_remark)}
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={9}
              style={{ color: "black", fontSize: "8px", textAlign: "left" }}
            >
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                Despresurizar tanques de combustibles (abrir y cerrar tapas de
                estanque)
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {tank_status === "OK"
                ? "REALIZADO"
                : tank_status === "NO_OK"
                ? "NO REALIZADO"
                : "NA"}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={9}
              style={{ color: "black", fontSize: "12px", textAlign: "left" }}
            >
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                <strong>OBSERVACIONES:</strong>{" "}
                {tank_remark !== "null" ? tank_remark : "Sin observaciones"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {tank_image !== null && (
                <img
                  style={{ width: "200px" }}
                  src={tank_image}
                  alt="Despresurizar"
                  onClick={() => handleImageModal(tank_image, tank_remark)}
                />
              )}
            </Grid>
          </Grid>
          {/*    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ backgroundColor: "white", paddingLeft: "10px" }}
    >
      <Grid
        item
        xs={9}
        style={{ color: "black", fontSize: "8px", textAlign: "left" }}
      >
        <Typography variant="body2" style={{ fontSize: "15px" }}>
          <strong>Observaciones:</strong> Despresurizar tanques de combustibles
          (abrir y cerrar tapas de estanque)
        </Typography>
      </Grid>
      <Grid
        item
        xs={3}
        style={{ color: "black", fontSize: "10px", textAlign: "center" }}
      >
        <Avatar
          style={{
            backgroundColor: "lightGreen",
            width: "20px",
            height: "20px",
            margin: "auto"
          }}
        >
          <ImageIcon style={{ fontSize: "12px" }} />{" "}
        </Avatar>
      </Grid>
    </Grid> */}
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={9}
              style={{ color: "black", fontSize: "8px", textAlign: "left" }}
            >
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                Drenar por completo el combustible del filtro decantador, luego
                llenar el filtro con bomba manual
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {fuel_filter_status === "OK"
                ? "REALIZADO"
                : fuel_filter_status === "NO_OK"
                ? "NO REALIZADO"
                : "NA"}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={9}
              style={{ color: "black", fontSize: "12px", textAlign: "left" }}
            >
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                <strong>OBSERVACIONES:</strong>{" "}
                {fuel_filter_remark !== "null"
                  ? fuel_filter_remark
                  : "Sin observaciones"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {fuel_filter_image !== null && (
                <img
                  style={{ width: "200px" }}
                  src={fuel_filter_image}
                  alt="Despresurizar"
                  onClick={() =>
                    handleImageModal(fuel_filter_image, fuel_filter_remark)
                  }
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "#022C42" }}
          >
            <Grid
              item
              xs={12}
              style={{ color: "white", fontSize: "15px", textAlign: "center" }}
            >
              Inspección de Batería
            </Grid>
          </Grid>

          {batteries &&
            batteries.map((battery, index) => (
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                style={{
                  backgroundColor: "white",
                  margin: "5px 0",
                  borderRadius: "14px",
                  border: "1px solid black"
                }}
                key={index}
              >
                <Grid
                  item
                  xs={2}
                  style={{
                    color: "black",
                    fontSize: "17px",
                    textAlign: "left",
                    paddingLeft: "10px"
                  }}
                >
                  BATERÍA {index + 1}
                </Grid>
                <Grid item xs={10}>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-start"
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center",
                        height: "15px"
                      }}
                    >
                      Controlar el estado de batería con MidTronic
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center",
                        height: "15px"
                      }}
                    >
                      <strong>VOLTAJE</strong>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center",
                        height: "15px"
                      }}
                    >
                      {battery.voltage !== "null" && battery.voltage + "V"}
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center",
                        height: "15px"
                      }}
                    >
                      <strong>AMPERAJE</strong>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center",
                        height: "15px"
                      }}
                    >
                      {battery.amperage !== "null" && battery.amperage + "CCA"}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center",
                        height: "15px"
                      }}
                    >
                      <strong>MARCA</strong>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center",
                        height: "15px"
                      }}
                    >
                      {battery.brand !== "null" && battery.brand}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center",
                        height: "15px"
                      }}
                    >
                      <strong>CAPACIDAD</strong>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center",
                        height: "15px"
                      }}
                    >
                      {battery.capacity !== "null" && battery.capacity + "Ah"}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center",
                        height: "17px"
                      }}
                    >
                      Si el test indica cargar batería, realizar nuevamente el
                      test con MidTronic luego del arranque del motor
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center"
                      }}
                    >
                      <strong>VOLTAJE</strong>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center"
                      }}
                    >
                      {battery.test_voltage !== "null" &&
                        battery.test_voltage + "V"}
                    </Grid>

                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center"
                      }}
                    >
                      <strong>AMPERAJE</strong>
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        color: "black",
                        fontSize: "10px",
                        textAlign: "center"
                      }}
                    >
                      {battery.test_amperage !== "null" &&
                        battery.test_amperage + "CCA"}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "#022C42" }}
          >
            <Grid
              item
              xs={12}
              style={{ color: "white", fontSize: "15px", textAlign: "center" }}
            >
              Inspección de Hábitaculo
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={9}
              style={{ color: "black", fontSize: "12px", textAlign: "left" }}
            >
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                Vaciar sistema de aire comprimido para unidades con sist. de
                freno neumático (pisar freno 8 veces a fondo)
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "15px", textAlign: "center" }}
            >
              {air_system_status === "OK"
                ? "REALIZADO"
                : air_system_status === "NO_OK"
                ? "NO REALIZADO"
                : "NA"}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={9}
              style={{ color: "black", fontSize: "12px", textAlign: "left" }}
            >
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                <strong>OBSERVACIONES:</strong>{" "}
                {air_system_remark !== "null"
                  ? air_system_remark
                  : "Sin observaciones"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {" "}
              {air_system_image !== null && (
                <img
                  style={{ width: "200px" }}
                  src={air_system_image}
                  alt="aire comprimido"
                  onClick={() =>
                    handleImageModal(air_system_image, air_system_remark)
                  }
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={9}
              style={{ color: "black", fontSize: "12px", textAlign: "left" }}
            >
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                Arrancar motor entre 15 a 30 minutos
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "15px", textAlign: "center" }}
            >
              {start_motor_status === "OK"
                ? "REALIZADO"
                : start_motor_status === "NO_OK"
                ? "NO REALIZADO"
                : "NA"}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={9}
              style={{ color: "black", fontSize: "12px", textAlign: "left" }}
            >
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                <strong>OBSERVACIONES:</strong>{" "}
                {start_motor_remark !== "null"
                  ? start_motor_remark
                  : "Sin observaciones"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {" "}
              {start_motor_image !== null && (
                <img
                  style={{ width: "200px" }}
                  src={start_motor_image}
                  alt="motor"
                  onClick={() =>
                    handleImageModal(start_motor_image, start_motor_remark)
                  }
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={9}
              style={{ color: "black", fontSize: "12px", textAlign: "left" }}
            >
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                Verificar si indica fallas en el tablero de instrumentos
                (indicar en observaciones e informar a supervisor)
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "15px", textAlign: "center" }}
            >
              {board_failures_status === "OK"
                ? "REALIZADO"
                : board_failures_status === "NO_OK"
                ? "NO REALIZADO"
                : "NA"}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={9}
              style={{ color: "black", fontSize: "12px", textAlign: "left" }}
            >
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                <strong>OBSERVACIONES:</strong>{" "}
                {board_failures_remark !== "null"
                  ? board_failures_remark
                  : "Sin observaciones"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {board_failures_image !== null && (
                <img
                  style={{ width: "200px" }}
                  src={board_failures_image}
                  alt="tablero"
                  onClick={() =>
                    handleImageModal(
                      board_failures_image,
                      board_failures_remark
                    )
                  }
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={9}
              style={{ color: "black", fontSize: "12px", textAlign: "left" }}
            >
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                Accionar sistema de A/C (verificar si enfría o no)
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "15px", textAlign: "center" }}
            >
              {ac_system_status === "OK"
                ? "REALIZADO"
                : ac_system_status === "NO_OK"
                ? "NO REALIZADO"
                : "NA"}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "white", paddingLeft: "10px" }}
          >
            <Grid
              item
              xs={9}
              style={{ color: "black", fontSize: "12px", textAlign: "left" }}
            >
              <Typography variant="body2" style={{ fontSize: "15px" }}>
                <strong>OBSERVACIONES:</strong>{" "}
                {ac_system_remark !== "null"
                  ? ac_system_remark
                  : "Sin observaciones"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {ac_system_image !== null && (
                <img
                  style={{ width: "200px" }}
                  src={ac_system_image}
                  alt="ac "
                  onClick={() =>
                    handleImageModal(ac_system_image, ac_system_remark)
                  }
                />
              )}
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "#022C42" }}
          >
            <Grid
              item
              xs={12}
              style={{ color: "white", fontSize: "15px", textAlign: "center" }}
            >
              Responsables
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              RESPONSABLE
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {responsible_full_name}
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {responsible_date && responsible_date.substring(0, 10)}
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              FIRMA
            </Grid>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              SUPERVISOR EXTERNO
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {external_supervisor_full_name}
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {external_supervisor_date &&
                external_supervisor_date.substring(0, 10)}
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              FIRMA
            </Grid>
          </Grid>

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              SUPERVISOR KAUFMANN
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {kaufman_supervisor_full_name}
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              {kaufmann_supervisor_date &&
                kaufmann_supervisor_date.substring(0, 10)}
            </Grid>
            <Grid
              item
              xs={3}
              style={{ color: "black", fontSize: "12px", textAlign: "center" }}
            >
              FIRMA
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ backgroundColor: "#022C42" }}
          >
            <Grid
              item
              xs={12}
              style={{ color: "white", fontSize: "15px", textAlign: "center" }}
            >
              Observaciones
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            style={{ color: "black", fontSize: "12px", textAlign: "center" }}
          >
            {remark !== "null" && remark}
          </Grid>
        </Page>
      )}
    </>
  );
};

/* eslint-disable-next-line */
const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      height: 800,
      overflow: "auto"
    },
    [theme.breakpoints.up("xs")]: {
      minHeight: 400
    }
  }
}));

export default SinglePage;
