import React from "react";
import PropTypes from "prop-types";
import { withStyles, createStyles } from "@material-ui/core/styles";
import clsx from "clsx";

// Material-UI
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Avatar,
  List,
  ListItem,
  ListItemIcon,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Divider,
  Drawer,
  Hidden
} from "@material-ui/core";

// MTERIAL ICONS
//import ExtensionIcon from "@material-ui/icons/Extension";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import MenuIcon from "@material-ui/icons/Menu";
import InsertChartIcon from "@material-ui/icons/InsertChart";
import PostAddIcon from "@material-ui/icons/PostAdd";
// import SearchIcon from '@material-ui/icons/Search';

import logoBaustack from "../assets/logo_blanco.png";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import RateReviewIcon from '@material-ui/icons/RateReview';
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";

// import PresentationCard from './PersentationCard';

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { logout } from "../redux/actions/auth/actions";
import axios from "axios";
import { API_BASE } from "../env";
import Loading from "../components/Loading";

class Header extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      mobileOpen: false,
      anchorEl: null,
      data: {
        profile: {}
      },
      picture: undefined,
      hasCompany: true,
      loading: false
    };
  }

  static propTypes = {
    auth: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired
  };

  async componentDidMount() {
    this.setState({ loading: true });
    this._isMounted = true;
    this.setState({ loading: false });
  }

  handleGetData = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const token = localStorage.getItem("token");
    const username = localStorage.getItem("username");

    config.headers["Authorization"] = `Token ${token}`;

    await axios
      .get(`${API_BASE}v1/users/${username}/`, config)
      .then(response => {
        if (this._isMounted) {
          this.setState({
            data: response.data
          });
        }
      })
      .catch(error => {
        //console.log(error.response.data);
      });
  };

  handleMobileClose = () => {
    this.setState({
      mobileOpen: false
    });
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleLogout = event => {
    event.preventDefault();
    this.props.logout();
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { classes, placeWeb, container, theme } = this.props;

    /*  if (!this.state.hasCompany) {
      return <Redirect to="/user-company" />;
    } */

    const pathname = window.location.pathname;

    const ListMenu = (
      <React.Fragment>
        <List>
          <ListItem
            button
            component={Link}
            to="/dashboard"
            onClick={this.handleMobileClose}
          >
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Tablero" />
          </ListItem>

          <ListItem
            button
            component={Link}
            to="/users"
            onClick={this.handleMobileClose}
          >
            <ListItemIcon>
              <PeopleAltIcon />
            </ListItemIcon>
            <ListItemText primary="Usuarios" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/addReport"
            onClick={this.handleMobileClose}
          >
            <ListItemIcon>
              <PostAddIcon />
            </ListItemIcon>
            <ListItemText primary="Stock" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/addFuelSupply"
            onClick={this.handleMobileClose}
          >
            <ListItemIcon>
              <LocalGasStationIcon /* style={{ color: "#95C225" }} */ />
            </ListItemIcon>
            <ListItemText primary="Suministro 8000SO" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/dispatchReview"
            onClick={this.handleMobileClose}
          >
            <ListItemIcon>
              <RateReviewIcon />
            </ListItemIcon>
            <ListItemText primary="Revisión Despacho" />
          </ListItem>
          <ListItem
            button
            component={Link}
            to="/reports"
            onClick={this.handleMobileClose}
          >
            <ListItemIcon>
              <InsertChartIcon />
            </ListItemIcon>
            <ListItemText primary="Reportes" />
          </ListItem>
          {/*          <ListItem
            button
            component={Link}
            to="/extensions"
            onClick={this.handleMobileClose}
          >
            <ListItemIcon>
              <Avatar className={classes.backgroundIcon}>
                <ExtensionIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText primary="Extensiones" />
          </ListItem> */}
        </List>
      </React.Fragment>
    );

    const drawer = (
      <React.Fragment>
        <div className={clsx(classes.toolbar, classes.title)}>
          <Link to="/dashboard" className={classes.link}>
            <Avatar src={logoBaustack} alt="SOMEC" className={classes.logo} />
          </Link>
        </div>
        <Divider />
        {ListMenu}
      </React.Fragment>
    );

    const menu = (
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchorEl}
        open={Boolean(this.state.anchorEl)}
        onClose={this.handleClose}
      >
        <MenuItem onClick={this.handleLogout}>Cerrar sesión</MenuItem>
      </Menu>
    );

    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div className={pathname === "/dashboard" ? null : classes.root}>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open menu"
              onClick={this.handleDrawerToggle}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap className={classes.headerText}>
              {placeWeb}
            </Typography>
            <Grid container justify="flex-end" alignItems="center">
              {/*  <Avatar
                alt={imageAlt}
                src={`${this.state.data.profile.picture}`}
                className={classes.avatar}
              /> */}
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="open menu"
                  onClick={this.handleClick}
                  //edge="start"
                >
                  <ArrowDropDown />
                </IconButton>
                {menu}
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer} aria-label="place web">
          <Hidden smUp implementation="css">
            <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === "rtl" ? "right" : "left"}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper
              }}
              ModalProps={{
                keepMounted: true // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.props.children}
        </main>
      </div>
    );
  }
}

const drawerWidth = 240;
const backgroundColor = "#022C42";

const styles = createStyles(theme => ({
  root: {
    display: "flex"
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      // background: 'linear-gradient(90deg, #00b9ef, #05e09c)'
      background: backgroundColor
    },
    [theme.breakpoints.up("xs")]: {
      // background: 'linear-gradient(90deg, #00b9ef, #05e09c)'
      background: backgroundColor
    }
  },

  headerText: {
    width: 200,
    marginLeft: 0,
    textAlign: "auto"
  },
  menuButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  avatar: {
    margin: 10
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth
  },
  title: {
    background: backgroundColor,
    color: "white",
    // boxShadow: '1px 1px 1px gray',
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: 40
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: "white"
  },

  link: {
    textDecoration: "none",
    display: "block",
    color: "black"
  },
  logo: {
    height: 50,
    width: 160,
    borderRadius: 0,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(0.5)
  },
  backgroundIcon: {
    background: "#95C225"
  }
}));

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  container: PropTypes.object,
  theme: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default compose(
  withStyles(styles, { withTheme: true }),
  connect(mapStateToProps, { logout })
)(Header);
