import axios from "axios";
import { API_BASE } from "../../../env/index";

import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS
} from "./actionType";

import { history } from "../../../routers/history";

// CHECK TOKEN AND LOAD USER
export const loadUser = () => (dispatch, getState) => {
  // GET TOKEN
  const token = getState().auth.token;

  // USER LOADING
  dispatch({
    type: USER_LOADING
  });

  // HEADERS
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };
  // IF TOKEN AND HEADERS CONFIG
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  axios
    .get(`${API_BASE}v1/users/current-user`, config)
    .then(res => {
      dispatch({
        type: USER_LOADED,
        payload: res.data
      });
    })
    .catch(error => {
      dispatch({
        type: AUTH_ERROR,
        payload: error.response
      });
    });
};

// LOGIN

export const login = (email, password) => dispatch => {
  // HEADERS
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  // REQUEST BODY
  const body = JSON.stringify({ email, password });

  // REQUEST
  axios
    .post(`${API_BASE}v1/users/login/`, body, config)
    .then(res => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
      history.push("/dashboard");
    })
    .catch(error => {
      console.error(error.response);
      dispatch({
        type: LOGIN_FAIL,
        payload: error.response
      });
    });
};

// LOGOUT

export const logout = () => (dispatch, getState) => {
  // GET TOKEN
  const token = getState().auth.token;

  // HEADERS
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  // IF TOKEN AND HEADERS CONFIG
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  axios
    .post(`${API_BASE}v1/users/logout/`, null, config)
    .then(res => {
      dispatch({
        type: LOGOUT_SUCCESS
      });
      if (res.data === null) {
        dispatch({
          type: AUTH_ERROR
        });
      }
    })
    .catch(error => {
      console.log(error.response);
    });
};

export const showSuccessSnackbar = message => {
  return dispatch => {
    dispatch({ type: "SNACKBAR_SUCCESS", message });
  };
};

export const clearSnackbar = () => {
  return dispatch => {
    dispatch({ type: "SNACKBAR_CLEAR" });
  };
};
