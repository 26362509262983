import React from "react";

import {
  //Button,
  Dialog,
  //DialogActions,
  DialogContent,
  DialogContentText,
  // DialogTitle,
  Grid,
  Typography
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export default function ImageModal(props) {
  const [open, setOpen] = React.useState(true);
  /* eslint-disable-next-line */

  const handleClose = () => {
    props.handleClose();
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" align="right">
            <CloseIcon onClick={handleClose} />
          </DialogContentText>
          <DialogContentText id="alert-dialog-description" align="left">
            <Typography>{props.currentObs}</Typography>
          </DialogContentText>
          <Grid container>
            <Grid item xs={12}>
              <img
                style={{ width: "300px" }}
                src={props.currentImage}
                alt="observation"
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
