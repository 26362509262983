import React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Page404 from "../pages/Page404";

import PrivateRoute from "./privateRoutes";

import AddIndicator from "../components/AddIndicator";

import Reports from "../components/Reports";
import ReportsAll from "../components/ReportTable";
import Report from "../components/Report";
// import FuelReport from "../components/FuelReport";
import Users from "../pages/Users";
// import FuelTable from "../components/FuelTable";
import DailyReport from "../components/DailyReport";
import Pdf from "../components/Pdf";
import { FilteredTable } from "../components/Table/FilteredTable";
import DispatchReview from "../components/DispatchReview";
import DispatchReviewPage from "../pages/DispatchView";
import FuelSupply from "../components/FuelSupply";
import FuelSupplyPage from "../pages/FuelSupply";

const AppRoutes = () => (
  <>
    <Switch>
      <Route exact path="/" component={Login} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/users" component={Users} />
      <PrivateRoute exact path="/addReport" component={AddIndicator} />
      <PrivateRoute exact path="/reports" component={Reports} />
      <PrivateRoute exact path="/reportsAll" component={ReportsAll} />
      <PrivateRoute exact path="/reports/:idReport" component={Report} />
      {/* <PrivateRoute exact path="/addFuelReport" component={FuelReport} /> */}
      {/* <PrivateRoute exact path="/fuelReports" component={FuelTable} /> */}
      <PrivateRoute exact path="/dispatchReview" component={DispatchReview} />
      <PrivateRoute exact path="/dispatchReviewData" component={DispatchReviewPage} />
      <PrivateRoute exact path="/addFuelSupply" component={FuelSupply} />
      <PrivateRoute exact path="/fuelSupplyData" component={FuelSupplyPage} />
      <PrivateRoute
        exact
        path="/dailyReport/:idReport"
        component={DailyReport}
      />

      <PrivateRoute
        exact
        path="/custom/:year/:month/:type/:assistance_location/:assistance_reason/:fuel_type"
        component={FilteredTable}
      />
      <Route exact path="/pdf" component={Pdf} />
      <Route component={Page404} />
    </Switch>
  </>
);

export default AppRoutes;
