import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import PostAddIcon from "@material-ui/icons/PostAdd";
import TodayIcon from "@material-ui/icons/Today";
//import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
//import CardActions from "@material-ui/core/CardActions";
//import { history } from "../routers/history";
import DeleteDialogReports from "./DeleteDialogReports";
import LocalGasStationIcon from "@material-ui/icons/LocalGasStation";
import RateReviewIcon from '@material-ui/icons/RateReview';

import {
  //FormControl,
  //InputLabel,
  //Select,
  //OutlinedInput,
  //MenuItem,
  //TextField,
  //Button,
  //Divider,
  Card,
  Grid,
  CardContent,
  Typography,

  //Menu,
  //MenuItem,
  CardHeader
  //Avatar
  //CardMedia
} from "@material-ui/core";
//import CustomTable from "./CustomTable";
//import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
//import SkipNextIcon from "@material-ui/icons/SkipNext";
//import PlayArrowIcon from "@material-ui/icons/PlayArrow";

class Reports extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      anchorEl: null,
      deleteDialog: false,
      selectedReport: undefined,
      loading: true
    };
  }
  componentDidMount() {
    //this.handleGetReports();
    document.title = "Somec - Reportes";
  }

  handleStatusDeleteDialog = () => {
    this.setState({
      deleteDialog: !this.state.deleteDialog
    });
  };

  handleGetReportSelected = id => {
    this.setState({
      selectedReport: id
    });
  };
  handleOpenMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  /*  handleDeleteIndicator = async id => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const token = localStorage.getItem("token");

    config.headers["Authorization"] = `Token ${token}`;

    await axios
      .delete(`${API_BASE}v1/indicators/${id}/`, config)
      .then(response => {
        alert("deleted");
      })
      .catch(error => {
        console.log(error.response.data);
        alert("error");
      })
      .finally(history.push("/indicators"));
  }; */

  /*   handleDeleteReport = async id => {
    const config = {
      headers: {}
    };

    const token = localStorage.getItem("token");

    config.headers["Authorization"] = `Token ${token}`;

    await axios
      .delete(`${API_BASE}v1/indicators/${id}/`, config)
      .then(response => {
        this.handleGetReports();
      })
      .catch(error => {
        console.log(error.response.data);
      });
  }; */

  render() {
    const { classes } = this.props;

    /*     const icon_more = (
      <div className={classes.iconMore}>
        <Grid item xs={12} md={12}>
          <Link to="/addIndicator" className={classes.alignIconAdd}>
            <Fab color="primary" className={classes.iconAdd}>
              <AddIcon />
            </Fab>
          </Link>
        </Grid>
      </div>
    ); */

    return (
      <div className={classes.div}>
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid item>
            <Card
              className={classes.card}
              style={{ backgroundColor: "#95C225" }}
            >
              <CardHeader />
              <CardContent className={classes.content}>
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.result}
                  style={{ color: "white" }}
                >
                  <Link to="/reportsAll" style={{ textDecoration: "none" }}>
                    <PostAddIcon
                      style={{ color: "white", fontSize: "150px" }}
                    />
                  </Link>
                </Typography>

                <Typography
                  variant="h6"
                  component="h6"
                  style={{
                    maxWidth: "200px",
                    overflowWrap: "break-word",
                    color: "white"
                  }}
                >
                  Reportes de inspección de stock
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* <Grid item>
            <Card
              className={classes.card}
              style={{ backgroundColor: "#c22561" }}
            >
              <CardHeader />
              <CardContent className={classes.content}>
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.result}
                  style={{ color: "white" }}
                >
                  {" "}
                  <Link to="/fuelReports" style={{ textDecoration: "none" }}>
                    <LocalGasStationIcon
                      style={{ color: "white", fontSize: "150px" }}
                    />
                  </Link>
                </Typography>

                <Typography
                  variant="h6"
                  component="h6"
                  style={{
                    maxWidth: "200px",
                    overflowWrap: "break-word",
                    color: "white"
                  }}
                >
                  Reportes de carga combustible
                </Typography>
              </CardContent>
            </Card>
          </Grid> */}

          <Grid item>
            <Card
              className={classes.card}
              style={{ backgroundColor: "#022C42" }}
            >
              <CardHeader />
              <CardContent className={classes.content}>
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.result}
                  style={{ color: "white" }}
                >
                  {" "}
                  <Link to="/fuelSupplyData" style={{ textDecoration: "none" }}>
                    <LocalGasStationIcon style={{ color: "white", fontSize: "150px" }} />
                  </Link>
                </Typography>

                <Typography
                  variant="h6"
                  component="h6"
                  style={{
                    maxWidth: "200px",
                    overflowWrap: "break-word",
                    color: "white"
                  }}
                >
                  Reporte de suministro 8000S0
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item>
            <Card
              className={classes.card}
              style={{ backgroundColor: "#600080" }}
            >
              <CardHeader />
              <CardContent className={classes.content}>
                <Typography
                  component="h2"
                  variant="h2"
                  className={classes.result}
                  style={{ color: "white" }}
                >
                  {" "}
                  <Link to="/dispatchReviewData" style={{ textDecoration: "none" }}>
                    <RateReviewIcon style={{ color: "white", fontSize: "150px" }} />
                  </Link>
                </Typography>

                <Typography
                  variant="h6"
                  component="h6"
                  style={{
                    maxWidth: "200px",
                    overflowWrap: "break-word",
                    color: "white"
                  }}
                >
                  Reporte de revisión de despacho
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {this.state.deleteDialog ? (
          <DeleteDialogReports
            hadleChangeFlag={this.handleStatusDeleteDialog}
            handleDeleteData={() =>
              this.handleDeleteReport(this.state.selectedReport)
            }
          />
        ) : null}
      </div>
    );
  }
}

const styles = createStyles(theme => ({
  select: {
    width: 300,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  searchButton: {
    width: 200,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  menuItem: {
    width: "100%"
  },
  link: {
    textDecoration: "none"
  },
  iconAdd: {
    backgroundColor: "#00B9EF",
    marginTop: theme.spacing(0),

    "&:hover": {
      backgroundColor: "#05E09C"
    }
  },
  input: {
    margin: theme.spacing(20, -1, -17),
    width: "36%"
  },
  card: {
    color: "white",
    minHeight: "260px",
    minWidth: "200px",
    borderRadius: "9%",
    marginLeft: 20,
    marginTop: 10
  },
  result: {
    marginTop: "30px",
    color: "white"
  },
  iconMore: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      right: theme.spacing(2),
      bottom: theme.spacing(2)
    },
    [theme.breakpoints.down("xs")]: {
      position: "absolute",
      right: theme.spacing(4),
      bottom: "10px"
    }
  },
  alignIconAdd: {
    float: "right"
  },
  div: {
    position: "relative"
  },
  expand: {
    marginLeft: "auto"
  },
  content: {
    marginTop: "-40px"
  }
}));

export default withStyles(styles)(Reports);
