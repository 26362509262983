import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
// import Fab from '@material-ui/core/Fab'
// import AddIcon from '@material-ui/icons/Add';
// import PropTypes from 'prop-types';

// import { Link } from 'react-router-dom'
import axios from "axios";
import { API_BASE } from "../env";
import Loading from "../components/Loading";
import Tittle from "../components/Tittle";
import TableUers from "../components/TableUsers";

class Users extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      loading: true,
      organization: undefined,
      users: [],
      company: undefined
    };
  }

  UNSAFE_componentWillMount() {
    this.handleGetCompany();
  }

  componentDidMount() {
    //this.handleGetUsers();
    document.title = "Somec - Usuarios";
  }

  handleGetCompany = async () => {
    this.setState({
      loading: false
    });
  };

  handleGetUsers = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const token = localStorage.getItem("token");
    const company = localStorage.getItem("company");
    config.headers["Authorization"] = `Token ${token}`;

    await axios
      .get(`${API_BASE}v1/company/${company}/members/`, config)
      .then(response => {
        this.setState({
          users: response.data
        });
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  render() {
    const { classes } = this.props;

    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <>
        <Tittle tittle="Usuarios" />
        <div className={classes.margin}>
          <TableUers users={this.state.users} />
        </div>
        {/* <div className = {classes.add}>
          <Grid container justify="flex-end" alignItems="center">
          <Link to = '/sign-up'>
            <Fab color="primary" aria-label="add">
              <AddIcon />
            </Fab>
          </Link>
          </Grid>
        </div> */}
      </>
    );
  }
}

const styles = createStyles(theme => ({
  icons: {
    backgroundColor: "red"
  },
  add: {
    marginTop: theme.spacing(2)
  },
  margin: {
    display: "grid",
    margin: theme.spacing(5)
  }
}));

// Users.propTypes = {
//   clasess: PropTypes.object.isRequired
// }

export default withStyles(styles)(Users);
