import React from "react";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

// import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import Loading from "./Loading";
import axios from "axios";
import { API_BASE } from "../env/index";
// import ToolBarStock from "./ToolBarStock";
import GridReportTable from "./Table/GridReportTable";
class ReportTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      result: [],
      selectedIdsArr: [],
      loading: true,
      openEditDialog: false,
      openAddDialog: false,
      id: undefined,
      bufferValue: "",
      columnName: "",
      search: "",
      data_: [],
      page: 0,
      rowsPerPage: 10,
      selected: [],
      idArray: [],
      dataDeleteSent: {
        index_array: []
      },
      alertDelete: false,
      tableName: "",
      openFullEditDialog: false,
      valores: [],
      idx: [],
      columnas: [],
      openFullAddDialog: false,
      idTable: null,
      column_id: [],
      selectedColumn: [],
      columnFiltered: [],
      anchorEl: null,
      columnValues: [],
      dataFiltered_: [],
      schoolName: "",
      school: null
    };
  }

  // Component Did Mount
  componentDidMount() {
    this.handleGetData();
    document.title = "Somec - Reportes";
  }

  // Close EditDialog
  handleCloseDialog = () => {
    this.setState({
      openEditDialog: false
    });
  };

  // Get Params to edit a specific value
  handleChangeParams = (event, _id, _valor, _columnName) => {
    this.setState({
      openEditDialog: !this.state.openEditDialog,
      id: _id,
      bufferValue: _valor,
      columnName: _columnName
    });
  };

  handleTestObject = (idx, val) => {
    return { id: idx, value: val };
  };

  // Get Table data from API
  handleGetData = async () => {
    const config = {
      headers: {}
    };

    const token = localStorage.getItem("token");

    config.headers["Authorization"] = `Token ${token}`;

    if (this.props.filteredData) {
      // DATA TO POST
      const dataPost = {
        year: Number(this.props.year),
        type: this.props.type,
        month: Number(this.props.month)
      };

      const dataPostFUEL = {
        year: Number(this.props.year),
        type: this.props.type,
        month: Number(this.props.month),
        fuel_type: this.props.fuel_type
      };

      const dataPostLocation = {
        year: Number(this.props.year),
        type: this.props.type,
        assistance_location: this.props.assistance_location
      };
      const dataPostReason = {
        year: Number(this.props.year),
        type: this.props.type,
        month: Number(this.props.month),
        assistance_reason: this.props.assistance_reason
      };

      // URLS
      const urlSTOCK = `${API_BASE}v1/stock/custom-data/`;
      const urlFUEL = `${API_BASE}v1/fuels/custom-data/`;
      const urlAssistance = `${API_BASE}v1/assistance/custom-data/`;

      axios
        .post(
          this.props.fuel_type !== "none"
            ? urlFUEL
            : this.props.assistance_location !== "none" ||
              this.props.assistance_reason !== "none" ||
              this.props.type === "assistance_battery"
            ? urlAssistance
            : urlSTOCK,
          this.props.fuel_type !== "none"
            ? dataPostFUEL
            : this.props.assistance_location !== "none"
            ? dataPostLocation
            : this.props.assistance_reason !== "none"
            ? dataPostReason
            : dataPost,
          config
        )
        .then(res => {
          this.setState({
            data: [res.data]
          });
        });
    } else {
      await axios
        .get(`${API_BASE}v1/stock/custom/`, config)
        .then(response => {
          let buffer = [response.data];
          this.setState({
            data: buffer
          });
        })
        .catch(error => {
          console.log(error.response);
        })
        .finally(this.setState({ loading: false }));
    }
  };

  // Transform to object array the data from API
  handleGetTableData = valor => {
    let column_buffer = [];

    valor.map(table =>
      table.column.map(column => column_buffer.push(column.column_name))
    );

    valor.map(table =>
      table.column.map(column => this.state.column_id.push(column.id_column))
    );

    //let columns = ["Columna_1", "Columna_2", "Columna_3"]

    const values = valor.reduce((acc, table) => {
      return acc.concat(
        table.column.map(column => {
          return column.values.map(item =>
            this.handleTestObject(item.id_value, item.value)
          );
        })
      );
    }, []);

    let rows = this.transpose(values);

    let dataNew = [];

    rows.forEach(value => {
      let obj = {};
      value.forEach((entry, i) => {
        obj[column_buffer[i]] = entry;
      });
      dataNew.push(obj);
    });

    this.setState({
      result: dataNew
    });
  };

  // Transpose array
  transpose = input => {
    // this function convert columns to rows
    return Object.keys(input[0]).map(x => {
      return input.map(y => {
        return y[x];
      });
    });
  };

  // Get Table name
  handleGetTableName = () => {
    const { data } = this.state;
    if (data.length > 0) {
      return data[0]["table_name"];
    }
  };

  // Show loading
  handleOpenLoading = () => {
    this.setState({ loading: true });
  };

  // Change Rows per page
  handleChangeRowsPerPage = event => {
    this.setState({
      rowsPerPage: parseInt(event.target.value),
      page: 0
    });
  };

  // Donwnload table
  handleGetIDS = ids => {
    this.setState({
      selectedIdsArr: ids
    });
  };

  handleDowndloadFile = async () => {
    // const ids = this.handleGetRbd(this.state.data, this.state.selected);
    const ids = this.state.selectedIdsArr;
    // console.log(ids)

    let idBuffer = [];
    /* eslint-disable-next-line */
    ids.map(id => {
      idBuffer.push(id[0]);
    });
    this.handleOpenLoading();

    const config = {
      responseType: "blob",
      headers: { "Content-Type": "application/json" }
    };
    const token = localStorage.getItem("token");

    config.headers["Authorization"] = `Token ${token}`;

    const data = {
      id_selected: idBuffer
    };

    await axios
      .post(`${API_BASE}v1/stock/download/`, data, config)
      .then(response => {
        let blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
        });

        let fileURL = window.URL.createObjectURL(blob);

        let fileLink = document.createElement("a");
        fileLink.href = fileURL;

        fileLink.download = "Reporte de stock.xlsx";
        fileLink.click();
      })
      .catch(error => {
        console.log(error.response.data);
      })
      .finally(this.setState({ loading: false }));
  };

  handleChangeFile = event => {
    this.setState(
      {
        file: event.target.files[0]
      },
      () => {
        if (this.state.file) {
          if (
            window.confirm(
              `¿Está seguro que desea cargar el archivo ${this.state.file.name}?`
            )
          ) {
            this.handleUploadFile();
          } else {
            this.setState({ file: null });
          }
        }
      }
    );
  };

  handleUploadFile = async () => {
    let data = new FormData();
    const config = { headers: { "Content-Type": "multipart/form-data" } };
    data.append("file", this.state.file);

    const token = localStorage.getItem("token");
    config.headers["Authorization"] = `Token ${token}`;

    const urlAssistance = `${API_BASE}v1/assistance/upload/`;
    const urlStock = `${API_BASE}v1/stock/upload/`;

    // console.log("this.props.assistance_reason", this.props.assistance_reason);
    // console.log(
    //   "this.props.assistance_location",
    //   this.props.assistance_location
    // );

    // console.log("this.props.type", this.props.type);

    await axios
      .post(
        this.props.assistance_reason || this.props.assistance_location
          ? this.props.assistance_location !== "none" ||
            this.props.assistance_reason !== "none" ||
            this.props.type
            ? urlAssistance
            : urlStock
          : urlStock,
        data,
        config
      )
      .then(response => {
        if (response.status === 201) {
          this.setState({ loading: false, file: null });
          this.handleGetData();
        }
      })
      .catch(() => {
        this.setState({ loading: false, file: null });
      })
      .then(() => {
        this.setState({ loading: false });
      })
      .finally(() => {
        this.handleGetData();
      });
  };

  // Search about object array
  handleSearch = event => {
    event.preventDefault();
    this.setState({
      data_: this.handleRealSearch(this.state.result, this.state.search),
      selected: []
    });
  };

  //Get all the values for a selected column
  handleColumnFilter = columnName => {
    let buffer = [];
    /* eslint-disable-next-line */
    this.state.result.map((row, key) => {
      this.state.data.map(table =>
        table.column.map((column, j) =>
          /* eslint-disable-next-line */
          Object.values(row).map((valor, i) => {
            if (j === i && columnName === column.column_name) {
              buffer.push(valor.value);
            }
          })
        )
      );
    });

    //Eliminate duplicates values
    const uniqueSet = new Set(buffer);

    const backToArray = [...uniqueSet];

    this.setState({
      columnFiltered: backToArray
    });
  };

  // Clear array when search is empty
  handleCleanResult = () => {
    if (this.state.search.length === 0) {
      this.setState({
        _data: []
      });
    }
  };

  // Change search "state"
  handleChange = prop => event => {
    let buffer = event.target.value.toLowerCase();
    this.setState({
      [prop]: buffer
    });
  };

  // Get search result and add to state variable "data_"
  handleRealSearch = (arr, value) => {
    let buffer;
    let result = [];
    for (let i = 0, iLen = arr.length; i < iLen; i++) {
      buffer = arr[i];
      for (let p in buffer) {
        let temp = buffer[p];
        for (let x in temp) {
          if (temp.value.toLowerCase().includes(value) && value !== "") {
            if (x && !result.includes(buffer)) {
              result.push(buffer);
            }
          }
        }
      }
    }
    return result;
  };

  // Get search result and add to state variable "data_" for a search by column
  handleRealColumnSearch = (arr, arrValue) => {
    if (
      this.state.columnValues.length > 0 ||
      this.state.selectedColumn.length > 0
    ) {
      this.setState({
        columnValues: [],
        selectedColumn: []
      });
    }
    let buffer;
    let result = [];
    /* eslint-disable-next-line */
    arrValue.map(value => {
      for (let i = 0, iLen = arr.length; i < iLen; i++) {
        buffer = arr[i];
        for (let p in buffer) {
          let temp = buffer[p];
          for (let x in temp) {
            if (temp.value === value && value !== "") {
              if (x && !result.includes(buffer)) {
                result.push(buffer);
              }
            }
          }
        }
      }
    });

    return result;
  };

  // Change table page
  handleChangePage = (event, newPage) => {
    this.setState({
      page: newPage
    });
  };

  // Select all rows on the table
  handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = this.state.data[0].map((entry, key) => key);
      this.setState({
        selected: newSelecteds
      });
      return;
    }
    this.setState({
      selected: [],
      idArray: []
    });
  };

  // Select row with a checkbox
  handleClickCheck = (event, key) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(key);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    //console.log(newSelected);
    this.setState({ selected: newSelected });
  };

  //Trigger function whit onClick to search the values selected and create the new table
  handleFilterColumnButton = () => {
    this.setState({ anchorEl: null });
    const { columnFiltered, selectedColumn } = this.state;
    let columnValues = [];
    selectedColumn.map(i =>
      /* eslint-disable-next-line */
      columnFiltered.map((columnName, key) => {
        if (i === key) {
          columnValues.push(columnName);
        }
      })
    );
    this.setState({
      columnValues: columnValues,
      data_: this.handleRealColumnSearch(this.state.result, columnValues)
    });
  };

  handleClickCheckColumn = (event, key) => {
    const { selectedColumn } = this.state;
    const selectedIndex = selectedColumn.indexOf(key);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedColumn, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedColumn.slice(1));
    } else if (selectedIndex === selectedColumn.length - 1) {
      newSelected = newSelected.concat(selectedColumn.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedColumn.slice(0, selectedIndex),
        selectedColumn.slice(selectedIndex + 1)
      );
    }
    //console.log(newSelected);
    this.setState({ selectedColumn: newSelected });
  };

  // Get key value from row between a checkbox
  handleIsSelected = id => {
    return this.state.selected.indexOf(id) !== -1;
  };
  // Get N id_rbd for DELETE
  handleGetRbd = (data, arrayKey) => {
    let buffer = [];
    /* eslint-disable-next-line */
    arrayKey.map(key => {
      buffer.push([data[0][key]["id"]]);
    });

    //console.log(result);
    /*  arrayKey.forEach(key => {
      let buffer = Object.values(data)[key];
      test = Object.values(buffer);
      test.forEach(id => result.push(id["id"]));
    }); */

    return buffer;
  };
  // Get the id_rbd of a row (EDIT, DELETE) or multiple rows
  handleGetArrayId = (data, arrayKey) => {
    let result = [data[0][arrayKey]["id_rbd"]];
    //console.log(result);
    /*  arrayKey.forEach(key => {
        let buffer = Object.values(data)[key];
        test = Object.values(buffer);
        test.forEach(id => result.push(id["id"]));
      }); */
    return result;
  };

  handleGetArrayValues = (data, arrayKey) => {
    let result = [];

    /*    arrayKey.forEach(key => {
      let buffer = Object.values(data)[key];
      test = Object.values(buffer);
      test.forEach(id => result.push(id["value"]));
    }); */
    return result;
  };

  handleClick = event => {
    //console.log(event.currentTarget);
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  // Delete all selected records
  handleDeleteData = () => {
    const { selected, data } = this.state;

    if (data === 0) {
      if (selected.length > 0) {
        this.setState(
          {
            dataDeleteSent: {
              index_array: this.handleGetRbd(data, selected)
            },
            selected: []
          },
          () => this.handleDeleteDataRequest(this.state.dataDeleteSent)
        );
      }
    } else if (data.length > 0) {
      if (selected.length > 0) {
        this.setState(
          {
            dataDeleteSent: {
              index_array: this.handleGetRbd(data, selected)
            },
            selected: []
          },
          () => this.handleDeleteDataRequest(this.state.dataDeleteSent)
        );
      }
    }
  };

  // Send Delete request to API
  handleDeleteDataRequest = data => {
    /* eslint-disable-next-line */
    Object.values(data).map(rmb => {
      /* eslint-disable-next-line */
      rmb.map(rbd => {
        /* eslint-disable-next-line */
        rbd.map(i => {
          const config = {
            headers: {
              "Content-Type": "application/json"
            }
          };

          const token = localStorage.getItem("token");

          config.headers["Authorization"] = `Token ${token}`;

          //const body = JSON.stringify(data);
          //console.log(body);

          axios
            .delete(`${API_BASE}v1/schools/${i}/`, config)
            .then(response => {
              this.setState({ loading: true });
              this.handleGetData();
            })
            .catch(error => {
              console.log(error.response.data);
            })
            .finally(
              this.setState({
                dataDeleteSent: {
                  index_array: []
                }
              })
            );
        });
      });
    });
  };

  handleResetId = () => {
    this.setState({ column_id: [] });
  };

  // Show delete confirmation
  handleOpenDeleteDialog = () => {
    this.setState({
      alertDelete: !this.state.alertDelete
    });
  };

  handleOpenFullEditDialog = () => {
    if (this.state.data_.length === 0) {
      this.setState({
        openFullEditDialog: !this.state.openFullEditDialog,
        columnas: this.handleGetColumnsArray(),
        valores: this.handleGetArrayValues(
          this.state.data,
          this.state.selected
        ),
        idx: this.handleGetArrayId(this.state.data, this.state.selected),
        school: this.handleGetSchool()
      });
    }
    if (this.state.data_.length > 0) {
      this.setState({
        openFullEditDialog: !this.state.openFullEditDialog,
        columnas: this.handleGetColumnsArray(),
        valores: this.handleGetArrayValues(
          this.state.data_,
          this.state.selected
        ),
        idx: this.handleGetArrayId(this.state.data_, this.state.selected)
      });
    }
  };

  handleOpenFullAddDialog = () => {
    this.setState({
      openFullAddDialog: !this.state.openFullAddDialog
    });
  };

  handleGetColumnsArray = () => {
    const { data } = this.state;

    if (data.length > 0) {
      let result = [];
      data.map(table =>
        table.map(column => {
          let obj = {};
          obj["column_name"] = column.name;
          obj["data_type"] = column.name;
          return result.push(obj);
        })
      );
      return result;
    }

    return;
  };

  wraperFunction = event => {
    this.handleClick(event);
    //this.handleColumnFilter(columnName);
  };

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleGetSchool = async () => {
    const id = this.handleGetArrayId(this.state.data, this.state.selected);

    const config = {
      headers: {}
    };

    const token = localStorage.getItem("token");

    config.headers["Authorization"] = `Token ${token}`;
    //let id_table = this.props.match.params.idTable;
    await axios
      .get(`${API_BASE}v1/schools/${id}/`, config)
      .then(response => {
        this.setState({ school: response.data });
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  render() {
    const { classes } = this.props;

    // if (this.state.loading) {
    //   return <Loading />;
    // }

    const table = (
      <GridReportTable
        tableData={this.state.data ? this.state.data[0] : []}
        handleGetIDS={this.handleGetIDS}
        handleGetNewSelected={this.handleGetNewSelected}
        handleGetData={this.handleGetData}
        handleDownloadFile={this.handleDowndloadFile}
        handleChangeFile={this.handleChangeFile}
        type={this.props.type}
      />
    );

    return (
      <>
        <Grid container>
          <Grid item lg={4} md={4} xl={4} xs={6}>
            {/*               {table_title}
             */}{" "}
          </Grid>
          <Grid item lg={4} md={4} xl={4} xs={6}>
            {/* {search} */}
          </Grid>
          <Grid item lg={4} md={4} xl={4} xs={12}>
            {/* {toolbar} */}
          </Grid>
        </Grid>
        {this.state.data.length === 0 ? (
          <Loading />
        ) : (
          <div className={classes.tableContainer}>{table}</div>
        )}
      </>
    );
  }
}

const styles = createStyles(theme => ({}));

export default withStyles(styles)(ReportTable);
