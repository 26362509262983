import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
import axios from "axios";
import { API_BASE } from "../env";
import Loading from "./Loading";
import moment from "moment";

import //FormControl,
//InputLabel,
//Select,
//OutlinedInput,
//MenuItem,
//TextField,
//Button,
//Divider,
//Grid
"@material-ui/core";
//import Tittle from "./Tittle";

import IndicatorNumerator from "./IndicatorNumerator";
import IndicatorDenominator from "./IndicatorDenominator";
import IndicatorData from "./IndicatorData";
import Habitaculo from "./Habitaculo";
import Observation from "./Observation";
//import Indicator from "./Indicator";
import { history } from "../routers/history";
// import Responsables from "./Responsables";
import { onFileLoaded, cleanImages } from "../redux/actions/images/actions";
import { connect } from "react-redux";
import { compose } from "redux";
class AddIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      dataTable: [],
      managementSystem: null,
      loading: false,
      selectedTable: "",
      indicatorName: "",
      indicatorType: "",
      indicatorDescription: "",
      listColumns: [],
      columnId: "",
      queryTypeList: [],
      queryType: null,
      numeratorColumnList: [],
      rowsNumerator: [
        {
          column: "",
          query: "",
          value: ""
        }
      ],
      rowsDenominator: [
        {
          voltaje: "",
          amperaje: "",
          marca: "",
          capacidad: "",
          voltaje_test: "",
          amperaje_test: ""
        }
      ],
      rowsCounter: 2,
      rowsCounterDenominator: 2,
      operationColumn: 1,
      queryTypeListText: [
        { query: "equal_to", data: "Igual a" },
        { query: "contains", data: "Contiene" }
      ],
      columnType: [],
      columnsData: [],
      querysData: [{ query: "equal_to", data: "Igual a" }],
      valuesData: [],
      querysDataDenominator: [{ query: "equal_to", data: "Igual a" }],
      hasCondition: false,
      hasConditionDenominator: false,
      vin_number: "",
      motor_number: "",
      factory: "",
      model: "",
      km: "",
      date: null,
      ac_system_remark: "",
      ac_system_image: "",
      visual_status: "",
      visual_remark: "",
      visual_image: "",
      tank_status: "",
      tank_remark: "",
      tank_image: "",
      fuel_filter_status: "",
      fuel_filter_remark: "",
      fuel_filter_image: "",
      air_system_status: "",
      air_system_remark: "",
      air_system_image: "",
      start_motor_status: "",
      start_motor_remark: "",
      start_motor_image: "",
      board_failures_status: "",
      board_failures_remark: "",
      board_failures_image: "",
      responsible_full_name: null,
      responsible_date: null,
      responsible_signature: null,
      external_supervisor_full_name: null,
      external_supervisor_date: null,
      external_supervisor_signature: null,
      kaufman_supervisor_full_name: null,
      kaufmann_supervisor_date: null,
      kaufmann_supervisor_signature: null,
      voltages: [],
      amperages: [],
      brands: [],
      capacities: "",
      test_voltages: [],
      test_amperages: [],
      remark: null,
      ac_system_sttus: ""
    };
  }
  handleChangeCondition = () => {
    this.setState({ hasCondition: !this.state.hasCondition });
  };

  handleChangeConditionDenominator = () => {
    this.setState({
      hasConditionDenominator: !this.state.hasConditionDenominator
    });
  };

  handleSetTotalRows = idColumn => {
    this.setState({ operationColumn: idColumn });
  };

  handleGetColumns = (event, data, idTable) => {
    event.preventDefault();
    let result = [];
    let id_table = idTable;
    for (let i = 0; i < data.length; i++) {
      let buffer = data[i];
      if (buffer.id_table === idTable) {
        result = buffer.column;
      }
    }
    this.setState({
      listColumns: result,
      selectedTable: id_table
    });

    //console.log(this.state);
  };

  handleGetColumnId = (event, id) => {
    event.preventDefault();

    this.setState({ columnId: id });
  };

  handleGetTable = idTable => {
    let id = idTable;
    this.setState({ selectedTable: id });

    // console.log(this.state);
  };

  handleChange = prop => event => {
    this.setState({
      [prop]: event.target.value
    });

    //console.log(this.state);
  };

  handleDateChange = date => {
    const newDate = moment(date).format("YYYY-MM-DDTHH:mm");
    this.setState({ date: newDate });
  };

  //set date and time for responsibles
  handleDateChangeResponsible = (date, type) => {
    const newDate = moment(date).format("YYYY-MM-DDTHH:mm");

    if (type === "responsible") {
      this.setState({ responsible_date: newDate });
    } else if (type === "external") {
      this.setState({ external_supervisor_date: newDate });
    } else if (type === "kaufmann") {
      this.setState({ kaufmann_supervisor_date: newDate });
    }
  };

  //Render the next form
  nextStep = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  //Render the previous form
  backStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });

    let newRows = [...this.state.rowsNumerator];
    newRows.splice(1);

    this.setState({
      rowsNumerator: newRows,
      rowsCounter: 2
    });
  };

  nextStepDenominator = () => {
    const { step } = this.state;
    this.setState({ step: step + 1 });
  };

  //Render the previous form
  backStepDenominator = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });

    let newRowsDenominator = [...this.state.rowsDenominator];
    newRowsDenominator.splice(1);

    this.setState({
      rowsCounterDenominator: 2,
      rowsDenominator: newRowsDenominator
    });
  };

  //Add row for numerator
  /* handleAddRow = () => {
    const { rowList } = this.state;
    let buffer = [1];
    buffer.push(1);
    this.setState({ rowList: buffer });
  }; */

  //Manage type of query that displays, depends on the column selected
  handleCleanQueryTypeList = () => {
    this.setState({ queryType: "None" });
  };

  //SendData to the server
  handleSendData = async () => {
    let amper = [];
    let amper_test = [];
    let vol_test = [];
    let volts = [];
    let brands = [];
    let capacities = [];

    let dateTime = Date.now();
    let dateTimeForNull = moment(dateTime).format("YYYY-MM-DDTHH:mm");

    console.log(dateTimeForNull);

    if (this.state.date === null) {
      this.setState({ date: dateTimeForNull });
    } else if (this.state.kaufmann_supervisor_date === null) {
      this.setState({
        kaufmann_supervisor_date: dateTimeForNull
      });
    } else if (this.state.external_supervisor_date === null) {
      this.setState({
        external_supervisor_date: dateTimeForNull
      });
    } else if (this.state.responsible_date === null) {
      this.setState({
        responsible_date: dateTimeForNull
      });
    }

    /* eslint-disable-next-line */
    this.state.rowsDenominator.map((row, i) => {
      if (row.amperaje === "") {
        amper.push(null);
      } else {
        amper.push(row.amperaje);
      }

      if (row.amperaje_test === "") {
        amper_test.push("null");
      } else {
        amper_test.push(row.amperaje_test);
      }

      if (row.voltaje_test === "") {
        vol_test.push("null");
      } else {
        vol_test.push(row.voltaje_test);
      }

      if (row.voltaje === "") {
        volts.push(null);
      } else {
        volts.push(row.voltaje);
      }

      if (row.capacidad === "") {
        capacities.push(null);
      } else {
        capacities.push(row.capacidad);
      }

      if (row.marca === "") {
        brands.push(null);
      } else {
        brands.push(row.marca);
      }
    });

    // const data = {};

    let data = new FormData();
    if (this.props.visual_image !== null) {
      data.append("visual_image", this.props.visual_image);
    }
    data.append("visual_remark", this.props.visual_remark);

    if (this.props.tank_image !== null) {
      data.append("tank_image", this.props.tank_image);
    }
    data.append("tank_remark", this.props.tank_remark);
    data.append("fuel_filter_remark", this.props.fuel_filter_remark);

    if (this.props.fuel_filter_image !== null) {
      data.append("fuel_filter_image", this.props.fuel_filter_image);
    }
    data.append("air_system_remark", this.props.air_system_remark);

    if (this.props.air_system_image !== null) {
      data.append("air_system_image", this.props.air_system_image);
    }
    data.append("start_motor_remark", this.props.start_motor_remark);
    if (this.props.start_motor_image !== null) {
      data.append("start_motor_image", this.props.start_motor_image);
    }
    data.append("board_failures_remark", this.props.board_failures_remark);
    if (this.props.board_failures_image !== null) {
      data.append("board_failures_image", this.props.board_failures_image);
    }
    data.append("ac_system_remark", this.props.ac_system_remark);
    if (this.props.ac_system_image !== null) {
      data.append("ac_system_image", this.props.ac_system_image);
    }
    data.append("vin_number", this.state.vin_number);
    data.append("motor_number", this.state.motor_number);
    data.append("factory", this.state.factory);
    data.append("model", this.state.model);
    data.append("km", this.state.km);

    if (this.state.date === null) {
      data.append("date", dateTimeForNull);
    } else {
      data.append("date", this.state.date);
    }

    data.append("visual_status", this.state.visual_status);
    data.append("tank_status", this.state.tank_status);
    data.append("fuel_filter_status", this.state.fuel_filter_status);
    data.append("air_system_status", this.state.air_system_status);
    data.append("start_motor_status", this.state.start_motor_status);
    data.append("board_failures_status", this.state.board_failures_status);
    data.append("remark", this.state.remark);
    volts.map(v => data.append("voltages", v));

    amper.map(am => data.append("amperages", am));

    brands.map(brand => data.append("brands", brand));

    capacities.map(capacity => data.append("capacities", capacity));

    // data.append("test_voltages", JSON.stringify(vol_test));

    vol_test.map(vTest => data.append("test_voltages", vTest));

    amper_test.map(amTest => data.append("test_amperages", amTest));

    data.append("responsible_full_name", this.state.responsible_full_name);

    if (this.state.responsible_date === null) {
      data.append("responsible_date", dateTimeForNull);
    } else {
      data.append("responsible_date", this.state.responsible_date);
    }

    data.append(
      "external_supervisor_full_name",
      this.state.external_supervisor_full_name
    );

    if (this.state.external_supervisor_date === null) {
      data.append("external_supervisor_date", dateTimeForNull);
    } else {
      data.append(
        "external_supervisor_date",
        this.state.external_supervisor_date
      );
    }

    data.append(
      "kaufman_supervisor_full_name",
      this.state.kaufman_supervisor_full_name
    );

    if (this.state.kaufmann_supervisor_date === null) {
      data.append("kaufmann_supervisor_date", dateTimeForNull);
    } else {
      data.append(
        "kaufmann_supervisor_date",
        this.state.kaufmann_supervisor_date
      );
    }

    data.append("ac_system_status", this.state.ac_system_sttus);

    // New Params
    // data.append("assistance", this.state.assistance);

    // if (this.state.assistance_reason === "0" || !this.state.assistance) {
    //   data.append("assistance_reason", null);
    // } else {
    //   data.append("assistance_reason", this.state.assistance_reason);
    // }

    // if (this.state.assistance_location === "0" || !this.state.assistance) {
    //   data.append("assistance_location", null);
    // } else {
    //   data.append("assistance_location", this.state.assistance_location);
    // }

    /* const handleUpdatePicture = async file => {
      let data = new FormData();
      const config = {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      };
      data.append("picture", file);

      const token = localStorage.getItem("token");
      config.headers["Authorization"] = `Token ${token}`;

      await axios
        .patch(
          `${API_BASE}v1/users/${this.props.match.params.idProfile}/profile/`,
          data,
          config
        )
        .then(response => {
          //console.log(response.data);
        })
        .catch(error => {
          console.log(error.response.data);
        });
    }; */

    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    const token = localStorage.getItem("token");

    config.headers["Authorization"] = `Token ${token}`;

    //const body = JSON.stringify(data);

    await axios
      .post(`${API_BASE}v1/stock/new/`, data, config)
      .then(response => {
        //console.log("ingresado");
      })
      .catch(error => {
        console.log(error.response.data);
      })
      .finally(this.props.cleanImages());

    history.push("/reportsAll");
  };

  //Manage rows values for numerator
  handleRowChangeColumn = idx => e => {
    const { rowsNumerator } = this.state;
    const { value } = e.target;
    //const rows = [...this.state.rowsNumerator];
    /* rows[idx] = {
      column: value
    }; */
    rowsNumerator[idx].column = value;
    this.forceUpdate();

    /* this.setState({  
      rowsNumerator: update(this.state.rowsNumerator, {
        idx: { column: { $set: value } }
      })
    }); */
    /* this.setState({
      rowsNumerator: rows
    }); */
  };

  handleRowChangeQuery = idx => e => {
    const { rowsNumerator } = this.state;

    const {
      value
    } = e.target; /* 
    rows[idx] = {
      query: value
    };
    this.setState({
      rowsNumerator: rows
    }); */
    /*  const rows = [...this.state.rowsNumerator]; */
    //this.state.querysData.push(value);

    rowsNumerator[idx].query = value;
    this.forceUpdate();
  };

  handleRowChangeValue = idx => e => {
    const { rowsNumerator } = this.state;

    const { value } = e.target;
    /*     const rows = [...this.state.rowsNumerator];
    rows[idx] = {
      value: value
    };
    this.setState({
      rowsNumerator: rows
    }); */

    rowsNumerator[idx].value = value;
    this.forceUpdate();
  };

  //Denominator rows
  handleRowChangeColumnDenominator = idx => e => {
    const { rowsDenominator } = this.state;
    const { value } = e.target;
    //const rows = [...this.state.rowsNumerator];
    /* rows[idx] = {
      column: value
    }; */
    rowsDenominator[idx].column = value;
    this.forceUpdate();

    /* this.setState({
      rowsNumerator: update(this.state.rowsNumerator, {
        idx: { column: { $set: value } }
      })
    }); */
    /* this.setState({
      rowsNumerator: rows
    }); */
  };

  handleRowChangeQueryDenominator = idx => e => {
    const { rowsDenominator } = this.state;

    const {
      value
    } = e.target; /* 
    rows[idx] = {
      query: value
    };
    this.setState({
      rowsNumerator: rows
    }); */
    /*  const rows = [...this.state.rowsNumerator]; */

    rowsDenominator[idx].query = value;
    this.forceUpdate();
  };

  //Change voltage value fot batteries
  handleRowChangeValueDenominator = idx => e => {
    const { rowsDenominator } = this.state;

    const { value } = e.target;
    /*     const rows = [...this.state.rowsNumerator];
    rows[idx] = {
      value: value
    };
    this.setState({
      rowsNumerator: rows
    }); */
    rowsDenominator[idx].voltaje = value;
    this.forceUpdate();
  };

  //Change voltage value fot batteries
  handleRowChangeValueAmper = idx => e => {
    const { rowsDenominator } = this.state;

    const { value } = e.target;
    /*     const rows = [...this.state.rowsNumerator];
      rows[idx] = {
        value: value
      };
      this.setState({
        rowsNumerator: rows
      }); */
    rowsDenominator[idx].amperaje = value;
    this.forceUpdate();
  };
  handleRowChangeValueBrand = idx => e => {
    const { rowsDenominator } = this.state;

    const { value } = e.target;
    /*     const rows = [...this.state.rowsNumerator];
      rows[idx] = {
        value: value
      };
      this.setState({
        rowsNumerator: rows
      }); */
    rowsDenominator[idx].marca = value;
    this.forceUpdate();
  };
  handleRowChangeValueCapacity = idx => e => {
    const { rowsDenominator } = this.state;

    const { value } = e.target;
    /*     const rows = [...this.state.rowsNumerator];
      rows[idx] = {
        value: value
      };
      this.setState({
        rowsNumerator: rows
      }); */
    rowsDenominator[idx].capacidad = value;
    this.forceUpdate();
  };

  // New Values
  handleRowChangeBatery = () => {
    this.setState({ assistance: !this.state.assistance });

    // const { rowsDenominator } = this.state;

    // const { value } = e.target;

    // rowsDenominator[idx].battery_changed = !rowsDenominator[idx]
    //   .battery_changed;
    this.forceUpdate();
  };

  handleRowChangeAssistanceBattery = e => {
    // const { rowsDenominator } = this.state;

    const { value } = e.target;

    this.setState({ assistance_reason: value });
    // rowsDenominator[idx].assistance_battery = value;
    this.forceUpdate();
  };

  handleRowChangeLocation = e => {
    // const { rowsDenominator } = this.state;

    const { value } = e.target;

    this.setState({ assistance_location: value });
    // rowsDenominator[idx].location = value;
    this.forceUpdate();
  };

  // --

  handleRowChangeValueAmperTest = idx => e => {
    const { rowsDenominator } = this.state;

    const { value } = e.target;
    /*     const rows = [...this.state.rowsNumerator];
      rows[idx] = {
        value: value
      };
      this.setState({
        rowsNumerator: rows
      }); */
    rowsDenominator[idx].amperaje_test = value;
    this.forceUpdate();
  };
  handleRowChangeValueVolTest = idx => e => {
    const { rowsDenominator } = this.state;

    const { value } = e.target;
    /*     const rows = [...this.state.rowsNumerator];
      rows[idx] = {
        value: value
      };
      this.setState({
        rowsNumerator: rows
      }); */
    rowsDenominator[idx].voltaje_test = value;
    this.forceUpdate();
  };
  //Add a new object (new row) Numerator
  handleAddRow = () => {
    const { rowsCounter } = this.state;
    if (this.state.listColumns.length >= this.state.rowsCounter) {
      const item = {
        column: "",
        query: "",
        value: ""
      };
      this.setState({
        rowsNumerator: [...this.state.rowsNumerator, item],
        rowsCounter: rowsCounter + 1
      });
    }
  };

  addRowNumber = () => {
    const item = {
      column: "",
      query: "",
      value: ""
    };
    this.setState({
      rowsNumerator: [...this.state.rowsNumerator, item],
      rowsCounter: this.state.rowsCounter + 1
    });
  };

  addRowNumberDenominator = () => {
    const item = {
      voltaje: "",
      amperaje: "",
      marca: "",
      capacidad: "",
      voltaje_test: "",
      amperaje_test: ""
    };
    this.setState({
      rowsDenominator: [...this.state.rowsDenominator, item],
      rowsCounterDenominator: this.state.rowsCounterDenominator + 1
    });
  };
  //Add a new object (new row) Numerator
  handleAddRowDenominator = () => {
    const { rowsCounterDenominator } = this.state;

    const item = {
      voltaje: "",
      amperaje: "",
      marca: "",
      capacidad: "",
      voltaje_test: "",
      amperaje_test: ""
    };
    if (this.state.rowsDenominator.length < 4) {
      this.setState({
        rowsDenominator: [...this.state.rowsDenominator, item],
        rowsCounterDenominator: rowsCounterDenominator + 1
      });
    }
  };

  //Remove the last row added
  handleRemoveRow = () => {
    const { rowsCounter } = this.state;
    this.setState({
      rowsNumerator: this.state.rowsNumerator.slice(0, -1),
      rowsCounter: rowsCounter - 1
    });
  };

  // Remove a scpecific row for numerator
  handleRemoveSpecificRow = idx => () => {
    const { rowsCounter, querysData } = this.state;

    let rows = [...this.state.rowsNumerator];
    rows.splice(idx, 1);

    querysData[idx] = "";
    this.forceUpdate();

    this.setState({ rowsNumerator: rows, rowsCounter: rowsCounter - 1 });

    if (this.state.rowsCounter === 2) {
      this.handleChangeCondition();
    }
  };

  // Remove a scpecific row for denominator
  handleRemoveSpecificRowDenominator = idx => () => {
    const { rowsCounterDenominator, querysDataDenominator } = this.state;

    let rows = [...this.state.rowsDenominator];
    rows.splice(idx, 1);

    querysDataDenominator[idx] = "";
    this.forceUpdate();

    if (this.state.rowsDenominator.length !== 1) {
      this.setState({
        rowsDenominator: rows,
        rowsCounterDenominator: rowsCounterDenominator - 1
      });
    }

    if (this.state.rowsCounterDenominator === 2) {
      this.handleChangeConditionDenominator();
    }
  };

  /*  handleObservation = (value, remark, image) => {
    if (value === "visual") {
      this.setState({ visual_remark: remark, visual_image: image });
    }
  }; */

  //Signature Related
  handleKaufmannSignature = signature => {
    this.setState({ kaufmann_supervisor_signature: signature });
  };

  showStep = () => {
    const { step } = this.state;
    const defaultValue = "None";
    if (step === 1)
      return (
        <IndicatorData
          defaultValue={defaultValue}
          selectedTable={this.state.selectedTable}
          tables={this.state.dataTable}
          handleChange={this.handleChange}
          nextStep={this.nextStep}
          listColumns={this.state.listColumns}
          handleGetColumns={this.handleGetColumns}
          handleGetColumnId={this.handleGetColumnId}
          indicatorName={this.state.indicatorName}
          indicatorType={this.state.indicatorType}
          indicatorDescription={this.state.indicatorDescription}
          vin_number={this.state.vin_number}
          motor_number={this.state.motor_number}
          factory={this.state.factory}
          model={this.state.model}
          km={this.state.km}
          date={this.state.date}
          handleDateChange={this.handleDateChange}
        />
      );

    if (step === 2)
      return (
        <IndicatorNumerator
          defaultValue={defaultValue}
          selectedTable={this.state.selectedTable}
          nextStep={this.nextStep}
          backStep={this.backStep}
          handleGetColumns={this.handleGetColumns}
          handleGetColumnId={this.handleGetColumnId}
          listColumns={this.state.listColumns}
          handleChange={this.handleChange}
          handleGetQueryTypeList={this.handleGetQueryTypeList}
          queryType={this.state.queryType}
          queryTypeList={this.state.queryTypeList}
          selectedColumn={this.state.selectedColumn}
          rowList={this.state.rowsNumerator}
          handleAddRow={this.handleAddRow}
          indicatorName={this.state.indicatorName}
          indicatorType={this.state.indicatorType}
          end={this.handleSendData}
          handleRowChangeColumn={this.handleRowChangeColumn}
          handleRowChangeQuery={this.handleRowChangeQuery}
          handleRowChangeValue={this.handleRowChangeValue}
          handleRemoveRow={this.handleRemoveRow}
          handleRemoveSpecificRow={this.handleRemoveSpecificRow}
          queryTypeListText={this.state.queryTypeListText}
          handleChangeColumnType={this.handleChangeColumnType}
          columnType={this.state.columnType}
          querysData={this.state.querysData}
          handleSetTotalRows={this.handleSetTotalRows}
          handleChangeCondition={this.handleChangeCondition}
          hasCondition={this.state.hasCondition}
          rowsCounter={this.state.rowsCounter}
          addRowNumber={this.addRowNumber}
          tank_status={this.state.tank_status}
          fuel_filter_status={this.fuel_filter_status}
          visual_status={this.state.visual_status}
        />
      );
    if (step === 3)
      return (
        <IndicatorDenominator
          state={this.state}
          defaultValue={defaultValue}
          selectedTable={this.state.selectedTable}
          nextStep={this.nextStepDenominator}
          backStep={this.backStepDenominator}
          handleGetColumns={this.handleGetColumns}
          handleGetColumnId={this.handleGetColumnId}
          listColumns={this.state.listColumns}
          handleChange={this.handleChange}
          handleGetQueryTypeList={this.handleGetQueryTypeListDenominator}
          queryType={this.state.queryType}
          queryTypeList={this.state.queryTypeList}
          selectedColumn={this.state.selectedColumn}
          rowList={this.state.rowsDenominator}
          handleAddRowDenominator={this.handleAddRowDenominator}
          indicatorName={this.state.indicatorName}
          indicatorType={this.state.indicatorType}
          end={this.handleSendData}
          handleRemoveRow={this.handleRemoveRow}
          handleRemoveSpecificRowDenominator={
            this.handleRemoveSpecificRowDenominator
          }
          handleRowChangeColumnDenominator={
            this.handleRowChangeColumnDenominator
          }
          handleRowChangeQueryDenominator={this.handleRowChangeQueryDenominator}
          handleRowChangeValueDenominator={this.handleRowChangeValueDenominator}
          handleSetTotalRows={this.handleSetTotalRows}
          hasCondition={this.state.hasConditionDenominator}
          rowsCounter={this.state.rowsCounterDenominator}
          addRowNumber={this.addRowNumberDenominator}
          handleChangeCondition={this.handleChangeConditionDenominator}
          handleChangeValueAmper={this.handleRowChangeValueAmper}
          handleChangeValueBrand={this.handleRowChangeValueBrand}
          handleChangeValueCapacity={this.handleRowChangeValueCapacity}
          handleChangeValueAmperTest={this.handleRowChangeValueAmperTest}
          handleChangeValueVolTest={this.handleRowChangeValueVolTest}
          handleRowChangeBatery={this.handleRowChangeBatery}
          handleRowChangeAssistanceBattery={
            this.handleRowChangeAssistanceBattery
          }
          handleRowChangeLocation={this.handleRowChangeLocation}
        />
      );
    if (step === 4)
      return (
        <Habitaculo
          defaultValue={defaultValue}
          selectedTable={this.state.selectedTable}
          nextStep={this.nextStepDenominator}
          backStep={this.backStepDenominator}
          handleGetColumns={this.handleGetColumns}
          handleGetColumnId={this.handleGetColumnId}
          listColumns={this.state.listColumns}
          handleChange={this.handleChange}
          handleGetQueryTypeList={this.handleGetQueryTypeListDenominator}
          queryType={this.state.queryType}
          queryTypeList={this.state.queryTypeList}
          selectedColumn={this.state.selectedColumn}
          rowList={this.state.rowsDenominator}
          handleAddRowDenominator={this.handleAddRowDenominator}
          indicatorName={this.state.indicatorName}
          indicatorType={this.state.indicatorType}
          end={this.handleSendData}
          handleRemoveRow={this.handleRemoveRow}
          handleRemoveSpecificRowDenominator={
            this.handleRemoveSpecificRowDenominator
          }
          handleRowChangeColumnDenominator={
            this.handleRowChangeColumnDenominator
          }
          handleRowChangeQueryDenominator={this.handleRowChangeQueryDenominator}
          handleRowChangeValueDenominator={this.handleRowChangeValueDenominator}
          handleSetTotalRows={this.handleSetTotalRows}
          hasCondition={this.state.hasConditionDenominator}
          rowsCounter={this.state.rowsCounterDenominator}
          addRowNumber={this.addRowNumberDenominator}
          handleChangeCondition={this.handleChangeConditionDenominator}
          air_system_status={this.state.air_system_status}
          start_motor_status={this.state.start_motor_status}
          board_failures_status={this.state.board_failures_status}
          ac_system_sttus={this.state.ac_system_sttus}
        />
      );

    // if (step === 5)
    //   return (
    //     <Responsables
    //       defaultValue={defaultValue}
    //       selectedTable={this.state.selectedTable}
    //       nextStep={this.nextStepDenominator}
    //       backStep={this.backStepDenominator}
    //       handleGetColumns={this.handleGetColumns}
    //       handleGetColumnId={this.handleGetColumnId}
    //       listColumns={this.state.listColumns}
    //       handleChange={this.handleChange}
    //       handleGetQueryTypeList={this.handleGetQueryTypeListDenominator}
    //       queryType={this.state.queryType}
    //       queryTypeList={this.state.queryTypeList}
    //       selectedColumn={this.state.selectedColumn}
    //       rowList={this.state.rowsDenominator}
    //       handleAddRowDenominator={this.handleAddRowDenominator}
    //       indicatorName={this.state.indicatorName}
    //       indicatorType={this.state.indicatorType}
    //       end={this.handleSendData}
    //       handleRemoveRow={this.handleRemoveRow}
    //       handleRemoveSpecificRowDenominator={
    //         this.handleRemoveSpecificRowDenominator
    //       }
    //       handleRowChangeColumnDenominator={
    //         this.handleRowChangeColumnDenominator
    //       }
    //       handleRowChangeQueryDenominator={this.handleRowChangeQueryDenominator}
    //       handleRowChangeValueDenominator={this.handleRowChangeValueDenominator}
    //       handleSetTotalRows={this.handleSetTotalRows}
    //       hasCondition={this.state.hasConditionDenominator}
    //       rowsCounter={this.state.rowsCounterDenominator}
    //       addRowNumber={this.addRowNumberDenominator}
    //       handleChangeCondition={this.handleChangeConditionDenominator}
    //       responsible_full_name={this.state.responsible_full_name}
    //       responsible_date={this.state.responsible_date}
    //       responsible_signature={this.state.responsible_signature}
    //       external_supervisor_full_name={
    //         this.state.external_supervisor_full_name
    //       }
    //       external_supervisor_date={this.state.external_supervisor_date}
    //       external_supervisor_signature={
    //         this.state.external_supervisor_signature
    //       }
    //       kaufman_supervisor_full_name={this.state.kaufman_supervisor_full_name}
    //       kaufmann_supervisor_date={this.state.kaufmann_supervisor_date}
    //       kaufmann_supervisor_signature={
    //         this.state.kaufmann_supervisor_signature
    //       }
    //       handleDateChangeResponsible={this.handleDateChangeResponsible}
    //     />
    //   );

    if (step === 5)
      return (
        <Observation
          defaultValue={defaultValue}
          selectedTable={this.state.selectedTable}
          nextStep={this.nextStepDenominator}
          backStep={this.backStepDenominator}
          handleGetColumns={this.handleGetColumns}
          handleGetColumnId={this.handleGetColumnId}
          listColumns={this.state.listColumns}
          handleChange={this.handleChange}
          handleGetQueryTypeList={this.handleGetQueryTypeListDenominator}
          queryType={this.state.queryType}
          queryTypeList={this.state.queryTypeList}
          selectedColumn={this.state.selectedColumn}
          rowList={this.state.rowsDenominator}
          handleAddRowDenominator={this.handleAddRowDenominator}
          indicatorName={this.state.indicatorName}
          indicatorType={this.state.indicatorType}
          end={this.handleSendData}
          handleRemoveRow={this.handleRemoveRow}
          handleRemoveSpecificRowDenominator={
            this.handleRemoveSpecificRowDenominator
          }
          handleRowChangeColumnDenominator={
            this.handleRowChangeColumnDenominator
          }
          handleRowChangeQueryDenominator={this.handleRowChangeQueryDenominator}
          handleRowChangeValueDenominator={this.handleRowChangeValueDenominator}
          handleSetTotalRows={this.handleSetTotalRows}
          hasCondition={this.state.hasConditionDenominator}
          rowsCounter={this.state.rowsCounterDenominator}
          addRowNumber={this.addRowNumberDenominator}
          handleChangeCondition={this.handleChangeConditionDenominator}
          remark={this.state.remark}
          handleDateChangeExternalSupervisor={
            this.handleDateChangeExternalSupervisor
          }
          handleDateChangeKaufmann={this.handleDateChangeKaufmann}
          kaufmann_supervisor_date={this.state.kaufmann_supervisor_date}
          external_supervisor_date={this.state.external_supervisor_date}
        />
      );
  };

  render() {
    const { loading } = this.state;
    /*    const values = {
      selectedTable,
      indicatorType,
      indicatorName
    }; */

    //const { classes } = this.props;

    //console.log(listColumns)

    if (loading) {
      return <Loading />;
    }

    return <>{this.showStep()}</>;
  }
}

const styles = createStyles(theme => ({
  select: {
    width: 300,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  searchButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 30
  },
  menuItem: {
    width: "100%"
  }
}));

const mapStateToProps = state => ({
  visual_image: state.images.visual_image,
  visual_remark: state.images.visual_remark,
  tank_image: state.images.tank_image,
  tank_remark: state.images.tank_remark,
  fuel_filter_image: state.images.fuel_filter_image,
  fuel_filter_remark: state.images.fuel_filter_remark,
  air_system_remark: state.images.air_system_remark,
  air_system_image: state.images.air_system_image,
  start_motor_remark: state.images.start_motor_remark,
  start_motor_image: state.images.start_motor_image,
  board_failures_image: state.images.board_failures_image,
  board_failures_remark: state.images.board_failures_remark,
  ac_system_remark: state.images.ac_system_remark,
  ac_system_image: state.images.ac_system_image
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, { onFileLoaded, cleanImages })
)(AddIndicator);
