import React, { useEffect } from "react";
// import PropTypes from "prop-types";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Slide,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextareaAutosize,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { API_BASE } from "../env";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function EditFuel(props) {
  // const [open, setOpen] = React.useState(true);
  // const id = props.idArray;
  const [values, setValues] = React.useState(props.arrayValues);
  /* eslint-disable-next-line */
  const [fuel, setFuel] = React.useState(props.fuel);
  const [vinNumber, setVinNumber] = React.useState("");
  const [orderNumber, setOrderNumber] = React.useState("");
  const [model, setModel] = React.useState("");
  const [fuelType, setFuelType] = React.useState("");
  const [idJet, setIdJet] = React.useState("");
  const [liters, setLiters] = React.useState();
  const [date, setDate] = React.useState();
  const [responsible, setResponsible] = React.useState("");
  const [remark, setRemark] = React.useState();
  /* eslint-disable-next-line */
  const [idx, setIdx] = React.useState();

  useEffect(() => {
    handleGetFuel();
    /* eslint-disable-next-line */
  }, [props.idToEdit]);

  const handleGetFuel = async () => {
    if (props.idToEdit) {
      const config = {
        headers: {}
      };

      const token = localStorage.getItem("token");

      config.headers["Authorization"] = `Token ${token}`;
      //let id_table = this.props.match.params.idTable;
      await axios
        .get(`${API_BASE}v1/fuels/${props.idToEdit}/`, config)
        .then(response => {
          setIdx(response.data.id);
          setVinNumber(response.data.vin_number);
          setOrderNumber(response.data.order_number);
          setModel(response.data.model);
          setFuelType(response.data.fuel_type);
          setIdJet(response.data.id_jet);
          setLiters(response.data.liters);
          setDate(response.data.date);
          setResponsible(response.data.responsible_full_name);
          setRemark(response.data.remark);
        })
        .catch(error => {
          console.log(error.response);
        });
      //.finally(props.handleCloseDialog());
    }
  };

  const handleClose = () => {
    // props.handleCloseDialog();
    props.setOpen(false);
  };

  const handleUpdateValue = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };

    const token = localStorage.getItem("token");
    config.headers["Authorization"] = `Token ${token}`;

    const body = {
      vin_number: vinNumber,
      order_number: orderNumber,
      model: model,
      fuel_type: fuelType,
      id_jet: idJet,
      liters: liters,
      date: date,
      responsible_full_name: responsible,
      remark: remark
    };

    await axios
      .patch(`${API_BASE}v1/fuels/${props.idToEdit}/`, body, config)
      .then(response => {
        props.updateData();
      })
      .catch(error => {
        console.log(error.response);
      })
      .finally(() => {
        props.handleGetData();
        props.setSelectedRow([]);
      });
  };

  // Get Table data from API

  const handleSubmit = event => {
    event.preventDefault();
    handleUpdateValue();
    handleClose();
  };
  /* eslint-disable-next-line */
  const handleDataChange = (i, event) => {
    let data_entry = [...values];
    data_entry[i] = event.target.value;
    setValues(data_entry);
  };

  const classes = styles();

  const form = (
    <>
      <form onSubmit={handleSubmit}>
        <div>
          <TextField
            label="Nº VIN"
            name="schoolName"
            type="text"
            variant="outlined"
            pattern="text"
            className={classes.input}
            onChange={e => setVinNumber(e.target.value)}
            InputLabelProps={{
              shrink: true
            }}
            required
            fullWidth
            value={vinNumber}
          />
        </div>
        <div>
          <TextField
            label="Nº Orden"
            name="email"
            type="email"
            variant="outlined"
            pattern="email"
            className={classes.input}
            onChange={e => setOrderNumber(e.target.value)}
            InputLabelProps={{
              shrink: true
            }}
            required
            fullWidth
            value={orderNumber}
          />
        </div>
        <div>
          <TextField
            label="Modelo"
            name="modelo"
            variant="outlined"
            className={classes.input}
            onChange={e => setModel(e.target.value)}
            InputLabelProps={{
              shrink: true
            }}
            required
            fullWidth
            value={model}
          />
        </div>
        <div>
          <br />
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="demo-simple-select-outlined-label">Tipo</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={fuelType}
              onChange={e => setFuelType(e.target.value)}
              style={{ width: "295px", height: "50px", marginTop: "14px" }}
            >
              <MenuItem value="BENCINA">BENCINA</MenuItem>
              <MenuItem value="DIESEL">DIESEL</MenuItem>
              <MenuItem value="ADBLUE">ADBLUE</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div>
          <TextField
            label="Surtidor"
            variant="outlined"
            className={classes.input}
            onChange={e => setIdJet(e.target.value)}
            InputLabelProps={{
              shrink: true
            }}
            required
            fullWidth
            value={idJet}
          />
        </div>
        <div>
          <TextField
            label="Litros"
            name="staffAmount"
            variant="outlined"
            className={classes.input}
            onChange={e => setLiters(e.target.value)}
            InputLabelProps={{
              shrink: true
            }}
            required
            fullWidth
            value={liters || ""}
          />
        </div>
        <div>
          <TextField
            label="Responsable"
            variant="outlined"
            className={classes.input}
            onChange={e => setResponsible(e.target.value)}
            InputLabelProps={{
              shrink: true
            }}
            required
            fullWidth
            value={responsible}
          />
        </div>

        <div>
          <br />
          <Typography variant="body1" align="left">
            Observaciones
          </Typography>
          <TextareaAutosize
            label="Observaciones"
            rowsMax={6}
            aria-label="maximum height"
            placeholder="Observaciones..."
            value={remark}
            onChange={e => setRemark(e.target.value)}
          />
        </div>
      </form>
    </>
  );

  return (
    <div className={classes.container}>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        TransitionComponent={Transition}
        keepMounted
        disableBackdropClick
      >
        <DialogTitle id="form-dialog-title">Actualizar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ingrese los valores a actualizar
          </DialogContentText>
          {form}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" variant="contained">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Actualizar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const styles = makeStyles(theme => ({
  container: {
    display: "grid"
  },
  input: {
    marginTop: theme.spacing(2),
    width: 400
  },
  formControl: {
    margin: 0,
    fullWidth: true,
    display: "flex",
    wrap: "nowrap"
  }
}));
