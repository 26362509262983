import {
  OPEN_NOTIFICATION,
  CLOSE_NOTIFICATION
} from "../actions/alerts/actionType";

const initialState = {
  open: false,
  type: null,
  message: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case OPEN_NOTIFICATION:
      console.log(action);
      return {
        ...state,
        open: true,
        message: action.payload.message,
        type: action.payload.notification
      };
    //console.log(state);
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        open: false
      };

    default:
      return state;
  }
}
