import React from "react";

class Page404 extends React.Component {
  componentDidMount() {
    document.title = "Baustack - Página web no encontrada";
  }

  handleBack = () => {
    window.history.back();
  };

  render() {
    return (
      <div>
        <h1>Upss! La dirección a la que acabas de acceder no existe</h1>
        <h2 onClick={this.handleBack}>atras</h2>
      </div>
    );
  }
}

export default Page404;
