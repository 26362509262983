import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import {
  reasonNameFinder,
  reasonNumberFinder,
  yearOptions
} from "./helpers/YearSelect";
import { API_BASE } from "../../env";
import axios from "axios";
import { history } from "../../routers/history";

import ReactLoading from "react-loading";

const options = {
  maintainAspectRatio: false,
  scales: {
    y: {
      stacked: true,
      grace: "5%",
      ticks: {
        beginAtZero: true
      }
    },
    x: {
      stacked: true
    }
  }
};

const StackedGraph = ({ title, graph_type }) => {
  const [value, setValue] = useState(() => {
    const currentData = new Date();
    return currentData.getFullYear();
  });

  const [dataFetched, setDataFetched] = useState(null);

  useEffect(() => {
    if (value && graph_type) {
      const config = {
        headers: {}
      };

      const token = localStorage.getItem("token");

      config.headers["Authorization"] = `Token ${token}`;

      const dataPOST = {
        year: value,
        graph_type: graph_type
      };

      const urlSTOCK = `${API_BASE}v1/stock/graph/`;
      const urlFUEL = `${API_BASE}v1/assistance/graph/`;

      // graph_type === "assistance_reason"
      axios
        .post(
          graph_type !== "assistance_reason" ? urlSTOCK : urlFUEL,
          dataPOST,
          config
        )
        .then(res => {
          setDataFetched(res.data);
        })
        .then(() => {});
    }
  }, [value, graph_type]);

  const handleName = tag => {
    switch (tag) {
      case "visual_status":
        return "Suma de Inspección Visual";
      case "tank_status":
        return "Suma de Despresurizar Tanque";
      case "fuel_filter_status":
        return "Suma de Drenar Combustible";
      case "air_system_status":
        return "Suma de Vaciar Sistema de Aire";
      case "board_failures_status":
        return "Suma de Fallas en el Tablero";
      case "start_motor_status":
        return "Suma de Sistema A/C";
      default:
        break;
    }
  };

  const dataSets = () => {
    let arr = [];

    dataFetched &&
      dataFetched.forEach((el, index) => {
        const colors = [
          "#003f5c",
          "#005c78",
          "#007a80",
          "#009673",
          "#31af53",
          "#95c225",
          "#003f5c",
          "#005c78",
          "#007a80",
          "#009673",
          "#31af53",
          "#95c225",
          "#003f5c",
          "#005c78",
          "#007a80",
          "#009673",
          "#31af53",
          "#95c225"
        ];

        arr = [
          ...arr,
          {
            label: !!el.reason
              ? reasonNameFinder(el.reason)
              : handleName(el.tag),
            backgroundColor: colors[index],

            data: [
              el.data?.Enero,
              el.data?.Febrero,
              el.data?.Marzo,
              el.data?.Abril,
              el.data?.Mayo,
              el.data?.Junio,
              el.data?.Julio,
              el.data?.Agosto,
              el.data?.Septiembre,
              el.data?.Octubre,
              el.data?.Noviembre,
              el.data?.Diciembre
            ]
          }
        ];
      });

    return arr;
  };

  const dataToDisplay = dataFetched && {
    labels: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],

    datasets: dataSets()
  };

  const handleChange = event => {
    setValue(event.target.value);
  };

  return (
    <>
      <div className="header">
        <h2 className="graph_title">{title}</h2>
        <div className="select_input">
          <FormControl>
            <InputLabel id="demo-simple-select-label">Año</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              onChange={handleChange}
            >
              {yearOptions &&
                yearOptions.map((opt, i) => {
                  return (
                    <MenuItem key={`optMenu//${i * 67}-`} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      </div>
      {dataFetched ? (
        <div>
          <Bar
            data={dataToDisplay}
            options={{
              ...options,
              plugins: {
                legend: graph_type === "assistance_reason" ? false : true
              },
              onClick: (evt, el, chart) => {
                if (el.length > 0) {
                  history.push(
                    `/custom/${value}/${el[0].index + 1}/${graph_type}/none/${
                      graph_type !== "stock_anomalies_per_month"
                        ? reasonNumberFinder(
                            chart.data.datasets[el[0].datasetIndex].label
                          )
                        : "none"
                    }/none`
                  );

                  // console.log(
                  //   "Active el list: ",
                  //   chart.data.datasets[el[0].datasetIndex].label.split(" ")[1]
                  // );
                  // let point = chart.getElementsAtEventForMode(
                  //   evt,
                  //   "point",
                  //   {
                  //     intersect: true
                  //   },
                  //   true
                  // );
                  // console.log(
                  //   "Mode point list: ",
                  //   chart.data.datasets[point[0].datasetIndex].label,
                  //   chart.data.datasets[point[0].datasetIndex].data[
                  //     point[0].index
                  //   ]
                  // );
                  // console.log("index: ", el[0].index);
                  // console.log("Label: ", chart.data.labels[el[0].index]);
                }
              }
            }}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <ReactLoading
            type={"bars"}
            color={"#003f5c"}
            height={70}
            width={75}
          />
        </div>
      )}
    </>
  );
};

export default StackedGraph;
