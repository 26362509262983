import React from "react";

const StatusBoolComponent = (props) => {
  return (
    <>
      {props.data.status ? 'OK' : 'Con Observaciones'}
    </>
  );
};

export default StatusBoolComponent;
