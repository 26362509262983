import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";

import axios from "axios";
import { API_BASE } from "../../env";
import { yearOptions } from "./helpers/YearSelect";

import ReactLoading from "react-loading";
import { history } from "../../routers/history";

const VerticalGraph = ({ title, graph_type }) => {
  const [value, setValue] = useState(() => {
    const currentData = new Date();
    return currentData.getFullYear();
  });

  const handleChange = event => {
    setValue(event.target.value);
  };

  const [dataFetched, setDataFetched] = useState(null);

  useEffect(() => {
    if (value && graph_type) {
      const config = {
        headers: {}
      };

      const token = localStorage.getItem("token");

      config.headers["Authorization"] = `Token ${token}`;

      const dataPOST = {
        year: value,
        graph_type: graph_type
      };

      const urlStock = `${API_BASE}v1/stock/graph/`;
      const urlAssistance = `${API_BASE}v1/assistance/graph/`;
      const urlDispatchReview = `${API_BASE}v1/dispatches-review/graph/`;
      const urlFuelSupply = `${API_BASE}v1/fuel-supply/graph/`;

      const getGraphUrl = (type) => {
        if (type === "assistance_battery") {
          return urlAssistance;
        } else if (type === "dispatch_review_graph_per_year") {
          return urlDispatchReview;
        } else if (type === "fuel_supply_graph_per_year") {
          return urlFuelSupply;
        } else {
          return urlStock;
        }

      }

      axios
        .post(
          // graph_type !== "assistance_battery" ? urlStock : urlAssistance,
          getGraphUrl(dataPOST.graph_type),
          dataPOST,
          config
        )
        .then(res => {
          // console.log(graph_type, res.data);
          setDataFetched(res.data);
        })
        .then(() => {});
    }
  }, [value, graph_type]);

  const dataToDisplay = {
    labels: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    datasets: [
      {
        label: "Reportes",
        data: [
          dataFetched?.Enero,
          dataFetched?.Febrero,
          dataFetched?.Marzo,
          dataFetched?.Abril,
          dataFetched?.Mayo,
          dataFetched?.Junio,
          dataFetched?.Julio,
          dataFetched?.Agosto,
          dataFetched?.Septiembre,
          dataFetched?.Octubre,
          dataFetched?.Noviembre,
          dataFetched?.Diciembre
        ],
        backgroundColor: ["rgb(149, 194, 37)"],
        borderColor: ["rgb(149, 194, 37)"]
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      y: {
        grace: "5%"
      }
    },
    plugins: {
      legend: false
    }
  };

  return (
    <>
      <div className="header">
        <h2 className="graph_title">{title}</h2>
        <div className="select_input">
          <FormControl>
            <InputLabel id="demo-simple-select-label">Año</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={value}
              onChange={handleChange}
            >
              {yearOptions &&
                yearOptions.map((opt, i) => {
                  return (
                    <MenuItem key={`optMenu//${i * 67}-`} value={opt.value}>
                      {opt.label}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      </div>
      {dataFetched ? (
        <div>
          <Bar
            data={dataToDisplay}
            options={{
              ...options,
              onClick: (evt, el, chart) => {
                if (el.length > 0) {
                  history.push(
                    `/custom/${value}/${el[0].index +
                      1}/${graph_type}/none/none/none`
                  );

                  // console.log(
                  //   "Active el list: ",
                  //   chart.data.datasets[el[0].datasetIndex].label,
                  //   chart.data.datasets[el[0].datasetIndex].data[el[0].index]
                  // );

                  // let point = chart.getElementsAtEventForMode(
                  //   evt,
                  //   "point",
                  //   {
                  //     intersect: true
                  //   },
                  //   true
                  // );

                  // console.log(
                  //   "Mode point list: ",
                  //   chart.data.datasets[point[0].datasetIndex].label,
                  //   chart.data.datasets[point[0].datasetIndex].data[
                  //     point[0].index
                  //   ]
                  // );
                  // console.log("index: ", el[0].index);
                  // console.log("Label: ", chart.data.labels[el[0].index]);
                }
              }
            }}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center"
          }}
        >
          <ReactLoading
            type={"bars"}
            color={"#003f5c"}
            height={70}
            width={75}
          />
        </div>
      )}
    </>
  );
};

export default VerticalGraph;
