export const yearOptions = [
  {
    label: "2021",
    value: 2021
  },
  {
    label: "2022",
    value: 2022
  },
  {
    label: "2023",
    value: 2023
  },
  {
    label: "2024",
    value: 2024
  },
  {
    label: "2025",
    value: 2025
  },
  {
    label: "2026",
    value: 2026
  }
];

export const indexLabelFinder = (tag, dataFetched) => {
  const index = dataFetched && dataFetched.findIndex(el => el.tag === tag);
  return index;
};

export const reasonNameFinder = name => {
  switch (name) {
    case "1":
      return "Asistencia a Unidad(es) de Adelante";
    case "2":
      return "Bateria(s) con Carga";
    case "3":
      return "Bateria(s) con Carga Solo Combustible";
    case "4":
      return "Bateria(s) Descarga(s) y Falta Combustible";
    case "5":
      return "Bateria(s) No Carga(n) Post Ciclo de Carga";
    case "6":
      return "Corta Corriente Activo";
    case "7":
      return "Corta Corriente Inactivo";
    case "8":
      return "Luces Externas Encendidas";
    case "9":
      return "Luces Internas Encendidas";
    case "10":
      return "Perdida de Carga por No Uso";
    case "11":
      return "Radio Encendida";
    case "12":
      return "Tecnico(s) Taller No Respetan Tarja";
    case "13":
      return "Tecnico(s) Taller Trabajando en Unidad";
    case "14":
      return "Unidad Andando cuando Asistio SOMEC";
    case "15":
      return "Unidad(es) con Poca Carga desde Embarque";
    case "16":
      return "Otro";

    default:
      break;
  }
};

export const reasonNumberFinder = name => {
  switch (name) {
    case "Asistencia a Unidad(es) de Adelante":
      return "1";
    case "Bateria(s) con Carga":
      return "2";
    case "Bateria(s) con Carga Solo Combustible":
      return "3";
    case "Bateria(s) Descarga(s) y Falta Combustible":
      return "4";
    case "Bateria(s) No Carga(n) Post Ciclo de Carga":
      return "5";
    case "Corta Corriente Activo":
      return "6";
    case "Corta Corriente Inactivo":
      return "7";
    case "Luces Externas Encendidas":
      return "8";
    case "Luces Internas Encendidas":
      return "9";
    case "Perdida de Carga por No Uso":
      return "10";
    case "Radio Encendida":
      return "11";
    case "Tecnico(s) Taller No Respetan Tarja":
      return "12";
    case "Tecnico(s) Taller Trabajando en Unidad":
      return "13";
    case "Unidad Andando cuando Asistio SOMEC":
      return "14";
    case "Unidad(es) con Poca Carga desde Embarque":
      return "15";
    case "Otro":
      return "16";

    default:
      break;
  }
};
