import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";

export default function DeleteDialogReports(props) {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    props.hadleChangeFlag();
    setOpen(false);
  };

  const handleDelete = () => {
    props.handleDeleteData();
    handleClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Eliminar Indicador"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>¿Está seguro que desea eliminar este indicador?</b>
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            Esta acción no se podrá revertir.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            VOLVER
          </Button>
          <Button
            className="hola"
            variant="contained"
            color="secondary"
            onClick={handleDelete}
          >
            ELIMINAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
