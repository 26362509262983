import React from "react";
import "../styles/dashboard.css";

import { Paper } from "@material-ui/core";

import VerticalGraph from "../components/Graphs/VerticalGraph";
import StackedGraph from "../components/Graphs/StackedGraph";
import PieGraph from "../components/Graphs/PieGraph";
import GroupedGraph from "../components/Graphs/GroupedGraph";

// DATA

const Dashboard = () => {
  return (
    <div className="dashboard_container">
      <div className="graph_container">
        <Paper elevation={3}>
          <VerticalGraph
            title="Stock Cantidad De Pautas"
            graph_type="stock_records_per_month"
          />
        </Paper>
      </div>
      <div className="graph_container">
        <Paper elevation={3}>
          <StackedGraph
            title="Stock Unidades con Anomalía"
            graph_type="stock_anomalies_per_month"
          />
        </Paper>
      </div>

      {/* <div className="graph_container">
        <Paper elevation={3}>
          <VerticalGraph
            title="Asistencia de Baterías"
            graph_type="assistance_battery"
          />
        </Paper>
      </div> */}

      <div className="graph_container">
        <Paper elevation={3}>
          <VerticalGraph
            title="Revisión Despacho"
            graph_type="dispatch_review_graph_per_year"
          />
        </Paper>
      </div>

      <div className="graph_container">
        <Paper elevation={3}>
          <VerticalGraph
            title="Suministro 8000SO"
            graph_type="fuel_supply_graph_per_year"
          />
        </Paper>
      </div>

      {/* <div className="graph_container">
        <Paper elevation={3}>
          <StackedGraph
            title="Motivo Asistencia de Batería"
            graph_type="assistance_reason"
          />
        </Paper>
      </div>

      <div className="graph_container_circle">
        <Paper elevation={3}>
          <PieGraph
            title="Asistencia Bat. Ubicación Física"
            graph_type="assistance_by_location"
          />
        </Paper>
      </div> */}

      {/* <div className="graph_container">
        <Paper elevation={3}>
          <StackedGraph
            title="Litros Comb por Tipo Cliente"
            graph_type=""
          />
        </Paper>
      </div> */}

      {/* <div className="graph_container">
        <Paper elevation={3}>
          <GroupedGraph
            title="Litro por Tipo Mes"
            graph_type={"fuels_per_month"}
          />
        </Paper>
      </div> */}

    </div>
  );
};

export default Dashboard;
