import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@material-ui/core";
import axios from "axios";
import { API_BASE } from "../env";

export default function DeleteFuel(props) {
  // const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    // props.hadleChangeFlag();
    props.setOpen(false);
  };

  const handleDelete = () => {
    props.idToEdit.length > 0 &&
    props.idToEdit.forEach((id) => {
      const config = {
        headers: {
          "Content-Type": "application/json"
        }
      };

      const token = localStorage.getItem("token");
      config.headers["Authorization"] = `Token ${token}`;

      axios
        .delete(`${API_BASE}v1/fuels/${id}/`, config)
        .then(response => {
          props.handleGetData();
        })
        .catch(error => {
          console.log(error);
        }).finally(()=> props.setSelectedRow([]))
    })

    // props.handleDeleteData();
    handleClose();
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Eliminar registros"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>¿Está seguro que desea eliminar los registros?</b>
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            Esta acción no se podrá revertir.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            VOLVER
          </Button>
          <Button
            className="hola"
            variant="contained"
            color="secondary"
            onClick={handleDelete}
          >
            ELIMINAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
