import React, { useEffect, useState } from "react";

import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import {
  // gridReportTableHeaders,
  headerName
} from "./helpers";
// import { login } from "../../redux/actions/auth/actions";
import SearchIconComponent from "./tableComponents/SearchIcon";
import StatusBoolComponent from "./tableComponents/StatusBoolComponent";
import DateComponent from "./tableComponents/DateComponent";
import AssistanceBoolComponent from "./tableComponents/AssistanceBoolComponent";
import { gridOptions } from "../../assets/Locale/LocaleEs";
import ToolbarTable from "./tableComponents/ToolbarTable";

const GridReportTable = ({
  type,
  tableData,
  handleGetData,
  handleGetIDS,
  handleDownloadFile,
  handleChangeFile,
  isFiltered
}) => {
  const [data, setData] = useState([]);

  const [gridApi, setGridApi] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [selectedRow, setSelectedRow] = useState([]);

  const headerField =
    type === "assistance_battery" ||
    type === "assistance_reason" ||
    type === "assistance_by_location"
      ? ["date", "vin_number", "assistance_reason", "assistance_location"]
      : [
          "date",
          "month",
          "brand",
          "model",
          "vin_number",
          "responsible",
          "status"
        ];

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const idIndex = headerField.indexOf("id");
  if (idIndex > -1) {
    headerField.splice(idIndex, 1);
  }
  const statusIndex = headerField.indexOf("status");
  if (statusIndex > -1) {
    headerField.splice(statusIndex, 1);
  }

  const assistanceIndex = headerField.indexOf("assistance");
  if (assistanceIndex > -1) {
    headerField.splice(assistanceIndex, 1);
  }

  const onGridReady = params => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onSelectionChanged = () => {
    const selectedIDS = [];
    const selectedIDStoDownload = [];
    const selectedRows = gridApi.getSelectedRows();

    selectedRows.forEach(row => selectedIDS.push(row.id));
    setSelectedRow(selectedIDS);

    //DOWNLOAD CSV
    selectedRows.forEach(row => selectedIDStoDownload.push([row.id]));
    handleGetIDS(selectedIDStoDownload);
  };

  const onFilterChange = e => {
    gridApi.setQuickFilter(e.target.value);
  };

  return (
    <>
      {!isFiltered && (
        <ToolbarTable
          selectedRow={selectedRow}
          handleGetData={handleGetData}
          handleDownloadFile={handleDownloadFile}
          onFilterChange={onFilterChange}
          handleChangeFile={handleChangeFile}
          reportTable
        />
      )}
      <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
        <AgGridReact
          localeText={gridOptions.localeText}
          rowData={data}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={true}
          frameworkComponents={{
            SearchIconComponent,
            StatusBoolComponent,
            AssistanceBoolComponent,
            DateComponent
          }}
        >
          <AgGridColumn
            width={40}
            checkboxSelection={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
            hide={
              type === "assistance_battery" ||
              type === "assistance_reason" ||
              type === "assistance_by_location"
            }
          />

          <AgGridColumn
            field="VER"
            width={80}
            cellRenderer="SearchIconComponent"
            hide={
              type === "assistance_battery" ||
              type === "assistance_reason" ||
              type === "assistance_by_location"
            }
          />

          {headerField.map((col, i) => {
            return (
              <AgGridColumn
                key={`ColNum${i * 76}$%`}
                headerName={headerName(col)}
                field={col}
                resizable={true}
                width={140}
                minWidth={140}
                flex={2}
                filter="agTextColumnFilter"
              />
            );
          })}
          <AgGridColumn
            headerName={headerName("status")}
            field="status"
            width={140}
            minWidth={140}
            flex={2}
            cellRenderer="StatusBoolComponent"
            hide={
              type === "assistance_battery" ||
              type === "assistance_reason" ||
              type === "assistance_by_location"
            }
          />
        </AgGridReact>
      </div>
    </>
  );
};

export default GridReportTable;
