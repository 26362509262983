import React from "react";
import Page from "./Page";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import logoKaufmann from "../assets/img_kaufmann.png";

const DailyReportPdf = ({ id, date, oct_95, oct_97, oct_93, diesel }) => (
  <Page singleMode={true} id={id} daily="daily" style={{ marginTop: "-50px" }}>
    <Grid container style={{ backgroundColor: "#022C42", paddingLeft: "10px" }}>
      <Grid
        item
        xs={6}
        style={{ color: "white", textAlign: "left", paddingLeft: "1px" }}
      >
        <img src={logoKaufmann} alt="kaufmann" style={{ width: "95px" }} />
      </Grid>
      <Grid
        item
        xs={6}
        style={{ color: "white", fontSize: "15px", paddingTop: "20px" }}
      >
        Cierre contable diario
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ backgroundColor: "white", height: "20px" }}
    >
      <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
        <strong>ADBLUE</strong>
      </Grid>
      <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
        {oct_97}
      </Grid>
      <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
        <strong></strong>
      </Grid>
      <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}></Grid>
    </Grid>

    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      style={{ backgroundColor: "white", height: "20px" }}
    >
      <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
        <strong>BENCINA</strong>
      </Grid>
      <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
        {oct_93}
      </Grid>
      <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
        <strong>Diesel</strong>
      </Grid>
      <Grid item xs={2} style={{ color: "black", fontSize: "12px" }}>
        {diesel}
      </Grid>
    </Grid>
  </Page>
);
/* eslint-disable-next-line */
const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      height: 800,
      overflow: "auto"
    },
    [theme.breakpoints.up("xs")]: {
      minHeight: 400
    }
  }
}));

export default DailyReportPdf;
