import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import ReportTable from "../../ReportTable";

import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import FuelTable from "../../FuelTable";

export const FilteredTable = () => {
  const {
    year,
    month,
    type,
    assistance_location,
    assistance_reason,
    fuel_type
  } = useParams();

  useEffect(() => {}, [
    year,
    month,
    type,
    assistance_location,
    assistance_reason,
    fuel_type
  ]);

  let history = useHistory();

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.goBack()}
      >
        Volver Atrás
      </Button>
      <br />
      {type === "fuels_per_month" ? (
        <FuelTable
          filteredData={true}
          year={year}
          month={month}
          type={type}
          assistance_location={assistance_location}
          assistance_reason={assistance_reason}
          fuel_type={fuel_type}
        />
      ) : (
        <ReportTable
          filteredData={true}
          year={year}
          month={month}
          type={type}
          assistance_location={assistance_location}
          assistance_reason={assistance_reason}
          fuel_type={fuel_type}
        />
      )}
    </>
  );
};
