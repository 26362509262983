import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Snackbar, SnackbarContent, IconButton } from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import { green, yellow, blue, red } from "@material-ui/core/colors";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

const iconType = {
  success: CheckCircleOutlineOutlinedIcon,
  warning: WarningOutlinedIcon,
  info: InfoOutlinedIcon,
  error: NotInterestedOutlinedIcon
};

/* const handleClose = () => {
  this.setState({ notification: false });
}; */

class Notifications extends React.Component {
  render() {
    const {
      classes,
      message,
      notificationType,
      onClose,
      open,
      autoHideDuration
    } = this.props;
    const Icon = iconType[notificationType];

    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          autoHideDuration={autoHideDuration}
          open={open}
          onClose={onClose}
        >
          <SnackbarContent
            className={clsx(classes[notificationType], classes.margin)}
            message={
              <span className={classes.message}>
                <Icon className={clsx(classes.icon, classes.iconType)} />
                {message}
              </span>
            }
            action={[
              <IconButton
                className={classes.close}
                onClick={onClose}
                color="inherit"
                key="close"
              >
                <CloseOutlinedIcon className={classes.icon} />
              </IconButton>
            ]}
          />
        </Snackbar>
      </React.Fragment>
    );
  }
}

const styles = createStyles(theme => ({
  message: {
    display: "flex",
    alignItems: "center"
  },

  margin: {
    margin: theme.spacing(1)
  },

  icon: {
    fontSize: 18
  },

  iconType: {
    opacity: 0.8,
    marginRight: theme.spacing(1)
  },

  // Icons Types styles

  success: {
    backgroundColor: green[600]
  },

  warning: {
    backgroundColor: yellow[600]
  },

  info: {
    backgroundColor: blue[500]
  },

  error: {
    backgroundColor: red[600]
  }
}));

Notifications.propTypes = {
  message: PropTypes.node,
  onClose: PropTypes.func,
  notificationType: PropTypes.oneOf(["success", "warning", "info", "error"])
    .isRequired,
  open: PropTypes.bool
};

export default withStyles(styles)(Notifications);
