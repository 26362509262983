import React from "react";
import kaufmann from "../assets/img_kaufmann.png";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet
} from "@react-pdf/renderer";
import { Avatar } from "@material-ui/core";
import axios from "axios";
import { API_BASE } from "../env/index";
import Loading from "./Loading";
//import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import imgPdf from "../assets/pdf.svg";
class Pdf extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      km: 0,
      model: "",
      factory: "",
      date: "",
      motor_number: "",
      vin_number: "",
      visual_status: "",
      tank_status: "",
      fuel_filter_status: "",
      loading: true,
      batteries: [],
      responsible_full_name: "",
      external_supervisor_full_name: "",
      kaufman_supervisor_full_name: "",
      kaufmann_supervisor_date: "",
      external_supervisor_date: "",
      responsible_date: "",
      visual_remark: "",
      tank_remark: "",
      fuel_filter_remark: "",
      air_system_remark: "",
      start_motor_remark: "",
      board_failures_remark: "",
      ac_system_remark: "",
      remark: "",
      start_motor_status: "",
      board_failures_status: "",
      ac_system_status: "",
      air_system_status: ""
    };
  }

  componentDidMount() {
    this.handleGetData();
  }

  handleGetData = async () => {
    const config = {
      headers: {}
    };

    const token = localStorage.getItem("token");
    config.headers["Authorization"] = `Token ${token}`;

    await axios
      .get(`${API_BASE}v1/stock/${this.props.idStock}`, config)
      .then(response => {
        //console.log(response.data);
        this.setState({
          km: response.data.km,
          model: response.data.model,
          factory: response.data.factory,
          date: response.data.date,
          motor_number: response.data.motor_number,
          vin_number: response.data.vin_number,
          visual_status: response.data.visual_status,
          tank_status: response.data.tank_status,
          fuel_filter_status: response.data.fuel_filter_status,
          batteries: response.data.batteries,
          responsible_full_name: response.data.responsible_full_name,
          external_supervisor_full_name:
            response.data.external_supervisor_full_name,
          kaufman_supervisor_full_name:
            response.data.kaufman_supervisor_full_name,
          kaufmann_supervisor_date: response.data.kaufmann_supervisor_date,
          external_supervisor_date: response.data.external_supervisor_date,
          responsible_date: response.data.responsible_date,
          visual_remark: response.data.visual_remark,
          tank_remark: response.data.tank_remark,
          fuel_filter_remark: response.data.fuel_filter_remark,
          air_system_remark: response.data.air_system_remark,
          start_motor_remark: response.data.start_motor_remark,
          board_failures_remark: response.data.board_failures_remark,
          ac_system_remark: response.data.ac_system_remark,
          remark: response.data.remark,
          start_motor_status: response.data.start_motor_status,
          board_failures_status: response.data.board_failures_status,
          ac_system_status: response.data.ac_system_status,
          air_system_status: response.data.air_system_status
        });
      })
      .catch(error => {
        console.log(error.response);
      })
      .finally(
        this.setState({
          loading: false
        })
      );
  };

  render() {
    const styles = StyleSheet.create({
      page: { padding: 60 },
      box: { width: "100%", marginBottom: 10 },
      pageNumbers: {
        position: "absolute",
        bottom: 20,
        left: 0,
        right: 0,
        textAlign: "center"
      },
      image: {
        width: "100px",
        marginLeft: 10
      },
      subtitle: {
        fontSize: 18,
        margin: 12,
        color: "white",
        textAlign: "right",
        position: "fixed",
        top: -50
      },
      column1: {
        fontSize: 10,
        color: "black",
        textAlign: "left",
        marginVertical: 0,
        marginHorizontal: "20%"
      },
      column2: {
        fontSize: 10,
        color: "black",
        textAlign: "left",
        marginVertical: -10,
        marginHorizontal: "60%"
      },
      column3: {
        fontSize: 10,
        color: "black",
        textAlign: "left",
        marginVertical: -20,
        marginHorizontal: "60%"
      },
      column4: {
        fontSize: 10,
        color: "black",
        textAlign: "left",
        marginVertical: -30,
        marginHorizontal: "80%"
      },
      title: {
        fontSize: 18,
        margin: 12,
        color: "white",
        textAlign: "center",
        position: "fixed"
      }
    });
    const PdfDocument = ({
      km,
      model,
      factory,
      date,
      motor_number,
      vin_number,
      visual_status,
      tank_status,
      fuel_filter_status,
      batteries,
      responsible_full_name,
      external_supervisor_full_name,
      kaufman_supervisor_full_name,
      kaufmann_supervisor_date,
      external_supervisor_date,
      responsible_date,
      visual_remark,
      tank_remark,
      fuel_filter_remark,
      air_system_remark,
      start_motor_remark,
      board_failures_remark,
      ac_system_remark,
      remark,
      start_motor_status,
      board_failures_status,
      ac_system_status,
      air_system_status
    }) => (
      <Document>
        <Page size="A4">
          <View
            style={[styles.box, { height: 60, backgroundColor: "#022c42" }]}
          >
            <Image style={styles.image} src={kaufmann} />
            <Text style={styles.subtitle}>
              Pauta Inspección Unidades en Stock
            </Text>
          </View>

          <View style={[styles.box, { height: 40, backgroundColor: "white" }]}>
            <Text style={styles.column1}>FÁBRICA: {factory}</Text>
            <Text style={styles.column2}>MODELO: {model}</Text>
            <Text style={[styles.column1, { marginVertical: 10 }]}>
              Nº VIN: {vin_number}
            </Text>
            <Text style={[styles.column1, { marginVertical: -10 }]}>
              Nº MOTOR: {motor_number}
            </Text>
            <Text style={[styles.column2, { marginVertical: -11 }]}>
              KM: {km}
            </Text>
            <Text style={[styles.column2, { marginVertical: 10 }]}>
              FECHA: {date.substring(0, 10)}
              {date && " " + date.substring(11, 19)}
            </Text>
          </View>
          <View
            style={[styles.box, { height: 50, backgroundColor: "#022c42" }]}
          >
            <Text style={[styles.title, { marginVertical: 10 }]}>
              Inspección previa
            </Text>
          </View>
          <View style={[styles.box, { height: 20, backgroundColor: "white" }]}>
            <Text
              style={[
                styles.column2,
                { marginVertical: 10, marginHorizontal: "0%" }
              ]}
            >
              Inspección visual en cuanto a daños y óxidos el chasis,
              identificar en observaciones
            </Text>

            <Text
              style={[
                styles.column2,
                {
                  marginVertical: -24,
                  marginHorizontal: "80%",
                  fontSize: 15,
                  textAlign: "center",
                  fontWeight: "bold"
                }
              ]}
            >
              {visual_status === "OK"
                ? "REALIZADO"
                : visual_status === "NO_OK"
                ? "NO REALIZADO"
                : "NA"}
            </Text>
          </View>

          <View style={[styles.box, { height: 20, backgroundColor: "white" }]}>
            <Text
              style={[
                styles.column2,
                { marginVertical: 10, marginHorizontal: "0%" }
              ]}
            >
              OBSERVACIONES:{" "}
              {visual_remark !== "null" ? visual_remark : "Sin observaciones"}
            </Text>

            <Text
              style={[
                styles.column2,
                {
                  marginVertical: -24,
                  marginHorizontal: "80%",
                  fontSize: 15,
                  textAlign: "center",
                  fontWeight: "bold"
                }
              ]}
            ></Text>
          </View>

          <View style={[styles.box, { height: 30, backgroundColor: "white" }]}>
            <Text
              style={[
                styles.column2,
                { marginVertical: -1, marginHorizontal: "0%" }
              ]}
            >
              Despresurizar tanques de combustibles (abrir y cerrar tapas de
              estanque)
            </Text>
            <Text
              style={[
                styles.column2,
                {
                  marginVertical: -14,
                  marginHorizontal: "80%",
                  fontSize: 15,
                  textAlign: "center"
                }
              ]}
            >
              {tank_status === "OK"
                ? "REALIZADO"
                : tank_status === "NO_OK"
                ? "NO REALIZADO"
                : "NA"}
            </Text>
          </View>

          <View style={[styles.box, { height: 30, backgroundColor: "white" }]}>
            <Text
              style={[
                styles.column2,
                { marginVertical: -1, marginHorizontal: "0%" }
              ]}
            >
              OBSERVACIONES:{" "}
              {tank_remark !== "null" ? tank_remark : "Sin observaciones"}
            </Text>
            <Text
              style={[
                styles.column2,
                {
                  marginVertical: -14,
                  marginHorizontal: "80%",
                  fontSize: 15,
                  textAlign: "center"
                }
              ]}
            ></Text>
          </View>
          <View style={[styles.box, { height: 31, backgroundColor: "white" }]}>
            <Text
              style={[
                styles.column2,
                { marginVertical: -23, marginHorizontal: "0%" }
              ]}
            >
              Drenar por completo el combustible del filtro decantador, luego
              llenar el filtro con bomba manual{" "}
            </Text>
            <Text
              style={[
                styles.column2,
                {
                  marginVertical: 7,
                  marginHorizontal: "80%",
                  fontSize: 15,
                  textAlign: "center"
                }
              ]}
            >
              {fuel_filter_status === "OK"
                ? "REALIZADO"
                : fuel_filter_status === "NO_OK"
                ? "NO REALIZADO"
                : "NA"}
            </Text>
          </View>
          <View style={[styles.box, { height: 31, backgroundColor: "white" }]}>
            <Text
              style={[
                styles.column2,
                { marginVertical: -23, marginHorizontal: "0%" }
              ]}
            >
              OBSERVACIONES:{" "}
              {fuel_filter_remark !== "null"
                ? fuel_filter_remark
                : "Sin observaciones"}
            </Text>
            <Text
              style={[
                styles.column2,
                {
                  marginVertical: 7,
                  marginHorizontal: "80%",
                  fontSize: 15,
                  textAlign: "center"
                }
              ]}
            ></Text>
          </View>
          <View
            style={[
              styles.box,
              {
                height: 50,
                backgroundColor: "#022c42",
                marginVertical: -30,
                marginBottom: 40
              }
            ]}
          >
            <Text style={[styles.title, { marginVertical: 10 }]}>
              Inspección de batería
            </Text>
          </View>
          <View
            style={[
              styles.box,
              {
                height: 25
              }
            ]}
          ></View>
          {batteries.map((bat, index) => (
            <View
              key={index}
              style={[
                styles.box,
                {
                  height: 80,

                  borderColor: "black",
                  borderStyle: "solid",
                  borderWidth: 2,
                  borderRadius: 5
                }
              ]}
            >
              <Text
                style={[
                  styles.column2,
                  {
                    marginVertical: 6,
                    marginHorizontal: "40%",
                    width: "500px",
                    fontSize: 8
                  }
                ]}
              >
                Controlar el estado de batería con MidTronic
              </Text>
              <Text
                style={[
                  styles.column2,
                  {
                    marginVertical: 5,
                    marginHorizontal: "10%",
                    fontSize: 15,
                    textAlign: "left"
                  }
                ]}
              >
                BATERÍA {index + 1}
              </Text>

              <View
                style={[
                  styles.box,
                  {
                    height: 40,
                    backgroundColor: "white",
                    marginHorizontal: "40%",
                    marginVertical: -30,
                    width: "600px"
                  }
                ]}
              >
                <Text style={styles.column1}>
                  AMPERAJE: {bat.amperage !== "null" && bat.amperage + "CCA"}
                </Text>
                <Text style={[styles.column2, { marginHorizontal: "0%" }]}>
                  VOLTAJE: {bat.voltage !== "null" && bat.voltage + "V"}
                </Text>
                <Text style={[styles.column1, { marginVertical: 10 }]}>
                  CAPACIDAD: {bat.capacity !== "null" && bat.capacity + "Ah"}
                </Text>
                <Text style={[styles.column1, { marginVertical: -10 }]}>
                  TEST AMPERAJE:
                  {bat.test_amperage !== "null" && bat.test_amperage + "CCA"}
                </Text>
                <Text
                  style={[
                    styles.column2,
                    {
                      marginVertical: -11,
                      marginHorizontal: "0%"
                    }
                  ]}
                >
                  MARCA: {bat.brand !== "null" && bat.brand}
                </Text>
                <Text
                  style={[
                    styles.column2,
                    { marginVertical: 10, marginHorizontal: "0%" }
                  ]}
                >
                  TEST VOLTAJE:
                  {bat.test_voltage !== "null" && bat.test_voltage + "V"}
                </Text>
              </View>
            </View>
          ))}
          <View>
            <View
              style={[styles.box, { height: 50, backgroundColor: "#022c42" }]}
            >
              <Text style={[styles.title, { marginVertical: 10 }]}>
                Inspección de Habitáculo
              </Text>
            </View>
            <View
              style={[styles.box, { height: 20, backgroundColor: "white" }]}
            >
              <Text
                style={[
                  styles.column2,
                  { marginVertical: 10, marginHorizontal: "0%" }
                ]}
              >
                Vaciar sistema de aire comprimido para unidades con sist. de
                freno neumático (pisar freno 8 veces a fondo)
              </Text>

              <Text
                style={[
                  styles.column2,
                  {
                    marginVertical: -24,
                    marginHorizontal: "80%",
                    fontSize: 15,
                    textAlign: "center",
                    fontWeight: "bold"
                  }
                ]}
              >
                {air_system_status === "OK"
                  ? "REALIZADO"
                  : air_system_status === "NO_OK"
                  ? "NO REALIZADO"
                  : "NA"}
              </Text>
            </View>
            <View
              style={[styles.box, { height: 20, backgroundColor: "white" }]}
            >
              <Text
                style={[
                  styles.column2,
                  { marginVertical: 10, marginHorizontal: "0%" }
                ]}
              >
                OBSERVACIONES:{" "}
                {air_system_remark !== "null"
                  ? air_system_remark
                  : "Sin observaciones"}
              </Text>

              <Text
                style={[
                  styles.column2,
                  {
                    marginVertical: -24,
                    marginHorizontal: "80%",
                    fontSize: 15,
                    textAlign: "center",
                    fontWeight: "bold"
                  }
                ]}
              ></Text>
            </View>
            <View
              style={[styles.box, { height: 20, backgroundColor: "white" }]}
            >
              <Text
                style={[
                  styles.column2,
                  { marginVertical: 10, marginHorizontal: "0%" }
                ]}
              >
                Arrancar motor entre 15 a 30 minutos{" "}
              </Text>

              <Text
                style={[
                  styles.column2,
                  {
                    marginVertical: -24,
                    marginHorizontal: "80%",
                    fontSize: 15,
                    textAlign: "center",
                    fontWeight: "bold"
                  }
                ]}
              >
                {start_motor_status === "OK"
                  ? "REALIZADO"
                  : start_motor_status === "NO_OK"
                  ? "NO REALIZADO"
                  : "NA"}
              </Text>
            </View>
            <View
              style={[styles.box, { height: 20, backgroundColor: "white" }]}
            >
              <Text
                style={[
                  styles.column2,
                  { marginVertical: 10, marginHorizontal: "0%" }
                ]}
              >
                OBSERVACIONES:{" "}
                {start_motor_remark !== "null"
                  ? start_motor_remark
                  : "Sin observaciones"}
              </Text>

              <Text
                style={[
                  styles.column2,
                  {
                    marginVertical: -24,
                    marginHorizontal: "80%",
                    fontSize: 15,
                    textAlign: "center",
                    fontWeight: "bold"
                  }
                ]}
              ></Text>
            </View>
            <View
              style={[styles.box, { height: 20, backgroundColor: "white" }]}
            >
              <Text
                style={[
                  styles.column2,
                  { marginVertical: 10, marginHorizontal: "0%" }
                ]}
              >
                Verificar si indica fallas en el tablero de instrumentos
                (indicar en observaciones e informar a supervisor)
              </Text>

              <Text
                style={[
                  styles.column2,
                  {
                    marginVertical: -24,
                    marginHorizontal: "80%",
                    fontSize: 15,
                    textAlign: "center",
                    fontWeight: "bold"
                  }
                ]}
              >
                {board_failures_status === "OK"
                  ? "REALIZADO"
                  : board_failures_status === "NO_OK"
                  ? "NO REALIZADO"
                  : "NA"}
              </Text>
            </View>
            <View
              style={[styles.box, { height: 20, backgroundColor: "white" }]}
            >
              <Text
                style={[
                  styles.column2,
                  { marginVertical: 10, marginHorizontal: "0%" }
                ]}
              >
                OBSERVACIONES:{" "}
                {board_failures_remark !== "null"
                  ? board_failures_remark
                  : "Sin observaciones"}
              </Text>

              <Text
                style={[
                  styles.column2,
                  {
                    marginVertical: -24,
                    marginHorizontal: "80%",
                    fontSize: 15,
                    textAlign: "center",
                    fontWeight: "bold"
                  }
                ]}
              ></Text>
            </View>
            <View
              style={[styles.box, { height: 20, backgroundColor: "white" }]}
            >
              <Text
                style={[
                  styles.column2,
                  { marginVertical: 10, marginHorizontal: "0%" }
                ]}
              >
                Accionar sistema de A/C (verificar si enfría o no)
              </Text>

              <Text
                style={[
                  styles.column2,
                  {
                    marginVertical: -24,
                    marginHorizontal: "80%",
                    fontSize: 15,
                    textAlign: "center",
                    fontWeight: "bold"
                  }
                ]}
              >
                {ac_system_status === "OK"
                  ? "REALIZADO"
                  : ac_system_status === "NO_OK"
                  ? "NO REALIZADO"
                  : "NA"}
              </Text>
            </View>
          </View>
          <View style={[styles.box, { height: 20, backgroundColor: "white" }]}>
            <Text
              style={[
                styles.column2,
                { marginVertical: 10, marginHorizontal: "0%" }
              ]}
            >
              OBSERVACIONES:{" "}
              {ac_system_remark !== "null"
                ? ac_system_remark
                : "Sin observaciones"}
            </Text>

            <Text
              style={[
                styles.column2,
                {
                  marginVertical: -24,
                  marginHorizontal: "80%",
                  fontSize: 15,
                  textAlign: "center",
                  fontWeight: "bold"
                }
              ]}
            ></Text>
          </View>
          <View
            style={[styles.box, { height: 50, backgroundColor: "#022c42" }]}
          >
            <Text style={[styles.title, { marginVertical: 10 }]}>
              Responsables
            </Text>
          </View>
          <View style={[styles.box, { height: 40, backgroundColor: "white" }]}>
            <Text style={[styles.column1, { marginHorizontal: "10%" }]}>
              Responsable
            </Text>
            <Text style={[styles.column2, { marginHorizontal: "30%" }]}>
              {responsible_full_name}
            </Text>
            <Text
              style={[
                styles.column1,
                { marginVertical: 10, marginHorizontal: "10%" }
              ]}
            >
              Supervisor externo
            </Text>
            <Text
              style={[
                styles.column1,
                { marginVertical: -10, marginHorizontal: "10%" }
              ]}
            >
              Supervisor Kaufmann
            </Text>
            <Text
              style={[
                styles.column2,
                { marginVertical: -11, marginHorizontal: "30%" }
              ]}
            >
              {external_supervisor_full_name}
            </Text>
            <Text
              style={[
                styles.column2,
                { marginVertical: 10, marginHorizontal: "30%" }
              ]}
            >
              {kaufman_supervisor_full_name ? kaufman_supervisor_full_name : ""}
            </Text>
            <Text style={[styles.column3, { marginVertical: -21 }]}>
              {kaufmann_supervisor_date
                ? kaufmann_supervisor_date.substring(0, 10)
                : ""}
            </Text>
            <Text style={[styles.column3, { marginVertical: -10 }]}>
              {responsible_date ? responsible_date.substring(0, 10) : ""}
            </Text>
            <Text style={[styles.column3, { marginVertical: 10 }]}>
              {external_supervisor_date
                ? external_supervisor_date.substring(0, 10)
                : ""}
            </Text>
            <Text style={[styles.column4, { marginVertical: -12 }]}>
              ____________________
            </Text>
            <Text style={[styles.column4, { marginVertical: -10 }]}>
              ____________________{" "}
            </Text>
            <Text style={[styles.column4, { marginVertical: -10 }]}>
              ____________________{" "}
            </Text>
          </View>
          <View
            style={[styles.box, { height: 50, backgroundColor: "#022c42" }]}
          >
            <Text style={[styles.title, { marginVertical: 10 }]}>
              Observacion
            </Text>
          </View>
          <Text style={[styles.column2, { marginHorizontal: "10%" }]}>
            {remark !== "null" ? remark : "Sin observaciones"}
          </Text>
        </Page>
      </Document>
    );

    if (this.state.loading) {
      return <Loading />;
    }

    return (
      <div
        style={{
          float: "right",
          marginBottom: "-56px",
          marginRight: "5px",
          paddingTop: "10px"
        }}
      >
        <PDFDownloadLink
          document={PdfDocument({
            km: this.state.km,
            model: this.state.model,
            factory: this.state.factory,
            date: this.state.date,
            motor_number: this.state.motor_number,
            visual_status: this.state.visual_status,
            tank_status: this.state.tank_status,
            vin_number: this.state.vin_number,
            fuel_filter_status: this.state.fuel_filter_status,
            batteries: this.state.batteries,
            responsible_full_name: this.state.responsible_full_name,
            external_supervisor_full_name: this.state
              .external_supervisor_full_name,
            kaufman_supervisor_full_name: this.state
              .kaufman_supervisor_full_name,
            kaufmann_supervisor_date: this.state.kaufmann_supervisor_date,
            external_supervisor_date: this.state.external_supervisor_date,
            responsible_date: this.state.responsible_date,
            visual_remark: this.state.visual_remark,
            tank_remark: this.state.tank_remark,
            fuel_filter_remark: this.state.fuel_filter_remark,
            air_system_remark: this.state.air_system_remark,
            start_motor_remark: this.state.start_motor_remark,
            board_failures_remark: this.state.board_failures_remark,
            remark: this.state.remark,
            start_motor_status: this.state.start_motor_status,
            board_failures_status: this.state.board_failures_status,
            ac_system_status: this.state.ac_system_status,
            air_system_status: this.state.air_system_status,
            ac_system_remark: this.state.ac_system_remark
          })}
          fileName="reporte.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              <Loading />
            ) : (
              <Avatar
                variant="circle"
                style={{
                  backgroundColor: "#C22561",
                  width: "50px",
                  height: "50px"
                }}
              >
                <img src={imgPdf} alt="pdf daily report" />
              </Avatar>
            )
          }
        </PDFDownloadLink>
      </div>
    );
  }
}
export default Pdf;
