import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";

import {
  FormControl,
  TextField,
  Button,
  Grid,
  Typography,
  Fab
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

class AddIndicator extends React.Component {
  continue = e => {
    this.props.nextStep();
  };

  back = e => {
    this.props.backStep();
  };

  addRow = e => {
    this.props.handleAddRowDenominator();
  };

  end = e => {
    this.props.end();
  };

  addCondition = e => {
    if (this.props.rowsCounter === 1) {
      this.props.addRowNumber();
    }
    this.props.handleChangeCondition();
  };

  render() {
    const { classes } = this.props;
    const {
      rowList,

      handleRowChangeValueDenominator,
      handleRemoveSpecificRowDenominator,

      handleChangeValueAmper,
      handleChangeValueBrand,
      handleChangeValueCapacity,
      handleChangeValueAmperTest,
      handleChangeValueVolTest
    } = this.props;

    return (
      <>
        {/* NEW FIELDS */}

        {/* <Grid item xs={12} xl={12} md={12}>
          <FormControlLabel
            control={
              <Checkbox
                onChange={handleRowChangeBatery}
                name="checkedB"
                color="primary"
              />
            }
            label="¿Se realizó asistencia de Batería?"
          />
        </Grid>

        <Grid item xs={12} xl={12} md={12}>
          <FormControl variant="outlined" className={classes.select}>
            <InputLabel id="demo-simple-select-outlined-label">
              Motivo de Asist. de Batería
            </InputLabel>
            <Select
              value={state.assistance_battery}
              onChange={handleRowChangeAssistanceBattery}
              label="Motivo de Asist. de Batería"
              disabled={!state.assistance}
            >
              <MenuItem value={"0"}>Seleccionar Motivo</MenuItem>
              <MenuItem value={"1"}>
                Asistencia a Unidad(es) de Adelante
              </MenuItem>
              <MenuItem value={"2"}>Bateria(s) con Carga</MenuItem>
              <MenuItem value={"3"}>
                Bateria(s) con Carga Solo Combustible
              </MenuItem>
              <MenuItem value={"4"}>
                Bateria(s) Descarga(s) y Falta Combustible
              </MenuItem>
              <MenuItem value={"5"}>
                Bateria(s) No Carga(n) Post Ciclo de Carga
              </MenuItem>
              <MenuItem value={"6"}>Corta Corriente Activo</MenuItem>
              <MenuItem value={"7"}>Corta Corriente Inactivo</MenuItem>
              <MenuItem value={"8"}>Luces Externas Encendidas</MenuItem>
              <MenuItem value={"9"}>Luces Internas Encendidas</MenuItem>
              <MenuItem value={"10"}>Perdida de Carga por No Uso</MenuItem>
              <MenuItem value={"11"}>Radio Encendida</MenuItem>
              <MenuItem value={"12"}>
                Tecnico(s) Taller No Respetan Tarja
              </MenuItem>
              <MenuItem value={"13"}>
                Tecnico(s) Taller Trabajando en Unidad
              </MenuItem>
              <MenuItem value={"14"}>
                Unidad Andando cuando Asistio SOMEC
              </MenuItem>
              <MenuItem value={"15"}>
                Unidad(es) con Poca Carga desde Embarque
              </MenuItem>
              <MenuItem value={"16"}>Otro</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} xl={12} md={12}>
          <FormControl variant="outlined" className={classes.select}>
            <InputLabel id="demo-simple-select-outlined-label">
              Motivo de Asist. de Batería
            </InputLabel>
            <Select
              value={state.location}
              onChange={handleRowChangeLocation}
              label="Ubicación Asist. de Batería"
              disabled={!state.assistance}
            >
              <MenuItem value={"0"}>Seleccionar Ubicación</MenuItem>
              <MenuItem value={"1"}>Almacenaje</MenuItem>
              <MenuItem value={"2"}>Despacho</MenuItem>
              <MenuItem value={"3"}>Lavado</MenuItem>
              <MenuItem value={"4"}>Patio</MenuItem>
              <MenuItem value={"5"}>Pulmon</MenuItem>
              <MenuItem value={"6"}>Taller</MenuItem>
            </Select>
          </FormControl>
        </Grid> */}

        {/* NEW FIELDS */}
        <br />
        {rowList.map((item, idx) => (
          <React.Fragment key={idx}>
            <Grid container direction="row" key={idx}>
              <Grid item xs={12} xl={12} md={12}>
                <Typography
                  variant="body1"
                  align="center"
                  paragraph
                  color="primary"
                >
                  Controlar el estado de baterías con MidTronic
                </Typography>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  className={classes.select}
                >
                  {/*  <TextField
                    className={classes.select}
                    variant="outlined"
                    label="Voltaje"
                    value={defaultValue}
                    onChange={handleChange("indicatorName")}
                    margin="dense"
                    type="text"
                    pattern=".{2,}"
                  /> */}
                  <TextField
                    name="voltaje"
                    className={classes.valueField}
                    variant="outlined"
                    label="Voltaje"
                    value={rowList[idx].voltaje}
                    onChange={handleRowChangeValueDenominator(idx)}
                    margin="dense"
                    type="text"
                    required
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} xl={12} md={12}>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  className={classes.select}
                >
                  <TextField
                    className={classes.select}
                    variant="outlined"
                    label="Amperaje"
                    value={rowList[idx].amperaje}
                    onChange={handleChangeValueAmper(idx)}
                    margin="dense"
                    type="text"
                    pattern=".{2,}"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} xl={12} md={12}>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  className={classes.select}
                >
                  <TextField
                    className={classes.select}
                    variant="outlined"
                    label="Marca"
                    value={rowList[idx].marca}
                    onChange={handleChangeValueBrand(idx)}
                    margin="dense"
                    type="text"
                    pattern=".{2,}"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} xl={12} md={12}>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  className={classes.select}
                >
                  <TextField
                    className={classes.select}
                    variant="outlined"
                    label="Capacidad"
                    value={rowList[idx].capacidad}
                    onChange={handleChangeValueCapacity(idx)}
                    margin="dense"
                    type="text"
                    pattern=".{2,}"
                  />
                </FormControl>
              </Grid>

              <br />
              <Grid item xs={12} xl={12} md={12}>
                <Typography
                  variant="body1"
                  align="center"
                  paragraph
                  color="primary"
                >
                  Si el test indica cargar batería, realizar nuevamente el test
                  con MidTronic luego del arranque del motor
                </Typography>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  className={classes.select}
                >
                  <TextField
                    className={classes.select}
                    variant="outlined"
                    label="Voltaje"
                    value={rowList[idx].voltaje_test}
                    onChange={handleChangeValueVolTest(idx)}
                    margin="dense"
                    type="text"
                    pattern=".{2,}"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} xl={12} md={12}>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  className={classes.select}
                >
                  <TextField
                    className={classes.select}
                    variant="outlined"
                    label="Amperaje"
                    value={rowList[idx].amperaje_test}
                    onChange={handleChangeValueAmperTest(idx)}
                    margin="dense"
                    type="text"
                    pattern=".{2,}"
                  />
                </FormControl>
                <Typography
                  variant="body1"
                  align="center"
                  paragraph
                  color="primary"
                >
                  Realizar carga de batería si es necesario
                </Typography>
              </Grid>

              <Grid item xs={12} xl={12} md={12}>
                <DeleteForeverOutlinedIcon
                  className={classes.iconDelete}
                  onClick={handleRemoveSpecificRowDenominator(idx)}
                />
              </Grid>
              <Grid item xs={12} xl={12} md={12}>
                <Fab
                  color="secondary"
                  className={classes.iconAdd}
                  size="small"
                  style={{ color: "white" }}
                >
                  <AddIcon fontSize="small" onClick={this.addRow} />
                </Fab>{" "}
                Agregar bateria
              </Grid>
            </Grid>
          </React.Fragment>
        ))}
        <Grid container direction="row" justify="center" alignItems="center">
          {/*  <Grid item xs={12} xl={3} md={3}>
            <Button
              className={classes.searchButton}
              variant="contained"
              onClick={this.back}
            >
              Atrás
            </Button>
          </Grid> */}
          <Grid item xs={12} xl={12} md={12}>
            <Button
              className={classes.searchButton}
              variant="contained"
              onClick={this.continue}
              color="secondary"
              type="submit"
              style={{ color: "white" }}
            >
              Continuar
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.searchButton}
              variant="contained"
              onClick={this.back}
              color="primary"
            >
              ATRAS
            </Button>
          </Grid>
        </Grid>
      </>
    );
  }
}

const styles = createStyles(theme => ({
  select: {
    width: 240,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  searchButton: {
    width: 200,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 30
  },
  menuItem: {
    width: "100%"
  },
  valueField: {
    marginTop: theme.spacing(-0.1)
  },
  iconAdd: {
    marginTop: "5px",
    marginLeft: "5px",
    backgroundColor: "#95C225"
  },
  iconDelete: {
    width: "20x",
    marginTop: "15px",
    marginLeft: "5px"
  },
  subtitle: {
    marginLeft: theme.spacing(1.5),
    border: "dashed 2px grey",
    borderRadius: "15px"
  }
}));

export default withStyles(styles)(AddIndicator);
