import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
//import PrintButton from "./PrintButton";
import DailyReportPdf from "./DailyReportPdf";
//import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import axios from "axios";
import { API_BASE } from "../env/index";
import PdfDaily from "./PdfDaily";
function DailyReport(props) {
  const { match } = props;

  const [data, setData] = useState([]);
  let todayDate = new Date();
  let month = todayDate.getMonth() + 1;

  if (month < 10) {
    month = "0" + month;
  }

  if (todayDate.getDate() < 10) {
    var newDay = "0" + todayDate.getDate();
  } else {
    /* eslint-disable-next-line */
    var newDay = todayDate.getDate();
  }

  const today = todayDate.getFullYear() + "-" + month + "-" + newDay;

  useEffect(() => {
    handleGetData();
    //console.log(data);
    /* eslint-disable-next-line */
  }, [match]);

  const handleGetData = async () => {
    const config = {
      headers: {}
    };

    const token = localStorage.getItem("token");
    config.headers["Authorization"] = `Token ${token}`;

    await axios
      .get(`${API_BASE}v1/fuels/daily/${today}`, config)
      .then(response => {
        //console.log(response.data);
        setData(response.data);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  //const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          {data && <PdfDaily date={today} />}
          <DailyReportPdf
            id={"singlePage"}
            date={todayDate}
            oct_93={data.bencina}
            oct_97={data.adblue}
            diesel={data.diesel}
            oct_95={data.bencina}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
/* eslint-disable-next-line */
const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      height: 800,
      overflow: "auto"
    },
    [theme.breakpoints.up("xs")]: {
      minHeight: 400
    }
  }
}));

export default DailyReport;
