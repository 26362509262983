import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  // Grid,
  TextField
} from "@material-ui/core";
import clsx from "clsx";
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";
import EditFuel from "../../../EditFuel";
import DeleteFuel from "../../../DeleteFuel";

const ToolbarTable = ({ selectedRow,
                        handleGetData,
                        handleDownloadFile,
                        onFilterChange,
                        reportTable,
                        handleChangeFile,
                        userRol,
                        setSelectedRow}) => {
  const classes = useToolbarStyles();

  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleOpenEditModal = () => {
    setOpenEdit(true)
  }

  return (
    <>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: selectedRow.length > 0
        })}
      >
           <div style={{display:'flex',justifyContent:'space-between'}}>
             <TextField
               label="Filtro Rápido"
               name="schoolName"
               type="text"
               variant="outlined"
               pattern="text"
               size="small"
               // className={classes.input}
               onChange={onFilterChange}
               InputLabelProps={{
                 shrink: true
               }}
               placeholder={'Buscar...'}
               // value={vinNumber}
             />

             <div style={{display:'flex',alignItems:'center'}}>
               {
                 selectedRow.length > 0 && userRol !== "CLIENT"?
                   <>
                     <Typography className={classes.title} color="inherit">
                       {`${selectedRow.length} Registros Seleccionados`}
                     </Typography>

                     {
                       !reportTable &&
                        <>
                          {
                            selectedRow.length === 1 &&
                            <Tooltip title="Editar">
                              <IconButton onClick={() => handleOpenEditModal()}>
                                <EditOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          }

                          <Tooltip title="Eliminar">
                            <IconButton onClick={()=>setOpenDelete(true)}>
                              <DeleteForeverOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </>
                     }

                     <Tooltip title="Descargar">
                       <IconButton onClick={()=>handleDownloadFile()}>
                         <GetAppOutlinedIcon />
                       </IconButton>
                     </Tooltip>
                   </>
                   :
                   <Tooltip title="Cargar archivo excel">
                       <IconButton
                       color="primary"
                       variant="contained"
                       component="label"
                       >
                         <CloudUploadOutlinedIcon />
                         <input
                         type="file"
                         accept=".xlsx, .xls, .ods"
                         onChange={handleChangeFile}
                         style={{ display: "none" }}
                         />
                       </IconButton>
                   </Tooltip>
               }
             </div>
           </div>
      </Toolbar>

      {/*MODAL EDIT*/}
      {
        selectedRow.length === 1 &&
        <EditFuel setSelectedRow={setSelectedRow} handleGetData={handleGetData} open={openEdit} setOpen={setOpenEdit} idToEdit={selectedRow[0]}/>
      }
      {/*MODAL DELETE*/}
      {
        selectedRow.length > 0 &&
        <DeleteFuel setSelectedRow={setSelectedRow} handleGetData={handleGetData} open={openDelete} setOpen={setOpenDelete} idToEdit={selectedRow}/>
      }
    </>
  );
};

export default ToolbarTable;

const useToolbarStyles = makeStyles(theme => ({
  root: {
    display: "grid"
  },
  toolbar: theme.mixins.toolbar,
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main
      }
      : {
        color: theme.palette.text.primary
      },
  title: {
    alignItems: "left",
    fontSize: 10
  }
}));