import React from "react";
import { Link } from "react-router-dom";
import { Avatar } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

const SearchIconComponent = (props) => {
  return (
      <div className='ag-cell-wrapper'>
        <Link to={`/reports/${props.data.id}`}>
          <Avatar
            style={{
              backgroundColor: "#95C225",
              width: "30px",
              height: "30px"
            }}
          >
            <SearchIcon style={{ color: "white" }} />
          </Avatar>
        </Link>
      </div>
  );
};

export default SearchIconComponent;
