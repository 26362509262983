import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
//import axios from "axios";
//import { API_BASE } from "../env";
//import Loading from "./Loading";
import {
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Fab
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";

import ObservationModalAirSystem from "./ObservationModalAirSystem";
import ObservationModalStartMotor from "./ObservationModalStartMotor";
import ObservationModalBoard from "./ObservationModalBoard";
import ObservationModalAc from "./ObservationModalAc";
/* 
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined"; */

class Habitaculo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      observationModalAirSystem: false,
      value: "",
      observationModalStartMotor: false,
      valueStartMotor: "",
      observationModalBoard: false,
      valueBoard: "",
      observationModalAc: false,
      valueAc: ""
    };
  }
  continue = e => {
    this.props.nextStep();
  };

  back = e => {
    this.props.backStep();
  };

  addRow = e => {
    this.props.handleAddRow();
  };

  handleStatusObservationDialog = value => {
    if (value === "air") {
      this.setState({
        observationModalAirSystem: !this.state.observationModalAirSystem,
        value: value
      });
    } else if (value === "motor") {
      this.setState({
        observationModalStartMotor: !this.state.observationModalStartMotor,
        valueStartMotor: value
      });
    } else if (value === "board") {
      this.setState({
        observationModalBoard: !this.state.observationModalBoard,
        valueBoard: value
      });
    } else if (value === "ac") {
      this.setState({
        observationModalAc: !this.state.observationModalAc,
        valueAc: value
      });
    }
  };

  addCondition = e => {
    if (this.props.rowsCounter === 1) {
      this.props.addRowNumber();
    }
    this.props.handleChangeCondition();
  };

  end = e => {
    this.props.end();
  };

  render() {
    const { classes } = this.props;
    const {
      handleChange,
      air_system_status,
      start_motor_status,
      ac_system_sttus,
      board_failures_status
    } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography variant="h5" align="center" paragraph color="primary">
              Inspección de Habitáculo{" "}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              align="center"
              paragraph
              color="primary"
            >
              Vaciar sistema de aire comprimido para unidades con sist. de freno
              neumático (pisar freno 8 veces a fondo)
            </Typography>
          </Grid>

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <RadioGroup
                aria-label="Estado"
                name="status"
                value={air_system_status}
                onChange={handleChange("air_system_status")}
                row
              >
                <FormControlLabel
                  value="OK"
                  control={<Radio color="primary" />}
                  label="REALIZADO"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="NO_OK"
                  control={<Radio color="primary" />}
                  label="NO REALIZADO"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="NA"
                  control={<Radio color="primary" />}
                  label="NA"
                  labelPlacement="bottom"
                />
              </RadioGroup>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} xl={12} md={12} style={{ color: "#95C225" }}>
                  <Fab className={classes.iconAdd} size="small">
                    <AddIcon
                      fontSize="small"
                      style={{ color: "white" }}
                      onClick={() => this.handleStatusObservationDialog("air")}
                    />
                  </Fab>{" "}
                  Agregar observación
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              align="center"
              paragraph
              color="primary"
            >
              Arrancar motor entre 15 a 30 minutos
            </Typography>
          </Grid>

          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <RadioGroup
                aria-label="Estado"
                name="status"
                value={start_motor_status}
                onChange={handleChange("start_motor_status")}
                row
              >
                <FormControlLabel
                  value="OK"
                  control={<Radio color="primary" />}
                  label="REALIZADO"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="NO_OK"
                  control={<Radio color="primary" />}
                  label="NO REALIZADO"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="NA"
                  control={<Radio color="primary" />}
                  label="NA"
                  labelPlacement="bottom"
                />
              </RadioGroup>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} xl={12} md={12} style={{ color: "#95C225" }}>
                  <Fab className={classes.iconAdd} size="small">
                    <AddIcon
                      fontSize="small"
                      style={{ color: "white" }}
                      onClick={() =>
                        this.handleStatusObservationDialog("motor")
                      }
                    />
                  </Fab>{" "}
                  Agregar observación
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              align="center"
              paragraph
              color="primary"
            >
              Verificar si indica fallas en el tablero de instrumentos (indicar
              en observaciones e informar a supervisor)
            </Typography>
          </Grid>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <RadioGroup
                aria-label="Estado"
                name="status"
                value={board_failures_status}
                onChange={handleChange("board_failures_status")}
                row
              >
                <FormControlLabel
                  value="OK"
                  control={<Radio color="primary" />}
                  label="REALIZADO"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="NO_OK"
                  control={<Radio color="primary" />}
                  label="NO REALIZADO"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value="NA"
                  control={<Radio color="primary" />}
                  label="NA"
                  labelPlacement="bottom"
                />
              </RadioGroup>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <Grid item xs={12} xl={12} md={12} style={{ color: "#95C225" }}>
                  <Fab className={classes.iconAdd} size="small">
                    <AddIcon
                      fontSize="small"
                      style={{ color: "white" }}
                      onClick={() =>
                        this.handleStatusObservationDialog("board")
                      }
                    />
                  </Fab>{" "}
                  Agregar observación
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              align="center"
              paragraph
              color="primary"
            >
              Accionar sistema de A/C controlar (verificar si enfría o no)
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <RadioGroup
                  aria-label="Estado"
                  name="status"
                  value={ac_system_sttus}
                  onChange={handleChange("ac_system_sttus")}
                  row
                >
                  <FormControlLabel
                    value="OK"
                    control={<Radio color="primary" />}
                    label="RALIZADO"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    value="NO_OK"
                    control={<Radio color="primary" />}
                    label="NO REALIZADO"
                    labelPlacement="bottom"
                  />
                  <FormControlLabel
                    value="NA"
                    control={<Radio color="primary" />}
                    label="NA"
                    labelPlacement="bottom"
                  />
                </RadioGroup>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    xs={12}
                    xl={12}
                    md={12}
                    style={{ color: "#95C225" }}
                  >
                    <Fab className={classes.iconAdd} size="small">
                      <AddIcon
                        fontSize="small"
                        style={{ color: "white" }}
                        onClick={() => this.handleStatusObservationDialog("ac")}
                      />
                    </Fab>{" "}
                    Agregar observación
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Button
              className={classes.searchButton}
              variant="contained"
              onClick={this.continue}
              color="secondary"
            >
              CONTINUAR
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.searchButton}
              variant="contained"
              onClick={this.back}
              color="primary"
            >
              ATRAS
            </Button>
          </Grid>
        </Grid>
        {this.state.observationModalAirSystem ? (
          <ObservationModalAirSystem
            hadleChangeFlag={this.handleStatusObservationDialog}
            handleObservation={this.props.handleObservation}
          />
        ) : null}
        {this.state.observationModalStartMotor ? (
          <ObservationModalStartMotor
            hadleChangeFlag={this.handleStatusObservationDialog}
            handleObservation={this.props.handleObservation}
          />
        ) : null}
        {this.state.observationModalBoard ? (
          <ObservationModalBoard
            hadleChangeFlag={this.handleStatusObservationDialog}
            handleObservation={this.props.handleObservation}
          />
        ) : null}
        {this.state.observationModalAc ? (
          <ObservationModalAc
            hadleChangeFlag={this.handleStatusObservationDialog}
            handleObservation={this.props.handleObservation}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

const styles = createStyles(theme => ({
  select: {
    width: 205,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  searchButton: {
    width: 200,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 30,
    color: "white"
  },
  menuItem: {
    width: "100%"
  },
  valueField: {
    marginTop: theme.spacing(-0.1)
  },
  subtitle: {
    marginLeft: theme.spacing(1.5),
    border: "dashed 2px grey",
    borderRadius: "15px"
  },

  subtitle1: {
    marginLeft: theme.spacing(1.5)
  },
  iconAdd: {
    marginTop: "5px",
    marginLeft: "5px",
    backgroundColor: "#95C225"
  },
  iconDelete: {
    width: "20x",
    marginTop: "15px",
    marginLeft: "5px"
  }
}));

export default withStyles(styles)(Habitaculo);
