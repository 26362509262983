import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

export default function Tittle (props){

  const classes = styles()

  return (
    <React.Fragment>
      <div>
        <h1 className = {classes.title} >{props.tittle}</h1>
      </div>
    </React.Fragment>
  )

}

const styles = makeStyles(theme => ({
  title: {
    textAlign: 'left',
    margin: 10
  }
}))