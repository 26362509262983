export const gridFuelTableHeaders = () => {
  return [
    "date",
    "month",
    "vin_number",
    "model",
    "order_number",
    "fuel_type",
    "liters",
    "id_jet",
    "responsible"
  ];
};

export const gridFuelTableHeadersFiltered = () => {
  return ["date", "vin_number", "assistance_location", "assistance_reason"];
};

export const gridReportTableHeaders = () => {
  return [
    "date",
    "month",
    "brand",
    "model",
    "vin_number",
    "responsible",
    "status"
  ];
};

export const headerName = field => {
  switch (field) {
    case "date":
      return "FECHA";
    case "month":
      return "MES";
    case "brand":
      return "MARCA";
    case "model":
      return "MODELO";
    case "vin_number":
      return "Nº VIN";
    case "responsible":
      return "RESPONSABLE";
    case "status":
      return "ESTADO";
    case "assistance":
      return "ASISTENCIA";
    case "assistance_location":
      return "UBICACIÓN";
    case "assistance_reason":
      return "MOTIVO";

    default:
      break;
  }
};

export const headerNameFuel = field => {
  switch (field) {
    case "date":
      return "FECHA";

    case "month":
      return "MES";

    case "vin_number":
      return "Nº VIN";

    case "model":
      return "MODELO";

    case "order_number":
      return "Nº OS";

    case "fuel_type":
      return "COMBUSTIBLE";

    case "liters":
      return "LITROS";

    case "id_jet":
      return "SURTIDOR";

    case "responsible":
      return "RESPONSABLE";

    case "location":
      return "LUGAR";

    case "reason":
      return "MOTIVO";

    default:
      break;
  }
};

// FECHA
// MES
// Nº VIN
// MODELO
// Nº OS
// COMBUSTIBLE
// LITROS
// SURTIDOR
// RESPONSABLE

// fuel_type: "BENCINA"
// id: 1
// id_jet: "8765"
// liters: "12"
// model: "mercedes"

// order_number: "5432"
// responsible: "Carlos Peñaloza"
// vin_number: "1234"
