import {
  USER_LOADING,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  SNACKBAR_CLEAR
} from "../actions/auth/actionType";

const initialState = {
  token: localStorage.getItem("token"),
  isAuthenticated: null,
  isLoading: false,
  user: null,
  notificationOpen: false,
  notificationMessage: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SNACKBAR_CLEAR:
      return {
        ...state,
        notificationOpen: false
      };
    case USER_LOADING:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: true
      };

    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload
      };

    case LOGIN_SUCCESS:
      localStorage.setItem("token", action.payload.token);
      localStorage.setItem("username", action.payload.user.username);
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false
      };
    case LOGIN_FAIL:
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("company");
      localStorage.removeItem("sg");

      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
        notificationMessage: "Credenciales no válidas",
        notificationOpen: true
      };

    case AUTH_ERROR:
    case LOGOUT_SUCCESS:
      localStorage.removeItem("token");
      localStorage.removeItem("username");
      localStorage.removeItem("company");
      localStorage.removeItem("sg");
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false
      };

    default:
      return state;
  }
}
