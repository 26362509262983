import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
//import PrintButton from "./PrintButton";
import SinglePage from "./SinglePage";
import ImageModal from "./ImageModal";
//import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import axios from "axios";
import { API_BASE } from "../env/index";
function Report(props) {
  const { match } = props;

  const [data, setData] = useState([]);
  const [imageModal, setImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [currentObs, setCurrentObs] = useState("");

  useEffect(() => {
    handleGetData();
    //console.log(data);
    /* eslint-disable-next-line */
  }, [match]);

  const handleGetData = async () => {
    const config = {
      headers: {}
    };

    const token = localStorage.getItem("token");
    config.headers["Authorization"] = `Token ${token}`;

    await axios
      .get(`${API_BASE}v1/stock/${match.params.idReport}`, config)
      .then(response => {
        // console.log(response.data);
        setData(response.data);
      })
      .catch(error => {
        console.log(error.response);
      });
  };

  const handleImageModal = (img, obs) => {
    setCurrentImage(img);
    setCurrentObs(obs);
    setImageModal(!imageModal);
  };

  //const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item style={{ marginLeft: "-10px" }}>
          {/* <PrintButton id={"singlePage"} label={"Descargar"} /> */}
          <SinglePage
            idStock={match.params.idReport}
            id={"singlePage"}
            factory={data.factory}
            model={data.model}
            motor_number={data.motor_number}
            vin_number={data.vin_number}
            km={data.km}
            date={data.date}
            batteries={data.batteries}
            board_failures_status={data.board_failures_status}
            fuel_filter_status={data.fuel_filter_status}
            start_motor_status={data.start_motor_status}
            visual_status={data.visual_status}
            air_system_status={data.air_system_status}
            tank_status={data.tank_status}
            kaufman_supervisor_full_name={data.kaufman_supervisor_full_name}
            kaufmann_supervisor_date={data.kaufmann_supervisor_date}
            kaufmann_supervisor_signature={data.kaufmann_supervisor_signature}
            responsible_full_name={data.responsible_full_name}
            responsible_date={data.responsible_date}
            responsible_signature={data.responsible_signature}
            external_supervisor_full_name={data.external_supervisor_full_name}
            external_supervisor_date={data.external_supervisor_date}
            external_supervisor_signature={data.external_supervisor_signature}
            visual_remark={data.visual_remark}
            visual_image={data.visual_image}
            tank_remark={data.tank_remark}
            tank_image={data.tank_image}
            fuel_filter_remark={data.fuel_filter_remark}
            air_system_remark={data.air_system_remark}
            board_failures_remark={data.board_failures_remark}
            ac_system_remark={data.ac_system_remark}
            start_motor_remark={data.start_motor_remark}
            remark={data.remark}
            handleImageModal={handleImageModal}
            fuel_filter_image={data.fuel_filter_image}
            air_system_image={data.air_system_image}
            start_motor_image={data.start_motor_image}
            board_failures_image={data.board_failures_image}
            ac_system_image={data.ac_system_image}
            ac_system_status={data.ac_system_status}
          />
        </Grid>
      </Grid>
      {imageModal && (
        <ImageModal
          handleClose={handleImageModal}
          currentImage={currentImage}
          currentObs={currentObs}
        ></ImageModal>
      )}
    </React.Fragment>
  );
}
/* eslint-disable-next-line */
const useStyles = makeStyles(theme => ({
  paper: {
    [theme.breakpoints.up("sm")]: {
      height: 800,
      overflow: "auto"
    },
    [theme.breakpoints.up("xs")]: {
      minHeight: 400
    }
  }
}));

export default Report;
