import {
  LOAD_FILE,
  LOAD_FILE_TANK,
  LOAD_FILE_FUEL_FILTER,
  LOAD_FILE_AIR_SYSTEM,
  LOAD_FILE_START_MOTOR,
  LOAD_FILE_BOARD_FAILURES,
  LOAD_FILE_AC,
  CLEAN_IMAGES
} from "../actions/images/actionType";

const initialState = {
  visual_image: null,
  visual_remark: null,
  fuel_filter_image: null,
  board_failures_image: null,
  ac_system_image: null,
  tank_image: null,
  tank_remark: null,
  fuel_filter_remark: null,
  air_system_remark: null,
  air_system_image: null,
  start_motor_image: null,
  start_motor_remark: null,
  board_failures_remark: null,
  ac_system_remark: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CLEAN_IMAGES:
      return {
        ...state,
        visual_image: null,
        visual_remark: null,
        tank_image: null,
        tank_remark: null,
        fuel_filter_image: null,
        fuel_filter_remark: null,
        air_system_image: null,
        air_system_remark: null,
        start_motor_image: null,
        start_motor_remark: null,
        board_failures_image: null,
        board_failures_remark: null,
        ac_system_image: null,
        ac_system_remark: null
      };
    case LOAD_FILE:
      return {
        ...state,
        visual_image: action.payload,
        visual_remark: action.obs
      };
    case LOAD_FILE_TANK:
      return {
        ...state,
        tank_image: action.payload,
        tank_remark: action.obs
      };
    case LOAD_FILE_FUEL_FILTER:
      return {
        ...state,
        fuel_filter_image: action.payload,
        fuel_filter_remark: action.obs
      };
    case LOAD_FILE_AIR_SYSTEM:
      return {
        ...state,
        air_system_image: action.payload,
        air_system_remark: action.obs
      };
    case LOAD_FILE_START_MOTOR:
      return {
        ...state,
        start_motor_image: action.payload,
        start_motor_remark: action.obs
      };
    case LOAD_FILE_BOARD_FAILURES:
      return {
        ...state,
        board_failures_image: action.payload,
        board_failures_remark: action.obs
      };
    case LOAD_FILE_AC:
      return {
        ...state,
        ac_system_image: action.payload,
        ac_system_remark: action.obs
      };
    default:
      return state;
  }
}
