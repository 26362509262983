import React from "react";
import "./App.css";
import AppRoutes from "./routers/index";
import { Router } from "react-router-dom";
import store from "./redux/store/store";
import { Provider } from "react-redux";
import { loadUser } from "./redux/actions/auth/actions";
import { history } from "./routers/history";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#022C42"
    },
    secondary: {
      main: "#95C225"
    }
  },
  overrides: {
    MuiList: {
      // Name of the component ⚛️ / style sheet
      padding: {
        // Name of the rule
        paddingTop: "0px",
        paddingBottom: "0px"
      }
    }
  }
});

class App extends React.Component {
  componentDidMount() {
    store.dispatch(loadUser());
  }

  render() {
    return (
      <div className="App">
        <Provider store={store}>
          <MuiThemeProvider theme={theme}>
            <Router history={history}>
              <AppRoutes />
            </Router>
          </MuiThemeProvider>
        </Provider>
      </div>
    );
  }
}

export default App;
