import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextareaAutosize
} from "@material-ui/core";
//import ImageUpload from "./ImageUpload";
import { makeStyles } from "@material-ui/core/styles";
//import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
//import CardContent from "@material-ui/core/CardContent";
import { onFileLoadedAirSystem } from "../redux/actions/images/actions";
import { connect } from "react-redux";
import Fab from "@material-ui/core/Fab";
import Grid from "@material-ui/core/Grid";
//import Avatar from "@material-ui/core/Avatar";

import red from "@material-ui/core/colors/red";
//import pink from "@material-ui/core/colors/pink";
import blue from "@material-ui/core/colors/blue";

//import Icon from "@material-ui/core/Icon";
import SearchIcon from "@material-ui/icons/Search";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
//import CollectionsIcon from "@material-ui/icons/Collections";

//import Typography from "@material-ui/core/Typography";
//import Popover from "@material-ui/core/Popover";

// Search
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
//import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import ReplayIcon from "@material-ui/icons/Replay";

export function ObservationModalAirSystem(props) {
  const [open, setOpen] = React.useState(true);
  const [obs, setObs] = React.useState(null);
  /* eslint-disable-next-line */
  const [value, setValue] = React.useState(props.value);
  /* eslint-disable-next-line */
  const [image, setImage] = React.useState(null);
  /* eslint-disable-next-line */
  const [preview, setPreview] = React.useState("");
  /* eslint-disable-next-line */
  const [raw, setRaw] = React.useState("");
  const [mainState, setMainState] = React.useState("initial"); // initial, search, gallery, uploaded
  /* eslint-disable-next-line */
  const [imageUploaded, setImageUploaded] = React.useState(0);
  const [selectedFile, setSelectedFile] = React.useState(null);
  /* eslint-disable-next-line */
  const [fileReader, setFileReader] = React.useState(undefined);
  /* eslint-disable-next-line */
  const [filename, setFileName] = React.useState();

  const handleClose = () => {
    props.hadleChangeFlag();
    setOpen(false);
  };

  const handleSubmit = () => {
    props.onFileLoadedAirSystem(image, obs);

    handleClose();
  };

  /*   const handleUploadClick = event => {
    console.log();
    var file = event.target.files[0];
    const reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function(e) {
      setSelectedFile([reader.result]);
    }.bind(this);
    console.log(url); // Would see a path?

    setMainState("uploaded");
    setSelectedFile(event.target.files[0]);
    setImageUploaded(1);
  }; */

  /* const handleSearchClick = event => {
    setMainState("search");
  }; */

  /* const handleGalleryClick = event => {
    setMainState("gallery");
  }; */

  const styles = makeStyles(theme => ({
    root: {
      backgroundColor: theme.palette.background.paper,
      width: 500,
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end"
    },
    icon: {
      margin: theme.spacing(2)
    },
    iconHover: {
      margin: theme.spacing(2),
      "&:hover": {
        color: red[800]
      }
    },
    cardHeader: {
      textalign: "center",
      align: "center",
      backgroundColor: "white"
    },
    input: {
      display: "none"
    },
    title: {
      color: blue[800],
      fontWeight: "bold",
      fontFamily: "Montserrat",
      align: "center"
    },
    button: {
      color: blue[900],
      margin: 10
    },
    secondaryButton: {
      color: "#95C225",
      margin: 10
    },
    typography: {
      margin: theme.spacing(2),
      backgroundColor: "default"
    },

    searchRoot: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: 400
    },
    searchInput: {
      marginLeft: 8,
      flex: 1
    },
    searchIconButton: {
      padding: 10
    },
    searchDivider: {
      width: 1,
      height: 28,
      margin: 4
    }
  }));

  const classes = styles();
  /* eslint-disable-next-line */
  const handleSearchClick = event => {
    setMainState("search");
  };
  /* eslint-disable-next-line */
  const handleGalleryClick = event => {
    setMainState("gallery");
  };
  const handleLoadLocalFile = event => {
    event.preventDefault();
    const file = event.target.files[0];
    setSelectedFile(window.URL.createObjectURL(file));
    //const localImageUrl = window.URL.createObjectURL(file);
    setMainState("uploaded");
    setImage(event.target.files[0]);
  };

  const renderInitialState = (
    <React.Fragment>
      {console.log(obs)}
      <Grid container justify="center" alignItems="center">
        <p>
          <strong>Agrega una imagen</strong>
        </p>

        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleLoadLocalFile}
          disabled={!obs}
        />

        <label htmlFor="contained-button-file">
          <Fab component="span" className={classes.button} disabled>
            <AddPhotoAlternateIcon color="primary" />
          </Fab>
        </label>
      </Grid>
    </React.Fragment>
  );
  /* 
  const handleSearchURL = event => {
    var file = event.target.files[0];
    var reader = new FileReader();
    var url = reader.readAsDataURL(file);

    reader.onloadend = function(e) {
      setSelectedFile([reader.result]);
    }.bind(this);
    console.log(url); // Would see a path?
    setSelectedFile(event.target.files[0]);
    setImageUploaded(1);
  }; */

  const handleImageSearch = url => {
    var filename = url.substring(url.lastIndexOf("/") + 1);
    console.log(filename);
    setMainState("uploaded");
    setImageUploaded(true);
    setSelectedFile(url);
    setFileReader(undefined);
    setFileName(filename);
  };

  const handleSeachClose = event => {
    setMainState("initial");
  };
  /* eslint-disable-next-line */
  const renderSearchState = (
    <Paper className={classes.searchRoot} elevation={1}>
      <InputBase className={classes.searchInput} placeholder="Image URL" />
      <IconButton
        className={classes.button}
        aria-label="Search"
        onClick={handleImageSearch}
      >
        <SearchIcon />
      </IconButton>
      <Divider className={classes.searchDivider} />
      <IconButton
        color="primary"
        className={classes.secondaryButton}
        aria-label="Close"
        onClick={handleSeachClose}
      >
        <CloseIcon />
      </IconButton>
    </Paper>
  );

  /* const handleAvatarClick = value => {
    var filename = value.url.substring(value.url.lastIndexOf("/") + 1);
    console.log(filename);
    setMainState("uploaded");
    setImageUploaded(true);
    setSelectedFile(value.url);
    setFileReader(undefined);
    setFileName(filename);
  }; */
  /* eslint-disable-next-line */
  const renderGalleryState = (
    /*const listItems = this.props.imageGallery.map(url => (
      <div
        onClick={value => this.handleAvatarClick({ url })}
        style={{
          padding: "5px 5px 5px 5px",
          cursor: "pointer"
        }}
      >
        <Avatar shape="square" size={100} src={url} />
      </div>
    ));*/

    <React.Fragment>
      <Grid>
        <IconButton
          color="primary"
          className={classes.secondaryButton}
          aria-label="Close"
          onClick={handleSeachClose}
        >
          <ReplayIcon />
        </IconButton>
      </Grid>
    </React.Fragment>
  );
  const imageResetHandler = event => {
    console.log("Click!");
    setMainState("initial");
    setSelectedFile(null);
    setImageUploaded(0);
  };

  const renderUploadedState = (
    <React.Fragment>
      <CardActionArea onClick={imageResetHandler}>
        <img
          alt="alt"
          width="100%"
          className={classes.media}
          src={selectedFile}
        />
      </CardActionArea>
    </React.Fragment>
  );

  /*   const galleryImageList = [
    "https://raw.githubusercontent.com/dxyang/StyleTransfer/master/style_imgs/mosaic.jpg",
    "https://upload.wikimedia.org/wikipedia/commons/thumb/e/ea/Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg/1280px-Van_Gogh_-_Starry_Night_-_Google_Art_Project.jpg",
    "https://raw.githubusercontent.com/ShafeenTejani/fast-style-transfer/master/examples/dora-maar-picasso.jpg",
    "https://pbs.twimg.com/profile_images/925531519858257920/IyYLHp-u_400x400.jpg",
    "https://raw.githubusercontent.com/ShafeenTejani/fast-style-transfer/master/examples/dog.jpg",
    "http://r.ddmcdn.com/s_f/o_1/cx_462/cy_245/cw_1349/ch_1349/w_720/APL/uploads/2015/06/caturday-shutterstock_149320799.jpg"
  ]; */
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Observaciones"}</DialogTitle>
        <DialogContent style={{ display: "inline-flex", paddingLeft: "15%" }}>
          <DialogContentText
            id="alert-dialog-description"
            style={{ margin: "auto" }}
          >
            Observaciones
            <TextareaAutosize
              rowsMin={4}
              placeholder="Observaciones"
              style={{ fontSize: 14 }}
              onChange={e => setObs(e.target.value)}
              value={obs || ""}
            />
          </DialogContentText>
          {/* <ImageUpload cardName="Input Image" imageGallery={galleryImageList} /> */}

          {(mainState === "initial" && renderInitialState) ||
            (mainState === "uploaded" && renderUploadedState)}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" color="primary">
            VOLVER
          </Button>
          <Button
            className="hola"
            variant="contained"
            color="secondary"
            onClick={handleSubmit}
          >
            GUARDAR
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

const mapStateToProps = state => ({
  image: state.images.air_system_image
});
export default connect(mapStateToProps, { onFileLoadedAirSystem })(
  ObservationModalAirSystem
);
