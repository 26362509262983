/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";

import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import {
  gridFuelTableHeaders,
  gridFuelTableHeadersFiltered,
  headerNameFuel
} from "./helpers";
import { gridOptions } from "../../assets/Locale/LocaleEs";
import ToolbarTable from "./tableComponents/ToolbarTable";

const GridFuelTable = ({
  type,
  tableData,
  handleGetData,
  handleGetIDS,
  handleDownloadFile,
  handleChangeFile,
  userRol,
  isFiltered
}) => {
  const [data, setData] = useState([]);

  const [gridApi, setGridApi] = useState(null);

  const [gridColumnApi, setGridColumnApi] = useState(null);

  const [selectedRow, setSelectedRow] = useState([]);

  const headerField =
    type === "assistance_battery" ||
    type === "assistance_reason" ||
    type === "assistance_location"
      ? gridFuelTableHeadersFiltered()
      : gridFuelTableHeaders();

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const idIndex = headerField.indexOf("id");
  if (idIndex > -1) {
    headerField.splice(idIndex, 1);
  }

  const assistanceIndex = headerField.indexOf("assistance");
  if (assistanceIndex > -1) {
    headerField.splice(assistanceIndex, 1);
  }

  const onGridReady = params => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  const onSelectionChanged = () => {
    const selectedIDS = [];
    const selectedIDStoDownload = [];
    const selectedRows = gridApi.getSelectedRows();

    selectedRows.forEach(row => selectedIDS.push(row.id));
    setSelectedRow(selectedIDS);

    //DOWNLOAD CSV
    selectedRows.forEach(row => selectedIDStoDownload.push([row.id]));
    handleGetIDS(selectedIDStoDownload);
  };

  const onFilterChange = e => {
    gridApi.setQuickFilter(e.target.value);
  };

  return (
    <>
      {!isFiltered && (
        <ToolbarTable
          selectedRow={selectedRow}
          handleGetData={handleGetData}
          handleDownloadFile={handleDownloadFile}
          onFilterChange={onFilterChange}
          handleChangeFile={handleChangeFile}
          userRol={userRol}
          setSelectedRow={setSelectedRow}
        />
      )}
      <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
        <AgGridReact
          localeText={gridOptions.localeText}
          rowData={data}
          onGridReady={onGridReady}
          onSelectionChanged={onSelectionChanged}
          suppressRowClickSelection={true}
          rowSelection={"multiple"}
          pagination={true}
        >
          <AgGridColumn
            width={40}
            checkboxSelection={true}
            headerCheckboxSelection={true}
            headerCheckboxSelectionFilteredOnly={true}
          />
          {headerField.map((col, i) => {
            return (
              <AgGridColumn
                key={`ColNum${i * 54}$%`}
                headerName={headerNameFuel(col)}
                field={col}
                resizable={true}
                width={140}
                minWidth={140}
                flex={2}
                filter="agTextColumnFilter"
              />
            );
          })}
        </AgGridReact>
      </div>
    </>
  );
};

export default GridFuelTable;
