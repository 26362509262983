import React from "react";
import { withStyles, createStyles } from "@material-ui/core/styles";
//import axios from "axios";
//import { API_BASE } from "../env";
//import Loading from "./Loading";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import {
  FormControl,
  TextField,
  Button,
  Grid,
  Typography
} from "@material-ui/core";

class AddIndicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameError: false
    };
  }
  continue = e => {
    this.props.nextStep();
  };
  render() {
    const { classes } = this.props;
    const {
      handleChange,
      vin_number,
      motor_number,
      factory,
      model,
      km,
      date,
      handleDateChange
    } = this.props;

    return (
      <React.Fragment>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Typography
              className={classes.subtitle}
              variant="h5"
              align="center"
              paragraph
              color="primary"
            >
              Pauta inspección Unidades en Stock
            </Typography>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.select}
            >
              <TextField
                className={classes.select}
                variant="outlined"
                label="Nº de VIN"
                value={vin_number}
                onChange={handleChange("vin_number")}
                margin="dense"
                type="text"
                pattern=".{2,}"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.select}
            >
              <TextField
                className={classes.select}
                variant="outlined"
                label="Nº de Motor"
                value={motor_number}
                onChange={handleChange("motor_number")}
                margin="dense"
                type="text"
                pattern=".{2,}"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.select}
            >
              <TextField
                className={classes.select}
                variant="outlined"
                label="Fábrica"
                value={factory}
                onChange={handleChange("factory")}
                margin="dense"
                type="text"
                pattern=".{2,}"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.select}
            >
              <TextField
                className={classes.select}
                variant="outlined"
                label="Modelo"
                value={model}
                onChange={handleChange("model")}
                margin="dense"
                type="text"
                pattern=".{2,}"
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              margin="dense"
              className={classes.select}
            >
              <TextField
                className={classes.select}
                variant="outlined"
                label="KM"
                value={km}
                onChange={handleChange("km")}
                margin="dense"
                type="text"
                pattern=".{2,}"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <br />
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              style={{ cursor: "pointer" }}
            >
              <DateTimePicker
                value={date || Date.now()}
                onChange={handleDateChange}
                format="dd/MM/yyyy hh:mm "
                ampm={false}
                style={{ width: "300px", cursor: "pointer" }}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.searchButton}
              variant="contained"
              onClick={this.continue}
              color="secondary"
            >
              CONTINUAR
            </Button>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const styles = createStyles(theme => ({
  select: {
    width: 300,
    marginTop: theme.spacing(2)
  },
  searchButton: {
    width: 200,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: 30,
    color: "white"
  },
  menuItem: {
    width: "100%"
  },
  error: {
    color: "red"
  }
}));

export default withStyles(styles)(AddIndicator);
