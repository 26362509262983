/* eslint-disable-next-line */
import { LOAD_FILE } from "./actionType";

export const onFileLoaded = (imageFile, obs) => {
  return dispatch => {
    dispatch({ type: "LOAD_FILE", payload: imageFile, obs: obs });
  };
};

export const onFileLoadedTank = (imageFile, obs) => {
  return dispatch => {
    dispatch({ type: "LOAD_FILE_TANK", payload: imageFile, obs: obs });
  };
};

export const onFileLoadedFuelFilter = (imageFile, obs) => {
  return dispatch => {
    dispatch({ type: "LOAD_FILE_FUEL_FILTER", payload: imageFile, obs: obs });
  };
};

export const onFileLoadedAirSystem = (imageFile, obs) => {
  return dispatch => {
    dispatch({ type: "LOAD_FILE_AIR_SYSTEM", payload: imageFile, obs: obs });
  };
};

export const onFileLoadedStartMotor = (imageFile, obs) => {
  return dispatch => {
    dispatch({ type: "LOAD_FILE_START_MOTOR", payload: imageFile, obs: obs });
  };
};

export const onFileLoadedBoard = (imageFile, obs) => {
  return dispatch => {
    dispatch({
      type: "LOAD_FILE_BOARD_FAILURES",
      payload: imageFile,
      obs: obs
    });
  };
};

export const onFileLoadedAc = (imageFile, obs) => {
  return dispatch => {
    dispatch({
      type: "LOAD_FILE_AC",
      payload: imageFile,
      obs: obs
    });
  };
};

export const cleanImages = () => {
  return dispatch => {
    dispatch({
      type: "CLEAN_IMAGES"
    });
  };
};
